import {createSlice} from "@reduxjs/toolkit";
import {assessmentClient, resourceClient} from "../../api/Container";

const initialState: surgeonConsentProcessState = {}

export const surgeonConsentProcessSlice = createSlice({
  name: 'surgeonConsentProcess',
  initialState,
  reducers: {
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default surgeonConsentProcessSlice.reducer;

// @ts-ignore as this is a middleware
export const getAssessmentByOperation = (operation: string): Promise<any> => async dispatch => {
  try {
    const response = await assessmentClient.getSurgeryAssessmentByOperation(operation);
    return Promise.resolve(response);
  } catch (err: any) {
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getResourceByOperation = (operation: string): Promise<any> => async dispatch => {
  try {
    const response = await resourceClient.getResourceByOperation(operation);
    return Promise.resolve(response);
  } catch (err: any) {
    return Promise.reject(err);
  }
}

type surgeonConsentProcessState = {}
