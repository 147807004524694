import React, {FunctionComponent, useEffect} from 'react';
import Routes from "../routes/routes";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {BrowserRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {refreshToken} from "../store/public/publicAuthSlice";
import './App.css';

const App: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshToken());
    // eslint-disable-next-line
  }, []);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#274d6c',
      },
      secondary: {
        main: '#fff',
      },
      text: {
        primary: '#274d6c',
        secondary: '#fff',
      },
      background: {
        default: '#f7fcfc'
      },
    },
    typography: {
      fontFamily: 'Raleway, sans-serif, -apple-system, Roboto, Arial',
    },
  });

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
          <Routes />
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
