import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ResourceEngagement} from "../../../api/Types";
import {Typography} from "@material-ui/core";
import ReactPlayer from "react-player/vimeo";

interface ResourceVimeoProps {
  resource: ResourceEngagement
  onVideoComplete: (resourceId: string) => void
}

const ResourceVimeo: FunctionComponent<ResourceVimeoProps> = props => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">{props.resource.resource_title}</Typography>
      <div className={classes.embedContainer}>
        <ReactPlayer
          url={`https://player.vimeo.com/video/${props.resource.resource_content}?autoplay=1title=0&byline=0&portrait=0`}
          onEnded={() => props.onVideoComplete(props.resource.resource_id)}
          controls={true}
          playing={true}
        />
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    embedContainer: {
      position: 'relative',
      paddingBottom: '56.25%',
      height: 0,
      overflow: 'none',
      maxWidth: '100%',
      zIndex: 10,
      "& iframe": {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
  }),
);

export default ResourceVimeo;
