import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ResourceEngagement} from "../../../api/Types";
import {Typography} from "@material-ui/core";
import {CheckCircle, PlayArrow} from "@material-ui/icons";

interface ResourceListProps {
  resources: ResourceEngagement[]
  active: number // index of active resource
  completed?: number
  total?: number
  onResourceClick: (resourceId: string) => void
}

const ResourceList: FunctionComponent<ResourceListProps> = props => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.header} variant="h6">
        {props.completed !== undefined && props.total !== undefined ? `Completed ${props.completed} / ${props.total}` : 'Video Playlist'}
      </Typography>
      <div className={classes.container}>
        <div className={classes.listContainer}>
          {props.resources.map((resource, key) => (
            <div key={resource.resource_id} className={classes.listItem} onClick={() => props.onResourceClick(resource.resource_id)}>
              <div className={classes.itemIcon}>{key === props.active ? <PlayArrow /> : null}</div>
              <Typography className={classes.title} variant="body1">{resource.resource_title}</Typography>
              <div className={classes.itemIcon}>{resource.patient_engagement > 0 ? <CheckCircle /> : null}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      textAlign: 'center',
    },
    container: {
      position: 'relative',
      paddingBottom: '56.25%',
      height: '100%',
      maxWidth: '100%',
      zIndex: 10,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '0',
      },
    },
    listContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      paddingBottom: '2.2em', // 2x listItem padding (plus a little extra)
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0', // no need in full width mode
      },
      [theme.breakpoints.down('xs')]: {
        position: 'unset',
        maxHeight: '250px',
      },
    },
    listItem: {
      padding: '1em 0.5em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-between',
      textAlign: 'left',
      borderTop: '1px solid #dbdbdb',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    itemIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '0.5em',
      width: '40px',
    },
    title: {
      width: '100%',
    }
  }),
);

export default ResourceList;
