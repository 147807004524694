import {mobileNumberRegex} from "../schemas/publicSchemas";
import {
  AssessmentType,
  Patient,
  PatientSurgery,
  QuestionCategory,
  SyllabusType,
  Surgery,
  ResourceCategory, SurgeonSurgery, Surgeon, ConsentForm, ConsentFormSurgery
} from "../api/Types";
import moment from "moment/moment";
import {authClient} from "../api/Container";
import store from "../store/store";

export const tokenHasExpired = () => {
  const expiration = authClient.getExpirationDate();
  // check if token has expired and logout if so
  return expiration !== null ? moment().unix() >= expiration.unix() : false;
}

export const linkPatientSurgeries = (patients: Patient[], surgeries: Surgery[]): PatientSurgery[] => {
  return surgeries.map((surgery) => {
    const patient = patients.find((patient) => patient.patient_id === surgery.patient_id);

    if (!patient) {
      return null;
    }

    return {
      surgery: surgery,
      patient: patient,
    }
  }).filter(filterNull);
}

export const linkConsentFormSurgeries = (patients: Patient[], surgeries: Surgery[], consentForms: ConsentForm[]): ConsentFormSurgery[] => {
  return surgeries.map((surgery) => {
    const patient = patients.find((patient) => patient.patient_id === surgery.patient_id);
    const consentForm = consentForms.find((consentForm) => consentForm.surgery_id === surgery.surgery_id);

    if (!patient || !consentForm) {
      return null;
    }

    return {
      surgery: surgery,
      patient: patient,
      form: consentForm,
    }
  }).filter(filterNull);
}

export const linkSurgeonSurgeries = (surgeons: Surgeon[], surgeries: Surgery[]): SurgeonSurgery[] => {
  return surgeries.map((surgery) => {
    const surgeon = surgeons.find((surgeon) => surgeon.surgeon_id === surgery.surgeon_id);

    return {
      surgery: surgery,
      surgeon: surgeon ? surgeon : null,
    }
  }).filter(filterNull);
}

export const findAssessmentByType = (surgeryId: string, stepType: AssessmentType): QuestionCategory|null => {
  const assessments = store.getState().patient.assessment.full.data;

  if (assessments === null || assessments.surgery_id !== surgeryId) {
    return null;
  }

  const assessment = assessments.categories.find((a) => a.category === stepType);

  if (assessment === undefined) {
    return null
  }

  return assessment;
}

export const findResourceByType = (surgeryId: string, stepType: SyllabusType): ResourceCategory|null => {
  const resources = store.getState().patient.resource.full.data;

  if (resources === null || resources.surgery_id !== surgeryId) {
    return null;
  }

  const resource = resources.categories.find((r) => r.category === stepType);

  if (resource === undefined) {
    return null
  }

  return resource;
}

export const formatMobileNumber = (mobileNumber: string|null): string|null => {
  if (mobileNumber === null || mobileNumber === '') {
    return mobileNumber;
  }

  if (mobileNumber.match('^447')) {
    return '+' + mobileNumber;
  }

  if (mobileNumber.match('^4407')) {
    return '+44' + mobileNumber.substring(3);
  }

  if (mobileNumber.match('^\\+4407')) {
    return '+44' + mobileNumber.substring(4);
  }

  if (mobileNumber.match('^07')) {
    return '+44' + mobileNumber.substring(1);
  }

  if (mobileNumber.match('^7')) {
    return '+44' + mobileNumber;
  }

  return mobileNumber.match(new RegExp(mobileNumberRegex)) ? mobileNumber : null;
}

export const filterNull = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
}
