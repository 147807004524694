import AuthClient from "./AuthClient";
import AssessmentClient from "./AssessmentClient";
import SurgeryClient from "./SurgeryClient";
import ResourceClient from "./ResourceClient";
import NoteClient from "./NoteClient";
import CertificateClient from "./CertificateClient";
import ConsentFormClient from "./ConsentFormClient";

export const assessmentClient = new AssessmentClient();
export const authClient = new AuthClient();
export const certificateClient = new CertificateClient();
export const consentFormClient = new ConsentFormClient();
export const noteClient = new NoteClient();
export const resourceClient = new ResourceClient();
export const surgeryClient = new SurgeryClient();

