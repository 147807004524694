import {withStyles, createStyles, Theme} from "@material-ui/core/styles";
import {KeyboardDatePicker} from "@material-ui/pickers";

const CpDatePicker = withStyles((theme: Theme) => createStyles({
  root: {
    '& label': {
      color: theme.palette.text.primary,
      opacity: 0.8,
    },
    '& label.Mui-focused': {
      opacity: 1,
    },
  }
}))(KeyboardDatePicker);

export default CpDatePicker;
