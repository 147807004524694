import React, {FunctionComponent} from 'react';
import CpAppBar from "../../components/UI/CpAppBar";
import {Tabs, Tab, Hidden} from "@material-ui/core";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import logo from "../../resources/logo.png";
import Typography from "@material-ui/core/Typography";
import Footer from "../UI/Footer";

interface HomeContainerProps {}

const HomeContainer: FunctionComponent<HomeContainerProps> = props => {
  const classes = useStyles();
  const history = useHistory();

  const tabSelectedHandler = (event: React.ChangeEvent<{}>, value: any) => {
    history.push(value);
  }

  let authTabs = [
    <Tab className={classes.tab} key="patient-portal" label="Patient Portal" value="/patient/login" />,
    <Tab className={classes.tab} key="surgeon-portal" label="Surgeon Portal" value="/surgeon/login" />,
  ];

  return (
    <>
      <CpAppBar>
        <img src={logo} className={classes.logo} onClick={(e) => tabSelectedHandler(e, '/home')} alt="Consent Plus Logo" />
        <Typography className={classes.title} variant="h6"> Consent Plus </Typography>
        <Hidden xsDown>
          <Tabs className={classes.tabRight} value={false} textColor="primary" onChange={tabSelectedHandler}>
            {authTabs}
          </Tabs>
        </Hidden>
      </CpAppBar>
      <main className={classes.content}>
        {props.children}
      </main>
      <Footer />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  snackbar: {
    paddingTop: '4.5em',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '3.4em',
    },
  },
  alert: {
    padding: '0.5em 1.5em',
  },
  logo: {
    marginLeft: '1em',
    maxHeight: '45px',
    cursor: 'pointer',
  },
  title: {
    cursor: 'default',
    marginLeft: '1em',
  },
  tab: {
    marginRight: '2.5em',
    fontSize: '1.1em',
    '&:hover': {
      opacity: 0.7,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '0.5em',
    },
  },
  tabRight: {
    marginLeft: 'auto',
  },
  content: {
    flexGrow: 1,
    marginTop: '64px',
    minHeight: `calc(100vh - 128px)`,
  },
})));

export default HomeContainer;
