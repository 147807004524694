import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import React, {FunctionComponent} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const Footer: FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footerContainer}>
      <div className={classes.linkContainer}>
        <Link className={classes.privacyLink} rel="noreferrer" to="/privacy" target="_blank">
          <Typography variant="body2" align="center">Privacy Statement</Typography>
        </Link>
        <Typography variant="body2" align="center"> | </Typography>
        <Link className={classes.privacyLink} rel="noreferrer" to="/terms" target="_blank">
          <Typography variant="body2" align="center">Terms of Use</Typography>
        </Link>
      </div>
      <Typography variant="body2" align="center">
        &copy; ConsentPlus {moment().year()}, All Rights Reserved
      </Typography>
    </footer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerContainer: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      height: '64px',
      width: '100%',
      lineHeight: '64px',
      color: theme.palette.text.secondary,
    },
    linkContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: "space-around",
      alignItems: "center",
    },
    privacyLink: {
      margin: '0 0.75em',
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      '&:hover': {
        opacity: 0.7,
      },
    },
  }),
);

export default Footer;
