import React, {FunctionComponent} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  IconButton, Tooltip,
  Typography
} from "@material-ui/core";
import {
  Accessibility,
  ExpandMore,
  LocalHospital,
  Schedule,
  Visibility
} from "@material-ui/icons";
import moment from "moment/moment";
import {Surgery} from "../../api/Types";
import CpContentContainer from "../UI/CpContentContainer";

const SurgeonPatientSurgeries: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const error = useSelector((state: RootState) => state.surgeon.surgery.currentPatientSurgeries.error);
  const loading = useSelector((state: RootState) => state.surgeon.surgery.currentPatientSurgeries.loading);
  const patientSurgeries = useSelector((state: RootState) => state.surgeon.surgery.currentPatientSurgeries.data);

  const viewSurgery = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    history.push(`/surgeon/surgeries/${id}`);
  }

  return (
    <Card className={classes.cardSection}>
      <CardHeader className={classes.cardHeader} title="Surgeries" />
      <CpContentContainer error={error} loading={loading}>
        {patientSurgeries ? patientSurgeries?.map((surgery: Surgery) => {
          return (
            <Accordion style={{marginTop: '1em'}} key={surgery.surgery_id}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id={surgery.surgery_id}
              >
                <Typography className={classes.accordionHeader}>{surgery.operation_name} - {moment(surgery.surgery_created_at).format('LL')}</Typography>
                <Tooltip title="View Surgery" className={classes.viewIcon}>
                  <IconButton onClick={(e) => viewSurgery(e, surgery.surgery_id)} aria-label="view">
                    <Visibility />
                  </IconButton>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <CardContent className={classes.cardContent}>
                  <Typography variant="body2" paragraph><Accessibility className={classes.icon} />{surgery.operation_name} </Typography>
                  <Typography variant="body2" paragraph><LocalHospital className={classes.icon} />{surgery.hospital_name}, {surgery.hospital_city}</Typography>
                  <Typography variant="body2" paragraph><Schedule className={classes.icon} />
                    <Tooltip title="Date the consent process was started" placement="right"><span>{moment(surgery.surgery_created_at).format('LL')}</span></Tooltip>
                  </Typography>
                </CardContent>
              </AccordionDetails>
            </Accordion>)
        }) : null}
      </CpContentContainer>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  cardSection: {
    marginRight: '1em',
    backgroundColor: '#f5f5f5'
  },
  cardHeader: {
    backgroundColor: '#fff',
  },
  cardContent: {
    width: '100%',
    paddingTop: 0,
  },
  viewIcon: {
    marginLeft: 'auto',
  },
  accordionHeader: {
    paddingTop: '0.75em',
    paddingBottom: '0.75em',
  },
  icon: {
    verticalAlign: '-6px',
    marginRight: '0.5em',
  },
})));

export default SurgeonPatientSurgeries;
