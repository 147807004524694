import React from "react";
import { Route } from "react-router-dom";
import AdminHome from "../pages/Admin/AdminHome";
import AdminDashboard from "../components/Admin/AdminDashboard";

const AdminRoutes = () => {
  const container = (component: JSX.Element) => {
    return <AdminDashboard>{component}</AdminDashboard>
  }

  return [
    <Route key="admin/home" path="/admin" exact component={() => container(<AdminHome />)} />,
    <Route key="admin/dashboard" path="/admin/dashboard" exact component={() => container(<AdminHome />)} />,
  ];
}

export default AdminRoutes;
