import React, {FunctionComponent, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {PatientSurgery} from "../../api/Types";
import SurgeriesTableList from "./SurgeriesTableList";
import {linkPatientSurgeries} from "../../app/helpers";

const SurgeonSurgeriesTableList: FunctionComponent<{}> = () => {
  const patients = useSelector((state: RootState) => state.surgeon.auth.patients.data);
  const surgeries = useSelector((state: RootState) => state.surgeon.surgery.surgeries.data);
  const error = useSelector((state: RootState) => state.surgeon.surgery.surgeries.error);
  const loading = useSelector((state: RootState) => state.surgeon.surgery.surgeries.loading);
  const [patientSurgeries, setPatientSurgeries] = useState<PatientSurgery[]|null>(null);
  const [searchedPatientSurgeries, setSearchedPatientSurgeries] = useState<PatientSurgery[]|null>(null);

  useEffect(() => {
    setPatientSurgeries(linkPatientSurgeries(patients ?? [], surgeries ?? []))
  }, [patients, surgeries]);

  const searchPatients = (searchValue: string) => {
    if (patientSurgeries === null) {
      return;
    }

    const searchList = [...patientSurgeries].filter((patientSurgery) => {
      return `${patientSurgery.patient?.patient_first_name} ${patientSurgery.patient?.patient_last_name} ${patientSurgery.surgery.operation_name}`
        .toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    })

    setSearchedPatientSurgeries(searchList);
  }

  return <SurgeriesTableList
    error={error}
    loading={loading}
    patientSurgeries={searchedPatientSurgeries !== null ? searchedPatientSurgeries : patientSurgeries ?? []}
    onSearch={searchPatients}
  />
}

export default SurgeonSurgeriesTableList;
