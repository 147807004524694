import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardContent, CardHeader, Chip, Divider, Grid, Typography} from "@material-ui/core";
import {RootState} from "../../store/store";
import {initialiseSurgeonAccount} from "../../store/surgeon/surgeonAuthSlice";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {AssignmentInd, Cancel, CheckCircle, Email, Person, Phone} from "@material-ui/icons";
import {logout} from "../../store/public/publicAuthSlice";
import {useHistory} from "react-router-dom";
import {SurgeonStatus} from "../../api/Types";
import CpContentContainer from "../../components/UI/CpContentContainer";

const SurgeonAccount: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const auth = useSelector((state: RootState) => state.public.auth.token);
  const error = useSelector((state: RootState) => state.surgeon.auth.account.error);
  const loading = useSelector((state: RootState) => state.surgeon.auth.account.loading);
  const surgeon = useSelector((state: RootState) => state.surgeon.auth.account.data);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (surgeon === null && error === null) {
      dispatch(initialiseSurgeonAccount()).catch(e => {
        if(e.status === 401 || e.status === 403) {
          dispatch(logout());
          history.push('/surgeon/login');
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  if (auth === null || surgeon === null) {
    return null;
  }

  const name = `${surgeon.surgeon_title ?? ''} ${surgeon.surgeon_first_name} ${surgeon.surgeon_last_name}`
  const mobileNumber = surgeon.surgeon_mobile_number
  const verified = surgeon.surgeon_status === SurgeonStatus.VERIFIED
    ? <Chip className={classes.chip} color="primary" label="Verified" icon={<CheckCircle />} />
    : surgeon.surgeon_status === SurgeonStatus.NOT_VERIFIED
      ? <Chip className={classes.chip} color="secondary" label="Not Verified" icon={<Cancel />} />
      : <Chip className={classes.chip} color="secondary" label="Rejected" icon={<Cancel />} />

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Surgeon Profile</Typography>
        <Divider />
      </div>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Card className={classes.section}>
            <CardHeader title="About" action={verified} />
            <CpContentContainer error={error} loading={loading}>
              <CardContent>
                <Typography variant="body2" paragraph><Person className={classes.icon} />Name: {name} </Typography>
                <Typography variant="body2" paragraph><AssignmentInd className={classes.icon} />GMC Number: {surgeon.surgeon_gmc_number}</Typography>
                {mobileNumber ? <Typography variant="body2" paragraph><Phone className={classes.icon} />Mobile Number: {mobileNumber}</Typography> : null}
                <Typography variant="body2" paragraph><Email className={classes.icon} />Email: {surgeon.surgeon_email}</Typography>
              </CardContent>
            </CpContentContainer>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  section: {
    marginRight: '1em',
  },
  sectionTitle: {
    paddingBottom: '0.5em',
  },
  chip: {
    marginTop: '0.8em',
    marginRight: '0.5em',
  },
  icon: {
    verticalAlign: '-6px',
    marginRight: '0.5em',
  },
})));

export default SurgeonAccount;
