import {createSlice} from "@reduxjs/toolkit";
import {ConsentStep} from "../../api/Types";
import {findConsentStepById, getConsentSteps, getConsentStepsByIds} from "../../entities/ConsentStep";
import store from "../store";

const initialState: patientConsentState = {
  steps: getConsentSteps().map((step) => step.id),
  completedStep: getConsentSteps()[0].id,
  activeStep: getConsentSteps()[0].id,
  started: false,
}

export const patientConsentSlice = createSlice({
  name: 'patientConsent',
  initialState,
  reducers: {
    setCompletedStep: (state, action) => {
      state.completedStep = action.payload.step;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload.step;
    },
    clearSteps: (state) => {
      state.steps = initialState.steps;
      state.completedStep = initialState.completedStep;
      state.activeStep = initialState.activeStep;
      state.started = initialState.started;
    },
    setStarted: (state, action) => {
      state.started = action.payload.started;
    },
  },
});

export default patientConsentSlice.reducer;

// @ts-ignore as this is a middleware
export const setActiveStep = (step: ConsentStep): Promise<any> => async dispatch => {
  dispatch(patientConsentSlice.actions.setActiveStep({step: step.id}));
  return Promise.resolve();
}

// @ts-ignore as this is a middleware
export const setStarted = (): Promise<any> => async dispatch => {
  dispatch(patientConsentSlice.actions.setStarted({started: true}));
  return Promise.resolve();
}

// @ts-ignore as this is a middleware
export const navigateToLatestStep = (surgeryId: string): Promise<any> => async dispatch => {
  try {
    const steps = getConsentSteps()
    const latestCompleted = steps.filter((step: ConsentStep) => !step.completed(surgeryId)).shift();
    const stepIndex = steps.findIndex((step) => step.id === (latestCompleted ? latestCompleted.id : steps[0].id));
    dispatch(setCompletedStep(steps[stepIndex]));
    dispatch(setActiveStep(steps[stepIndex]));
    return Promise.resolve();
  } catch (e: any) {
    // do nothing
  }
}

// @ts-ignore as this is a middleware
export const setLatestCompletedStep = (surgeryId: string): Promise<any> => async dispatch => {
  try {
    const steps = getConsentSteps()
    const latestCompleted = steps.filter((step: ConsentStep) => !step.completed(surgeryId)).shift();
    const stepIndex = steps.findIndex((step) => step.id === (latestCompleted ? latestCompleted.id : steps[0].id));
    console.log(surgeryId, stepIndex)
    dispatch(setCompletedStep(steps[stepIndex]));
    return Promise.resolve();
  } catch (e: any) {
    // do nothing
  }
}

// @ts-ignore as this is a middleware
export const clearSteps = (): Promise<any> => async dispatch => {
  dispatch(patientConsentSlice.actions.clearSteps());
  return Promise.resolve();
}

// @ts-ignore as this is a middleware
export const nextConsentCompletedStep = (surgeryId: string): Promise<any> => async dispatch => {
  const consentState = store.getState().patient.consent;
  const steps = getConsentStepsByIds(consentState.steps);
  const currentStep = findConsentStepById(consentState.completedStep);
  let stepIndex = steps.findIndex((step) => step.id === currentStep.id);

  stepIndex = currentStep.completed(surgeryId) ? stepIndex + 1 : stepIndex;

  if (steps[stepIndex]) {
    dispatch(setCompletedStep(steps[stepIndex]));
    dispatch(setActiveStep(steps[stepIndex]));
    return Promise.resolve();
  }
}

// @ts-ignore as this is a middleware
export const setCompletedStep = (step: ConsentStep): Promise<any> => async dispatch => {
    dispatch(patientConsentSlice.actions.setCompletedStep({step: step.id}));
    return Promise.resolve();
}

type patientConsentState = {
  steps: string[],
  completedStep: string,
  activeStep: string,
  started: boolean,
}
