import React, {FunctionComponent} from 'react';
import {Grid} from "@material-ui/core";
import PasswordResetForm from "../../components/Public/PasswordResetForm";

const PasswordResetPage: FunctionComponent<{}> = () => {
  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={9} md={6}>
        <PasswordResetForm />
      </Grid>
    </Grid>
  )
}

export default PasswordResetPage;
