import React, {FunctionComponent} from 'react';
import {Card, CardContent, CardHeader, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Email, Person} from "@material-ui/icons";
import CpContentContainer, {LoadingErrorProps} from "../../components/UI/CpContentContainer";
import {Patient} from "../../api/Types";

interface PatientDetailsProps extends LoadingErrorProps {
  patient?: Patient|null
  title?: string
}

const PatientDetails: FunctionComponent<PatientDetailsProps> = props => {
  const classes = useStyles();

  return <Card className={classes.cardSection}>
    <CardHeader title={props.title ?? "About"} />
    <CpContentContainer error={props.error} loading={props.loading}>
      {props.patient ?
        <CardContent>
          <Typography variant="body2" paragraph>
            <Person className={classes.icon} />
            {`${props.patient.patient_first_name} ${props.patient.patient_last_name}`}
          </Typography>
          <Typography variant="body2" paragraph><Email className={classes.icon} />{props.patient.patient_email}</Typography>
        </CardContent>
      : null }
    </CpContentContainer>
  </Card>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  cardSection: {
    marginRight: '1em',
  },
  icon: {
    verticalAlign: '-6px',
    marginRight: '0.5em',
  },
})));

export default PatientDetails;
