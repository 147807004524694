import {createSlice} from "@reduxjs/toolkit";
import {noteClient} from "../../api/Container";
import {Error, Loading} from "../store";
import {Note} from "../../api/Types";

const initialState: patientNotesState = {
  current: {
    error: null,
    loading: false,
    data: null
  },
}

export const patientNoteSlice = createSlice({
  name: 'patientNotes',
  initialState,
  reducers: {
    setCurrentNotes: (state, action) => {
      state.current.data = action.payload.notes;
      state.current.error = null;
      state.current.loading = false;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default patientNoteSlice.reducer;

// @ts-ignore as this is a middleware
export const addNote = (surgeryId: string, content: string): Promise<any> => async dispatch => {
  dispatch(patientNoteSlice.actions.setStart({index: 'current'}));

  try {
    const response = await noteClient.createSurgeryNote(surgeryId, content);
    await dispatch(getSurgeryNotes(surgeryId));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientNoteSlice.actions.setEnd({index: 'current', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getSurgeryNotes = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(patientNoteSlice.actions.setStart({index: 'current'}));

  try {
    const response = await noteClient.getSurgeryNotes(surgeryId);
    dispatch(patientNoteSlice.actions.setCurrentNotes({notes: response.data.surgery_notes}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientNoteSlice.actions.setEnd({index: 'current', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

type patientNotesState = {
  current: {
    error: Error
    loading: Loading
    data: Note[]|null
  }
}
