import React, {FunctionComponent, useEffect} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import PatientLoginForm from "../../components/Public/PatientLoginForm";
import {authClient} from "../../api/Container";
import {tokenHasExpired} from "../../app/helpers";
import {logout} from "../../store/public/publicAuthSlice";
import {useDispatch} from "react-redux";
import CpContentContainer from "../../components/UI/CpContentContainer";

const PatientLoginPage: FunctionComponent<{}> = () => {
  const roles = authClient.getAuthRoles();
  const token = authClient.getToken();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if(tokenHasExpired()) {
      dispatch(logout());
    }

    if (roles && token && roles.includes('PATIENT')) {
      history.push('/patient/dashboard');
    }
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container justify="center">
      <CpContentContainer loading={loading}>
        <Grid item xs={12} sm={9} md={6}>
          <Typography paragraph>Got here accidentally? <Link to="/home">Go home</Link></Typography>
          <Typography>Haven't got an account? <Link to="/patient/register">Please register</Link></Typography>
          <PatientLoginForm />
        </Grid>
      </CpContentContainer>
    </Grid>
  )
}

export default PatientLoginPage;
