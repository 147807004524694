import React, {FunctionComponent} from 'react';
import {Divider, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import FindOrCreatePatientForm from "../../components/Forms/FindOrCreatePatientForm";
import SelectOperationForm from "../../components/Forms/SelectOperationForm";
import SelectHospitalForm from "../../components/Forms/SelectHospitalForm";
import CpContentContainer from "../../components/UI/CpContentContainer";
import {useDispatch} from "react-redux";
import {createSurgery} from "../../store/patient/patientSurgerySlice";
import {authClient} from "../../api/Container";
import {Alert} from "@material-ui/lab";
import {getListOfSurgeonPatients} from "../../store/surgeon/surgeonAuthSlice";
import {getSurgeries} from "../../store/surgeon/surgeonSurgerySlice";

const SurgeonConsentProcessCreate: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [patientId, setPatientId] = React.useState<null|string>(null);
  const [operationId, setOperationId] = React.useState<null|string>(null);
  const [hospitalId, setHospitalId] = React.useState<null|string>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null|string>(null);
  const [success, setSuccess] = React.useState(false);

  const submitHandler = async (hospital: string) => {
    setLoading(true);
    setError(null);

    if (patientId === null || operationId === null) {
      return;
    }

    try {
      await dispatch(createSurgery(patientId, operationId, authClient.getSurgeonId(), hospital));
      await Promise.all([
        dispatch(getListOfSurgeonPatients()),
        dispatch(getSurgeries())
      ]);
      setLoading(false);
      setSuccess(true);
    } catch (e: any) {
      setError(e.data.data.errors[0]);
    }
  }

  const confirmPatient = (patientId: string) => {
    setPatientId(patientId);
  }

  const confirmOperation = (operationId: string) => {
    setOperationId(operationId);
  }

  const confirmHospital = (hospitalId: string) => {
    setHospitalId(hospitalId);
    submitHandler(hospitalId);
  }

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5" paragraph>Create a consent process on your patient's behalf</Typography>
        <Typography variant="body1" paragraph>Please create a consent process on your patient's behalf. Find their account (list, by email or create a patient account) and select the operation.</Typography>
        <Typography variant="body1" paragraph>The benefit is their progress is visible throughout, and you ensure they are given the correct content.</Typography>
        <Divider />
      </div>
      <div className={classes.contentContainer}>
        {
          patientId === null ? <FindOrCreatePatientForm onSubmit={confirmPatient} />
          : operationId === null ? <SelectOperationForm onSubmit={confirmOperation} />
          : hospitalId === null ? <SelectHospitalForm onSubmit={confirmHospital} />
          : success
            ? <Alert severity="success">You have successfully created your patient's consent form</Alert>
            : <CpContentContainer error={error} loading={loading}/>
        }
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  contentContainer: {
    padding: '0 4em',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    }
  },
  success: {
    margin: '5em',
  },
  comingSoon: {
    padding: '3em',
  },
})));

export default SurgeonConsentProcessCreate;
