import React, {FunctionComponent, useEffect} from 'react';
import {AccountCircle, BorderColor, CardMembership, Help, Home, People} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import CpAppBar from "../UI/CpAppBar";
import NavDrawer from "../UI/NavDrawer";
import NavList from "../UI/NavList";
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Button, Divider, Grid, Hidden} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/public/publicAuthSlice";
import {initialiseSurgeonAccount} from "../../store/surgeon/surgeonAuthSlice";
import consentLogo from '../../resources/consent-logo.png';
import logo from "../../resources/logo.png";
import {RootState} from "../../store/store";
import {authClient} from "../../api/Container";
import {tokenHasExpired} from "../../app/helpers";

interface SurgeonDashboardProps {
  displayDrawer?: boolean
}

const SurgeonDashboard: FunctionComponent<SurgeonDashboardProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const listItems = getSurgeonListItems();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const roles = authClient.getAuthRoles();
  const surgeon = useSelector((state: RootState) => state.surgeon.auth.account.data);

  const isAdmin = roles !== null ? roles.includes('ADMIN') : false;

  useEffect(() => {
    // check if token has expired and logout if so
    if(tokenHasExpired()) {
      dispatch(logout());
      history.push('/surgeon/login');
      return;
    }

    if (surgeon === null) {
      dispatch(initialiseSurgeonAccount()).catch(err => {
        if(err.status === 401 || err.status === 403) {
          dispatch(logout());
          history.push('/surgeon/login');
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const navDrawerHandler = (itemSelected: string) => {
    const index = listItems.findIndex(element => element.text === itemSelected);
    const element = listItems[index];

    history.push(`/surgeon${element ? element.route : ''}`);
  }

  const navBarHandler = (route: string) => {
    history.push(route);
  }

  const logoutHandler = () => {
    dispatch(logout()).then(() => {
      history.push('/home');
    }).catch(() => {
      history.push('/home');
    })
  }

  const drawerToggleHandler = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <CpAppBar className={classes.appBar} menuIconClick={(props.displayDrawer ?? true) ? drawerToggleHandler : undefined}>
        <Grid container>
          <Grid container item sm={6} md={5}>
            <Hidden xsDown>
              <img src={logo} className={classes.logo} onClick={() => navBarHandler('/home')} alt="Consent Plus Logo" />
              <Typography className={classes.title} variant="h6" noWrap> Surgeon Portal </Typography>
            </Hidden>
          </Grid>
          <Grid container item xs={9} sm={6} md={7} direction="row" justify="flex-end">
            {isAdmin
              ? <Button onClick={() => navBarHandler('/admin')} className={classes.navItem} color="inherit">Admin</Button>
              : null
            }
            <Button onClick={logoutHandler} className={classes.navItem} color="inherit">Logout</Button>
          </Grid>
        </Grid>
      </CpAppBar>
      {(props.displayDrawer ?? true)
        ? <NavDrawer open={drawerOpen} onClose={drawerToggleHandler}>
          <NavList onNavSelect={navDrawerHandler} listItems={listItems} />
          <div className={classes.bottomDivider}>
            <Divider />
            <img onClick={() => navBarHandler('/home')} src={consentLogo} className={classes.bottomDivideLogo} alt="Consent Plus Logo" />
          </div>
        </NavDrawer>
        : null
      }
      <main className={classes.content}>{ props.children }</main>
    </div>
  )
}

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '2.5em',
      },
    },
    logo: {
      maxHeight: '45px',
      cursor: 'pointer',
    },
    title: {
      marginLeft: '1em',
      marginRight: '1.3em',
      lineHeight: '2.5',
      width: '180px'
    },
    content: {
      flexGrow: 1,
      marginTop: '64px',
      padding: theme.spacing(2),
    },
    navItem: {
      marginRight: '2em',
    },
    bottomDivider: {
      position: 'fixed',
      bottom: 0,
      maxHeight: '120px',
      width: drawerWidth,
    },
    bottomDivideLogo: {
      cursor: 'pointer',
      maxHeight: '70px',
      marginLeft: '2.5em',
    },
  }),
);

type listItem = {
  text: string,
  icon: JSX.Element
  route: string,
}

const getSurgeonListItems = (): listItem[] => [
  {
    text: 'Home',
    icon: <Home />,
    route: '/dashboard'
  },
  {
    text: 'Patients',
    icon: <People />,
    route: '/patients'
  },
  {
    text: 'Surgeries',
    icon: <CardMembership />,
    route: '/surgeries'
  },
  {
    text: 'Consent Forms',
    icon: <BorderColor />,
    route: '/consent-form'
  },
  {
    text: 'My Account',
    icon: <AccountCircle />,
    route: '/account'
  },
  {
    text: 'Help',
    icon: <Help />,
    route: '/help'
  },
];

export default SurgeonDashboard;
