import React, {FunctionComponent} from 'react';
import {Card, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography} from "@material-ui/core";
import {Visibility} from "@material-ui/icons";
import {PatientSurgery} from "../../api/Types";
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpSearch from "../UI/CpSearch";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";

interface SurgeriesTableListProps extends LoadingErrorProps {
  patientSurgeries: PatientSurgery[],
  className?: string,
  onSearch?: (searchText: string) => void,
}

const SurgeriesTableList: FunctionComponent<SurgeriesTableListProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const viewPatient = (surgeryId: string) => {
    history.push(`/surgeon/surgeries/${surgeryId}`)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSearch = (searchText: string) => {
    if (page !== 0) {
      setPage(0);
    }

    if (props.onSearch !== undefined) {
      props.onSearch(searchText);
    }
  };

  return (
    <Card className={props.className}>
      <Grid container>
        <Grid item xs={12} md={7} lg={5} className={classes.section}>
          <Typography variant="h5">Latest Surgeries</Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={7} className={classes.section}>
          {props.onSearch !== undefined ? <CpSearch onSearch={onSearch} /> : null }
        </Grid>
      </Grid>
      <CpContentContainer error={props.error} loading={props.loading}>
        <TableContainer className={classes.tableSection} component={Paper}>
          <Table aria-label="Latest surgeries table" size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Actions</strong></TableCell>
                <TableCell><strong>Patient Name</strong></TableCell>
                <TableCell><strong>Operation</strong></TableCell>
                <TableCell><strong>Hospital</strong></TableCell>
                <TableCell><strong>Consent Started</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.patientSurgeries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(patientSurgery => (
                <TableRow key={patientSurgery.surgery.surgery_id}>
                  <TableCell>
                    <Tooltip title="View Surgery">
                      <IconButton onClick={() => viewPatient(patientSurgery.surgery.surgery_id)} aria-label="view">
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {patientSurgery.patient.patient_first_name} {patientSurgery.patient.patient_last_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {patientSurgery.surgery.operation_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {patientSurgery.surgery.hospital_name}
                  </TableCell>
                  <TableCell>{patientSurgery.surgery.surgery_created_at.substring(0,10)}</TableCell>
                </TableRow>
              )) ?? []}
            </TableBody>
          </Table>
        </TableContainer>
      </CpContentContainer>
      {props.patientSurgeries.length > rowsPerPage
        ? <TablePagination
          component="div"
          count={props.patientSurgeries.length}
          rowsPerPageOptions={[10]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
        : null
      }

    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  section: {
    padding: '1em',
  },
  tableSection: {
    marginTop: '1em'
  }
})));


export default SurgeriesTableList;
