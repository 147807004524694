import React from "react";
import { Route } from "react-router-dom";
import SurgeonHome from "../pages/Surgeon/SurgeonHome";
import SurgeonAccount from "../pages/Surgeon/SurgeonAccount";
import SurgeonDashboard from "../components/Surgeon/SurgeonDashboard";
import SurgeonPatients from "../pages/Surgeon/SurgeonPatients";
import SurgeonPatientAccount from "../pages/Surgeon/SurgeonPatientAccount";
import SurgeonPatientSurgery from "../pages/Surgeon/SurgeonPatientSurgery";
import SurgeonSurgeries from "../pages/Surgeon/SurgeonSurgeries";
import SurgeonConsentProcessLink from "../pages/Surgeon/SurgeonConsentProcessLink";
import SurgeonSurgeryNotesPage from "../pages/Surgeon/SurgeonSurgeryNotesPage";
import SurgeonConsentProcessCreate from "../pages/Surgeon/SurgeonConsentProcessCreate";
import SurgeonHelpPage from "../pages/Surgeon/SurgeonHelpPage";
import SurgeonInformationPage from "../pages/Surgeon/SurgeonInformationPage";
// import SurgeonFaqPage from "../pages/Surgeon/SurgeonFaqPage";
import SurgeonPatientConsentProcessHelpPage from "../pages/Surgeon/SurgeonPatientConsentProcessHelpPage";
import SurgeonConsentFormPage from "../pages/Surgeon/SurgeonConsentFormPage";
import SurgeonCertificatePage from "../pages/Surgeon/SurgeonCertificatePage";
import SurgeonConsentFormsPage from "../pages/Surgeon/SurgeonConsentFormsPage";

const SurgeonRoutes = () => {
  const container = (component: JSX.Element, showNav: boolean = true) => {
    return <SurgeonDashboard displayDrawer={showNav}>{component}</SurgeonDashboard>
  }

  return [
    <Route key="surgeon/dashboard" path="/surgeon/dashboard" exact component={() => container(<SurgeonHome />)} />,
    <Route key="surgeon/home" path="/surgeon/home" exact component={() => container(<SurgeonHome />)} />,
    <Route key="surgeon/account" path="/surgeon/account" exact component={() => container(<SurgeonAccount />)} />,

    <Route key="surgeon/patients" path="/surgeon/patients" exact component={() => container(<SurgeonPatients />)} />,
    <Route key="surgeon/patients/:id" path="/surgeon/patients/:id" exact component={() => container(<SurgeonPatientAccount />)} />,

    <Route key="surgeon/surgeries" path="/surgeon/surgeries" exact component={() => container(<SurgeonSurgeries />)} />,
    <Route key="surgeon/surgeries/:id" path="/surgeon/surgeries/:id" exact component={() => container(<SurgeonPatientSurgery />)} />,

    <Route key="surgeon/consent-process/link" path="/surgeon/consent-process/link" exact component={() => container(<SurgeonConsentProcessLink />)} />,
    <Route key="surgeon/consent-process/create" path="/surgeon/consent-process/create" exact component={() => container(<SurgeonConsentProcessCreate />)} />,

    <Route key="surgeon/consent-form" path="/surgeon/consent-form" exact component={() => container(<SurgeonConsentFormsPage />)} />,
    <Route key="surgeon/consent-form/:id" path="/surgeon/consent-form/:id" exact component={() => container(<SurgeonConsentFormPage />)} />,

    <Route key="surgeon/certificate/:id" path="/surgeon/certificate/:id" exact component={() => container(<SurgeonCertificatePage />)} />,

    <Route key="surgeon/notes" path="/surgeon/notes" exact component={() => container(<SurgeonSurgeryNotesPage />, false)} />,
    <Route key="surgeon/notes" path="/surgeon/notes/:id" exact component={() => container(<SurgeonSurgeryNotesPage />, false)} />,

    <Route key="surgeon/help" path="/surgeon/help" exact component={() => container(<SurgeonHelpPage />)} />,
    <Route key="surgeon/help/information" path="/surgeon/help/information" exact component={() => container(<SurgeonInformationPage />)} />,
    // <Route key="surgeon/help/faq" path="/surgeon/help/faq" exact component={() => container(<SurgeonFaqPage />)} />,
    <Route key="surgeon/help/consent-process" path="/surgeon/help/consent-process" exact component={() => container(<SurgeonPatientConsentProcessHelpPage />, false)} />,

    <Route key="surgeon/home" path="/surgeon/" exact component={() => container(<SurgeonHome />)} />,
  ];
}

export default SurgeonRoutes;
