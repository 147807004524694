import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ConsentStep} from "../../../../api/Types";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment, {Moment} from "moment/moment";
import CpContentContainer from "../../../UI/CpContentContainer";
import SelectSurgeonForm from "../../../Forms/SelectSurgeonForm";
import SelectHospitalForm from "../../../Forms/SelectHospitalForm";
import ConfirmSurgery from "../../../Forms/ConfirmSurgery";
import {updateSurgery, getCurrentSurgery} from "../../../../store/patient/patientSurgerySlice";
import {RootState} from "../../../../store/store";
import SelectSurgeryDateForm from "../../../Forms/SelectSurgeryDateForm";
import CpButton from "../../../UI/CpButton";
import {ArrowBack, ArrowForward, NavigateNext} from "@material-ui/icons";
import {Typography} from "@material-ui/core";
import {navigateToLatestStep} from "../../../../store/patient/patientConsentSlice";
import EmailSurgeonForm from "../../../Forms/EmailSurgeonForm";
import {Alert} from "@material-ui/lab";

interface FinaliseSurgeryDetailsProcessProps {
  step: ConsentStep
}

const FinaliseSurgeryDetailsProcess: FunctionComponent<FinaliseSurgeryDetailsProcessProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const surgery = useSelector((state: RootState) => state.patient.surgery.current.data);
  const [surgeon, setSurgeon] = React.useState<undefined|null|string>(surgery?.surgeon_id ?? undefined);
  const [hospital, setHospital] = React.useState<undefined|null|string>(surgery?.hospital_id ?? undefined);
  const [surgeryDate, setSurgeryDate] = React.useState<undefined|null|Moment>(surgery?.surgery_date ? moment(surgery.surgery_date) : undefined);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string|null>(null);
  const [emailSurgeon, setEmailSurgeon] = React.useState<boolean>(false);
  const [emailSurgeonSent, setEmailSurgeonSent] = React.useState<boolean>(false);

  useEffect(() => {
    if(surgery === null) {
      dispatch(getCurrentSurgery(params.id));
    }
    // eslint-disable-next-line
  }, [params.id]);

  const emailSurgeonHandler = (findSurgeon: boolean) => {
    setEmailSurgeon(findSurgeon)
  }

  const surgeonHandler = (selectedSurgeon: string) => {
    setSurgeon(selectedSurgeon);
  }

  const hospitalHandler = (selectedHospital: string) => {
    setHospital(selectedHospital);
  }

  const surgeryDateHandler = (selectedSurgeryDate: Moment) => {
    setSurgeryDate(selectedSurgeryDate);
  };

  const resetHandler = () => {
    setHospital(undefined);
    setSurgeon(undefined);
    setSurgeryDate(undefined);
    setSubmitError(null);
    setSubmitLoading(false);
  }

  const submitHandler = async () => {
    try {
      setSubmitLoading(true);
      if (!hospital || !surgeon) {
        setSubmitError('You must submit after selecting a hospital and surgeon')
        return;
      }

      await dispatch(updateSurgery(hospital, surgeon, params.id, surgeryDate));
      await dispatch(navigateToLatestStep(params.id));
    } catch (e: any) {
      setSubmitLoading(false);
      setSubmitError(e.data.data.errors[0]);
    }
  }

  return <div className={classes.container}>
    {/* SURGEON */}
    {surgeon === undefined
      ? <> {emailSurgeon
        ? <Typography className={classes.surgeonEmailLink} onClick={() => emailSurgeonHandler(false)} variant="h6" align="center" paragraph><span className={classes.arrow}><ArrowBack /></span> Go back to select your surgeon from our verified list</Typography>
        : <Typography className={classes.surgeonEmailLink} onClick={() => emailSurgeonHandler(true)} variant="h6" align="center" paragraph>If you cannot find your surgeon please provide their email <span className={classes.arrow}><ArrowForward /></span></Typography>
        }
        {emailSurgeonSent
          ? <Alert variant="filled" severity="success">We have sent an email to your surgeon. Unfortunately you cannot sign the consent form until they are registered on our system</Alert>
          : emailSurgeon ? <EmailSurgeonForm onComplete={() => setEmailSurgeonSent(true)} /> : <SelectSurgeonForm onSubmit={surgeonHandler}/>
        }</>
      : null
    }

    {/* HOSPITAL */}
    {surgeon !== undefined && hospital === undefined ? <SelectHospitalForm onSubmit={hospitalHandler} />: null}

    {/* Surgery Date */}
    {hospital !== undefined && surgeryDate === undefined ?
      <><CpButton className={classes.skipButton} variant="contained" color="primary" size="large" endIcon={<NavigateNext />} onClick={() => setSurgeryDate(null)}>Skip Surgery Date</CpButton>
      <Typography variant="h6" align="center" paragraph>We recommend you select your expected date of surgery, however please use the skip button above if unsure.</Typography>
      <SelectSurgeryDateForm onSubmit={surgeryDateHandler} /></>
    : null}

    {/* CONFIRM SURGERY */}
    {surgeon !== undefined && hospital !== undefined && surgeryDate !== undefined
      ? <ConfirmSurgery
        operationId={surgery?.operation_id ?? ''}
        surgeonId={surgeon ?? ''}
        hospitalId={hospital ?? ''}
        surgeryDate={surgeryDate}
        onConfirm={submitHandler}
        onCancel={resetHandler}
      />
      : null
    }
    <CpContentContainer error={submitError} loading={submitLoading} />
  </div>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '2em 5em',
      [theme.breakpoints.down('sm')]: {
        padding: '2em 1em',
      },
    },
    skipButton: {
      width: '50%',
      margin: '0 auto 1.5em auto',
      [theme.breakpoints.down('sm')]: {
        width: '75%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    arrow: {
      position: 'relative',
      top: '5px',
      fontSize: 38,
      fontWeight: 600,
      marginLeft: '0.1em',
    },
    surgeonEmailLink: {
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  }),
);

export default FinaliseSurgeryDetailsProcess;
