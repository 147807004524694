import axios from 'axios';
import download from "downloadjs";

const certificateInstance = axios.create({
  baseURL: '/api/certificate',
});

certificateInstance.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;

export default class CertificateClient {
  findCertificate = (surgeryId: string) => {
    this.authorize();

    return certificateInstance.get(`/surgery/${surgeryId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  downloadCertificate = (surgeryId: string) => {
    this.authorize();

    return certificateInstance.get(`/download/surgery/${surgeryId}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    })
      .then(response => {
        download(new Blob([response.data], { type: "application/pdf" }), 'consent-certificate.pdf');
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  previewCertificate = (surgeryId: string) => {
    this.authorize();

    return certificateInstance.get(`/preview/surgery/${surgeryId}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    })
      .then(response => {
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        return Promise.resolve(URL.createObjectURL(file))
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  createCertificate = (surgeryId: string, consented: boolean) => {
    this.authorize();

    return certificateInstance.post(`/surgery/${surgeryId}`, {consented})
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  private authorize = () => {
    certificateInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }
}
