import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {QuestionAnswer} from "../../../api/Types";
import AssessmentQuestionAnswer from "../AssessmentQuestionAnswer";
import PreviousNextButtons from "../Assessment/PreviousNextButtons";

interface QuestionAnswerContainerProps {
  questionAnswers: QuestionAnswer[]
}

const QuestionAnswerContainer: FunctionComponent<QuestionAnswerContainerProps> = props => {
  const classes = useStyles();
  const [currentQuestionKey, setCurrentQuestionKey] = React.useState<number>(0);
  const [questionAnswers, setQuestionAnswers] = React.useState<QuestionAnswer[]|null>(null);

  useEffect(() => {
    setQuestionAnswers(props.questionAnswers);
  }, [props.questionAnswers])

  const answerHandler = (answer: string) => {
    if (questionAnswers !== null) {
      const answers = [...questionAnswers];
      answers[currentQuestionKey].patient_answer = answer;
      setQuestionAnswers(answers);
      setTimeout(() => {
        nextQuestionHandler();
      }, 400);
    }
  }

  const nextQuestionHandler = async () => {
    const key = (currentQuestionKey ?? 0) + 1;

    if (key !== props.questionAnswers.length) {
      setCurrentQuestionKey(props.questionAnswers[key] ? key : 0)
    }
  }

  const previousQuestionHandler = async () => {
    const key = (currentQuestionKey ?? 0) - 1;
    setCurrentQuestionKey(props.questionAnswers[key] ? key : props.questionAnswers.length - 1)
  }

  if (questionAnswers === null) {
    return null;
  }

  return (
    <>
      <div className={classes.assessmentContainer}>
        <AssessmentQuestionAnswer
          question={questionAnswers[currentQuestionKey ?? 0]}
          answerHandler={answerHandler}
          questionPosition={currentQuestionKey !== null ? (currentQuestionKey + 1) : 1}
          questionTotalCount={questionAnswers.length}
        />
      </div>
      <PreviousNextButtons previousQuestion={previousQuestionHandler} nextQuestion={nextQuestionHandler} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    assessmentContainer: {
      padding: '1em',
      [theme.breakpoints.down('sm')]: {
        padding: '1em 0',
      }
    },
  }),
);

export default QuestionAnswerContainer;
