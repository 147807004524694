import axios from 'axios';
import {Credentials, Patient, Surgeon, SurgeonStatus, UpdatePatient} from "./Types";
import moment, {Moment} from "moment/moment";

const authInstance = axios.create({
  baseURL: '/api/auth',
});

authInstance.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;

export default class AuthClient {
  getSurgeon = (surgeonId: string|null) => {
    this.authorize();

    if (surgeonId === null) {
      surgeonId = 'me'
    }

    return authInstance.get(`/surgeon/${surgeonId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getListOfSurgeons = () => {
    this.authorize();

    return authInstance.get('/patient/surgeons/me')
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getAllSurgeons = () => {
    this.authorize();

    return authInstance.get(`/surgeons`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getPatient = (patientId: string|null) => {
    this.authorize();

    if (patientId === null) {
      patientId = 'me'
    }

    return authInstance.get(`/patient/${patientId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  findPatientByEmail = (email: string|null) => {
    this.authorize();

    return authInstance.get(`/patient`, {
        params: {
          email: email
        }
      }).then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  emailSurgeonForRegistration = (email: string|null) => {
    this.authorize();

    return authInstance.post('/surgeon/email-registration', {
      email: email
    }).then(response => {
      return Promise.resolve(response.data)
    }).catch(error => {
      return Promise.reject(error.response)
    });
  }

  updatePatient = (patientId: string|null, patient: UpdatePatient) => {
    this.authorize();

    if (patientId === null) {
      patientId = 'me'
    }

    return authInstance.put(`/patient/${patientId}`, patient)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getListOfPatients = () => {
    this.authorize();

    return authInstance.get('/surgeon/patients/me')
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getUnverifiedSurgeons = () => {
    this.authorize();

    return authInstance.get('/surgeon/unverified')
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  setSurgeonStatus = (surgeonId: string, status: SurgeonStatus) => {
    this.authorize();

    return authInstance.post('/surgeon/status', {
      surgeon_id: surgeonId,
      status: status
    })
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  registerSurgeon = (surgeon: Surgeon) => {
    return authInstance.post(
      '/surgeon/register',
      {
        title: surgeon.surgeon_title,
        first_name: surgeon.surgeon_first_name,
        last_name: surgeon.surgeon_last_name,
        gmc_number: surgeon.surgeon_gmc_number,
        mobile_number: surgeon.surgeon_mobile_number,
        email: surgeon.surgeon_email,
        password: surgeon.surgeon_password,
      }
    ).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response);
    })
  }

  registerPatient = (patient: Patient) => {
    return authInstance.post(
      '/patient/register',
      {
        title: patient.patient_title,
        first_name: patient.patient_first_name,
        last_name: patient.patient_last_name,
        mobile_number: patient.patient_mobile_number,
        email: patient.patient_email,
        password: patient.patient_password,
      }
    ).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response);
    })
  }

  login = (credentials: Credentials) => {
    this.removeAuth();

    return authInstance.post(
      '/login',
      {
        email: credentials.email,
        password: credentials.password,
      },
    ).then(async response => {
      await this.setToken(response.data.data.access_token);
      await this.setSurgeonId(response.data.data.surgeon_id);
      await this.setPatientId(response.data.data.patient_id);
      await this.setExpirationDate(response.data.data.expires_at);
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response);
    })
  }

  logout = () => {
    authInstance.defaults.headers.common['Authorization'] = this.getToken();

    this.removeAuth();
    return authInstance.post('/logout').then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response)
    });
  }

  refreshToken = () => {
    authInstance.defaults.headers.common['Authorization'] = this.getToken();

    return authInstance.post('/token/refresh').then(async response => {
      await this.setToken(response.data.data.access_token);
      await this.setSurgeonId(response.data.data.surgeon_id);
      await this.setPatientId(response.data.data.patient_id);
      await this.setExpirationDate(response.data.data.expires_at);
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response);
    });
  }

  resetPasswordRequest = (email: string) => {
    return authInstance.post('/password/reset-request', {email}).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response);
    })
  }

  checkResetPasswordToken = (token: string) => {
    return authInstance.get(`/password/reset-request?token=${token}`).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response);
    })
  }

  resetPassword = (email: string, password: string, token: string) => {
    return authInstance.post('/password/reset', {email, password, token}).then(response => {
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.response);
    })
  }

  getToken = (): string|null => {
    return localStorage.getItem('token');
  }

  getAuthRoles = (): string[]|null => {
    const token = this.decryptToken();

    if (token === null) {
      return token
    }

    return token.auth_roles;
  }

  getAuthId = (): string => {
    const token = this.decryptToken();

    if (token === null) {
      return ''
    }

    return token.auth_id;
  }

  getSurgeonId = (): string|null => {
    return localStorage.getItem('surgeonId');
  }

  getPatientId = (): string|null => {
    return localStorage.getItem('patientId');
  }

  getExpirationDate = (): Moment|null => {
    const expiration = localStorage.getItem('expirationDate');
    return expiration !== null ? moment(parseInt(expiration)) : null;
  }

  private setToken = (token: string) => {
    localStorage.setItem('token', token);
  }

  private setSurgeonId = (surgeonId: string|null) => {
    if (surgeonId !== null) {
      localStorage.setItem('surgeonId', surgeonId);
    }
  }

  private setPatientId = (patientId: string|null) => {
    if (patientId !== null) {
      localStorage.setItem('patientId', patientId);
    }
  }

  private setExpirationDate = (expiresAt: number) => {
    localStorage.setItem('expirationDate', (expiresAt * 1000).toString());
  }

  private removeAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('surgeonId');
    localStorage.removeItem('patientId');
    localStorage.removeItem('expirationDate');
  }

  private authorize = () => {
    authInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }

  private decryptToken = () => {
    const token = this.getToken();

    if (token === null) {
      return null
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}
