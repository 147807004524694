import React, {FunctionComponent} from 'react';
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import CpLoading from "./CpLoading";
import Typography from "@material-ui/core/Typography";

export interface LoadingErrorProps {
  loading?: boolean|null
  error?: string| null
}

interface CpContentContainerProps extends LoadingErrorProps {}

const CpContentContainer: FunctionComponent<CpContentContainerProps> = props => {
  const classes = useStyles();

  if (props.error) {
    return <div className={classes.container}><Typography paragraph>{props.error}</Typography></div>;
  }

  if (props.loading) {
    return <div className={classes.container}><CpLoading loading={props.loading} /></div>;
  }

  return <>{props.children}</>;
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    flexGrow: 1,
    padding: '1em',
    textAlign: 'center',
    color: 'red',
  },
})));

export default CpContentContainer;
