import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {ConsentStep} from "../../../../api/Types";

interface CompletedSummaryProps {
  step: ConsentStep
}

const CompletedSummary: FunctionComponent<CompletedSummaryProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.descriptionContainer}>
      <Typography paragraph variant="h5"><strong>{props.step.name}</strong></Typography>
      <Typography variant="body1">Congratulations on completing your consent journey!</Typography>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionContainer: {
      width: '100%',
      textAlign: 'center'
    },
  }),
);

export default CompletedSummary;
