import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import CpButton from "../UI/CpButton";
import {MergeType} from "@material-ui/icons";
import CpForm from "../UI/CpForm";
import React, {FunctionComponent} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ValidationErrorItem} from "joi";
import {useDispatch} from "react-redux";
import CpContentContainer from "../UI/CpContentContainer";
import {linkConsentProcessSchema} from "../../schemas/surgeonSchemas";
import {getSurgeryByUniqueCode} from "../../store/surgeon/surgeonSurgerySlice";
import CpTextField from "../UI/CpTextField";
import {Surgery} from "../../api/Types";

interface GetSurgeryByUniqueCodeFormProps {
  onComplete: (surgery: Surgery) => void,
}

const GetSurgeryByUniqueCodeForm: FunctionComponent<GetSurgeryByUniqueCodeFormProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uniqueCode, setUniqueCode] = React.useState<string|null>(null);
  const [joiErrors, setJoiErrors] = React.useState<ValidationErrorItem[]|null>(null);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string|null>(null);

  const submitHandler = async () => {
    setSubmitLoading(true)
    setSubmitError(null)
    setJoiErrors(null)
    try {
      await linkConsentProcessSchema.validateAsync(
        { unique_code: uniqueCode },
        { abortEarly: false }
      );

      if (uniqueCode === null) {
        return;
      }

      const response = await dispatch(getSurgeryByUniqueCode(uniqueCode));
      setSubmitLoading(false);
      if (response.status === 'success') {
        props.onComplete(response.data.surgery);
        return;
      }
    } catch (e: any) {
      if (e.status === undefined) {
        setJoiErrors(e.details);
        setSubmitLoading(false)
        return;
      }
      setSubmitLoading(false)
      setSubmitError(e.data.data.errors[0])
    }
  }

  const uniqueCodeChangeHandler = (uniqueCode: any) => {
    setUniqueCode(uniqueCode.toUpperCase());
  }

  return <div className={classes.codeForm}>
    <CpForm onSubmit={submitHandler} name="Please Type Your Unique Code Below">
      <CpTextField
        id="link-unique-code"
        disabled={submitLoading}
        className={classes.field}
        inputProps={{className: classes.inputCodeText}}
        type="text"
        label="Unique Code"
        variant="outlined"
        onChange={(e) => uniqueCodeChangeHandler(e.target.value)}
        error={getJoiErrorMessage('unique_code', joiErrors) !== null}
        helperText={getJoiErrorMessage('unique_code', joiErrors)}
      />
      <CpButton
        disabled={submitLoading}
        type="submit"
        className={classes.field}
        variant="contained"
        color="primary"
        size="large"
        startIcon={<MergeType />}
        data-testid="login-button"
      >
        Link
      </CpButton>
      <CpContentContainer error={submitError} loading={submitLoading} />
    </CpForm>
  </div>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  codeForm: {
    margin: '2em 10em',
    [theme.breakpoints.down('sm')]: {
      margin: '2em 0',
    }
  },
  inputCodeText: {
    fontFamily: 'open sans',
    textTransform: 'uppercase',
    letterSpacing: '10px',
  },
  field: {
    width: '75%',
    minWidth: '200px',
    margin: '1em auto',
  },
})));

export default GetSurgeryByUniqueCodeForm;
