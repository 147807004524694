import React, {FunctionComponent, useEffect} from 'react';
import {Divider, Grid, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {getSurgeries} from "../../store/patient/patientSurgerySlice";
import {authClient} from "../../api/Container";
import CpContentContainer from "../../components/UI/CpContentContainer";
import PatientActiveSurgeries from "../../components/Patient/PatientActiveSurgeries";
import PatientHomepageActions from "../../components/Patient/PatientHomepageActions";

const PatientHome: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const patient = useSelector((state: RootState) => state.patient.auth.account.data);
  const error = useSelector((state: RootState) => state.patient.surgery.surgeries.error);
  const loading = useSelector((state: RootState) => state.patient.surgery.surgeries.loading);

  useEffect(() => {
    const patientId = authClient.getPatientId();
    if (patientId !== null) {
      dispatch(getSurgeries(patientId));
      return;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <Typography className={classes.headerTitle} variant="h5">
        Welcome {`${patient ? patient.patient_first_name : ''}`}
      </Typography>
      <Divider />
        <Grid container>
          <Grid item xs={12} md={5}>
            <PatientHomepageActions />
          </Grid>
          <Grid item xs={12} md={7}>
            <CpContentContainer error={error} loading={loading}>
              <PatientActiveSurgeries />
            </CpContentContainer>
          </Grid>
        </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    padding: '0 1.5em',
    marginBottom: '5em',
  },
  headerTitle: {
    marginLeft: '1em',
    padding: '0.5em 0',
  },
})));

export default PatientHome;
