import React, {FunctionComponent} from 'react';
import CpAppBar from "../../components/UI/CpAppBar";
import {Tabs, Tab, Hidden} from "@material-ui/core";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import CpError from "../UI/CpError";
import logo from "../../resources/logo.png";
import Typography from "@material-ui/core/Typography";
import {authClient} from "../../api/Container";
import Footer from "../UI/Footer";

interface HomeContainerProps {}

const PatientHomeContainer: FunctionComponent<HomeContainerProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const auth = authClient.getToken();
  const roles = authClient.getAuthRoles();
  const error = useSelector((state: RootState) => state.public.auth.error);

  const tabSelectedHandler = (event: React.ChangeEvent<{}>, value: any) => {
    history.push(value);
  }

  let authTabs = [
    <Tab className={classes.tab} key="login" label="Login" value="/patient/login" />,
    <Tab className={classes.tab} key="register" label="Register" value="/patient/register" />,
  ];

  if (auth !== null && roles !== null) {
    authTabs = [];
    if (roles.includes('ADMIN')) {
      authTabs.push(<Tab key="admin/dashboard" label="Admin" value="/admin" />)
    }

    if (roles.includes('PATIENT')) {
      authTabs.push(<Tab key="patient/dashboard" label="Patient" value="/patient" />)
    }
  }

  const displayError = error !== null ? <CpError error={error} /> : null

  return (
    <>
      <CpAppBar>
        <img src={logo} className={classes.logo} onClick={(e) => tabSelectedHandler(e, '/home')} alt="Consent Plus Logo" />
        <Hidden xsDown>
          <Typography className={classes.title} variant="h6"> Patient Portal </Typography>
        </Hidden>
        <Tabs className={classes.tabRight} value={false} textColor="primary" onChange={tabSelectedHandler}>
          {authTabs}
        </Tabs>
      </CpAppBar>
      <main className={classes.content}>
        {displayError}
        {props.children}
      </main>
      <Footer />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  snackbar: {
    paddingTop: '4.5em',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '3.4em',
    },
  },
  alert: {
    padding: '0.5em 1.5em',
  },
  logo: {
    marginLeft: '1em',
    maxHeight: '45px',
    cursor: 'pointer',
  },
  title: {
    cursor: 'default',
    marginLeft: '1em',
  },
  tab: {
    marginRight: '2em',
    fontSize: '1.1em',
    '&:hover': {
      opacity: 0.7,
    },
  },
  tabLeft: {
    marginLeft: '2em',
  },
  tabRight: {
    marginLeft: 'auto',
  },
  content: {
    flexGrow: 1,
    marginTop: '64px',
    padding: theme.spacing(3),
    minHeight: `calc(100vh - 128px)`,
  },
})));

export default PatientHomeContainer;
