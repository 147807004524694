import React, {FunctionComponent} from 'react';
import CpButton from "../../UI/CpButton";
import {ConsentStep} from "../../../api/Types";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

type SubmitStepProps = {
  onSubmit: () => void,
  disabled?: boolean,
  step: ConsentStep
  surgery: string
  className?: string
}

const SubmitStep: FunctionComponent<SubmitStepProps> = props => {
  const classes = useStyles();

  return <CpButton
    className={props.className}
    disabled={props.disabled ?? true}
    variant="contained"
    color="primary"
    classes={{containedPrimary: classes.containedPrimary}}
    size="large"
    startIcon={props.step.getIcon()}
    onClick={() => props.onSubmit()}
  >
    {props.step.completed(props.surgery) ? 'Next Section' : 'Submit'}
  </CpButton>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containedPrimary: {
      backgroundColor: '#009A49',
      '&:hover': {
        backgroundColor: '#007b3a',
      }
    }
  }),
);

export default SubmitStep;
