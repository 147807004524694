import React, {PureComponent} from 'react';
import CpTextField from "../../components/UI/CpTextField";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import {ExitToApp} from "@material-ui/icons";
import CpForm, {CpFormState} from "../UI/CpForm";
import {patientRegisterSchema} from "../../schemas/publicSchemas";
import CpButton from "../UI/CpButton";
import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {login, registerPatient} from "../../store/public/publicAuthSlice";
import {FormHelperText, Select, Typography} from "@material-ui/core";
import CpSelectContainer from "../UI/CpSelectContainer";
import {formatMobileNumber} from "../../app/helpers";
import CpContentContainer from "../UI/CpContentContainer";
import {Alert} from "@material-ui/lab";
import {Patient} from "../../api/Types";

interface CreatePatientFormProps extends WithStyles, RouteComponentProps {
  registerPatient: typeof registerPatient;
  login: typeof login;
  loading: boolean;
  onCreate: (patient: Patient) => void
}

interface CreatePatientFormState extends CpFormState {
  submitLoading: boolean,
  submitError: null|string,
  success: boolean,
}

class CreatePatientForm extends PureComponent<CreatePatientFormProps, CreatePatientFormState> {
  state: CreatePatientFormState = {
    fields: {
      title: null,
      other_title: null,
      first_name: null,
      last_name: null,
      mobile_number: null,
      email: null,
    },
    errors: null,
    submitLoading: false,
    submitError: null,
    success: false,
  }

  submitHandler = async (e: React.FormEvent) => {
    this.setState({errors: [], submitLoading: true, submitError: null});
    const fields = {...this.state.fields};
    fields.title = fields.title !== 'Other' ? fields.title : fields.other_title;
    delete fields.other_title;

    fields.mobile_number = formatMobileNumber(fields.mobile_number);

    try {
      await patientRegisterSchema.validateAsync(
        fields,
        { abortEarly: false }
      );

      const patient = {
        patient_title: fields.title ?? '',
        patient_first_name: fields.first_name ?? '',
        patient_last_name: fields.last_name ?? '',
        patient_mobile_number: fields.mobile_number,
        patient_email: fields.email ?? '',
        patient_gender: null,
        patient_laterality: null,
        patient_date_of_birth: null,
      }

      const response = await this.props.registerPatient(patient);

      response.data.patient_id
        ? this.setState({success: true, submitLoading: false})
        : this.setState({submitError: 'There was an issue creating this account', submitLoading: false});

      this.props.onCreate({...patient, ...response.data});
    } catch (e: any) {
      if (e.status === undefined) {
        this.setState({errors: e.details});
      }
      this.setState({submitError: e.data.data.errors[0], submitLoading: false});
    }
  }

  inputChangeHandler = (inputName: string, value: any) => {
    const fields = {...this.state.fields};
    fields[inputName] = value;

    this.setState({fields: fields});
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.success ? <Alert severity="success">
            You have successfully created your Patient's account, please advise they need to view their email to set a password.
          </Alert>
        : <Typography variant="body1" paragraph>Please advise that on creation your patient will receive a reset password email so they are able to complete their account registration</Typography>
        }
      <CpForm className={classes.root} onSubmit={this.submitHandler} name="Create Patient Account">
        <CpSelectContainer className={classes.field} label="Title (optional)" id="register-title-select">
          <Select
            native
            value={this.state.fields.title ?? ''}
            label="Title (optional)"
            onChange={(e) => this.inputChangeHandler('title', e.target.value)}
            error={getJoiErrorMessage('title', this.state.errors) !== null}
            inputProps={{name: 'title', id: 'register-title-select'}}
          >
            <option aria-label="Please Select" value="" />
            <option aria-label="Prof" value="Prof">Prof</option>
            <option aria-label="Dr" value="Dr">Dr</option>
            <option aria-label="Mr" value="Mr">Mr</option>
            <option aria-label="Mr" value="Miss">Miss</option>
            <option aria-label="Mr" value="Mrs">Mrs</option>
            <option aria-label="Mr" value="Ms">Ms</option>
            <option aria-label="Mr" value="Ms">Mx</option>
            <option aria-label="Other" value="Other">Other</option>
          </Select>
          <FormHelperText>{getJoiErrorMessage('title', this.state.errors)}</FormHelperText>
        </CpSelectContainer>
        {this.state.fields.title === 'Other'
          ? <CpTextField
            className={classes.field}
            type="text"
            label="Other Title"
            variant="outlined"
            onChange={(e) => this.inputChangeHandler('other_title', e.target.value)}
            error={getJoiErrorMessage('title', this.state.errors) !== null}
            helperText={getJoiErrorMessage('title', this.state.errors)}
          />
          : null
        }
        <CpTextField
          id='register-first-name'
          className={classes.field}
          type="text"
          label="First Name"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('first_name', e.target.value)}
          error={getJoiErrorMessage('first_name', this.state.errors) !== null}
          helperText={getJoiErrorMessage('first_name', this.state.errors)}
        />
        <CpTextField
          id='register-last-name'
          className={classes.field}
          type="text"
          label="Last Name"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('last_name', e.target.value)}
          error={getJoiErrorMessage('last_name', this.state.errors) !== null}
          helperText={getJoiErrorMessage('last_name', this.state.errors)}
        />
        <CpTextField
          id='register-email'
          className={classes.field}
          type="email"
          label="Email"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('email', e.target.value)}
          error={getJoiErrorMessage('email', this.state.errors) !== null}
          helperText={getJoiErrorMessage('email', this.state.errors)}
        />
        <CpTextField
          id='register-mobile-number'
          className={classes.field}
          type="text"
          label="Mobile Number (optional)"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('mobile_number', e.target.value)}
          error={getJoiErrorMessage('mobile_number', this.state.errors) !== null}
          helperText={getJoiErrorMessage('mobile_number', this.state.errors)}
        />
        <CpButton
          type="submit"
          disabled={this.state.success || this.state.submitLoading}
          className={classes.field}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<ExitToApp />}
          data-testid="register-button"
        >
          Create
        </CpButton>
        <CpContentContainer error={this.state.submitError} loading={this.state.submitLoading} />
      </CpForm>
      </>
    )
  }
}

const styles = (theme: Theme) => createStyles({
  root: {
    margin: '0 2em',
  },
  field: {
    margin: '1em 1.5em',
  },
  label: {
    color: theme.palette.text.primary,
    opacity: 0.8,
  },
});

const mapStateToProps = (state: any) => {
  return {
    loading: state.public.auth.loading,
  };
};

const actionCreators = { registerPatient, login };

export default connect( mapStateToProps, actionCreators )(withStyles(styles)(withRouter(CreatePatientForm)));
