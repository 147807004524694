import React, {FunctionComponent, useEffect} from 'react';
import {findAssessmentByType} from "../../../../app/helpers";
import {AssessmentType, ConsentStep} from "../../../../api/Types";
import CpContentContainer from "../../../UI/CpContentContainer";
import {getFullAssessment} from "../../../../store/patient/patientAssessmentSlice";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {RootState} from "../../../../store/store";
import AssessmentQuestionAnswerContainer from "../../Assessment/AssessmentQuestionAnswerContainer";
import SubmitStep from "../SubmitStep";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {navigateToLatestStep, nextConsentCompletedStep} from "../../../../store/patient/patientConsentSlice";
import ConsentProcessSaveButton from "../ConsentProcessSaveButton";
import {Typography} from "@material-ui/core";

interface InitialAssessmentProcessProps {
  step: ConsentStep
}

const InitialAssessmentProcess: FunctionComponent<InitialAssessmentProcessProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const assessments = useSelector((state: RootState) => state.patient.assessment.full.data);
  const error = useSelector((state: RootState) => state.patient.assessment.full.error);
  const loading = useSelector((state: RootState) => state.patient.assessment.full.loading);
  const assessment = findAssessmentByType(params.id, AssessmentType.INITIAL_ASSESSMENT);
  const [saveDisable, setSaveDisable] = React.useState(false);

  useEffect(() => {
    if (assessments === null || assessments.surgery_id !== params.id) {
      dispatch(getFullAssessment(params.id));
    }

    return () => {
      dispatch(props.step.save(params.id))
    }
    // eslint-disable-next-line
  }, [params.id]);

  if (assessment === null) {
    return null;
  }

  const onSubmit = async () => {
    if (props.step.completed(params.id)) {
      await dispatch(nextConsentCompletedStep(params.id));
      return;
    }

    try {
      await dispatch(props.step.save(params.id));
      await dispatch(navigateToLatestStep(params.id));
      setSaveDisable(true);
      setTimeout(() => {
        if (!saveDisable) {
          setSaveDisable(false)
        }
      }, (30 * 1000)) // 30 seconds
    } catch (e: any) {
      // do nothing, redux should handle error
    }
  }

  return (
    <>
      <div className={classes.submitContainer}>
        <CpContentContainer error={error} loading={loading}>
          <div className={classes.buttonContainer}>
            <ConsentProcessSaveButton className={classes.button} onSave={onSubmit} disabled={saveDisable} />
            <SubmitStep surgery={params.id} className={classes.button} step={props.step} onSubmit={onSubmit} disabled={props.step.completionPercentage(params.id) !== 100} />
          </div>
          {props.step.completed(params.id) ? <Typography className={classes.completedMessage} paragraph variant="body1">This section is complete, you will be unable to change your answers</Typography> : null}
        </CpContentContainer>
      </div>
      <AssessmentQuestionAnswerContainer completed={props.step.completed(params.id)} surgeryId={params.id} assessment={assessment} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  submitContainer: {
    marginTop: '2em',
  },
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '2em 1.5em',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
      margin: '1em 0',
    },
  },
  button: {
    padding: '0.5em 5em',
    margin: '0 3em',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0.1em',
      padding: '0.5em 2em',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0.5em 1em',
    },
  },
  completedMessage: {
    color: 'red',
    margin: 'auto',
    textAlign: 'center',
  },
}));

export default InitialAssessmentProcess;
