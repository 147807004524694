import React from "react";
import { Route } from "react-router-dom";
import PatientHome from "../pages/Patient/PatientHome";
import PatientDashboard from "../components/Patient/PatientDashboard";
import PatientConsentContainer from "../pages/Patient/PatientConsentContainer";
import PatientConsentProcessPage from "../pages/Patient/PatientConsentProcessPage";
import PatientConsentSummaryPage from "../pages/Patient/PatientConsentSummaryPage";
import PatientSurgeryNotesPage from "../pages/Patient/PatientSurgeryNotesPage";
import PatientInformationPage from "../pages/Patient/PatientInformationPage";
import PatientCreateConsentProcessPage from "../pages/Patient/PatientCreateConsentProcessPage";

const PatientRoutes = () => {
  const container = (component: JSX.Element) => {
    return <PatientDashboard>{component}</PatientDashboard>
  }

  return [
    <Route key="/patient/" path="/patient/" exact component={() => container(<PatientHome />)} />,
    <Route key="patient/home" path="/patient/home" exact component={() => container(<PatientHome />)} />,
    <Route key="patient/dashboard" path="/patient/dashboard" exact component={() => container(<PatientHome />)} />,
    <Route key="patient/information" path="/patient/information" exact component={() => container(<PatientInformationPage />)} />,

    <Route key="patient/notes/" path="/patient/notes" exact component={() => container(<PatientSurgeryNotesPage />)} />,
    <Route key="patient/notes/:id" path="/patient/notes/:id" exact component={() => container(<PatientSurgeryNotesPage />)} />,

    <Route key="patient/consent-process/create" path="/patient/consent-process/create" exact component={() => container(<PatientCreateConsentProcessPage />)} />,
    <Route key="patient/consent-process/:id" path="/patient/consent-process/:id" exact component={() => container(<PatientConsentContainer><PatientConsentProcessPage /></PatientConsentContainer>)} />,

    <Route key="patient/surgery/:id" path="/patient/surgery/:id" exact component={() => container(<PatientConsentContainer><PatientConsentSummaryPage /></PatientConsentContainer>)} />,
  ];
}

export default PatientRoutes;
