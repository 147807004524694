import React, {FunctionComponent} from 'react';
import {
  Card, Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import {Visibility} from "@material-ui/icons";
import {Patient} from "../../api/Types";
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpSearch from "../UI/CpSearch";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";

interface PatientTableListProps extends LoadingErrorProps {
  patients: Patient[],
  className?: string,
  onSearch?: (searchText: string) => void,
  onSelect?: (patientId: string) => void
}

const PatientTableList: FunctionComponent<PatientTableListProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const viewPatient = (patientId: string) => {
    if (props.onSelect === undefined) {
      history.push(`/surgeon/patients/${patientId}`)
      return;
    }

    props.onSelect(patientId);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSearch = (searchText: string) => {
    if (page !== 0) {
      setPage(0);
    }

    if (props.onSearch !== undefined) {
      props.onSearch(searchText);
    }
  };

  return (
    <Card className={props.className}>
      <Grid container>
        <Grid item xs={12} md={7} lg={5} className={classes.section}>
          <Typography variant="h5">Latest Patients</Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={7} className={classes.section}>
          {props.onSearch !== undefined ? <CpSearch onSearch={onSearch} /> : null }
        </Grid>
      </Grid>
      <CpContentContainer error={props.error} loading={props.loading}>
        <TableContainer className={classes.tableSection} component={Paper}>
          <Table aria-label="Latest patients table" size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Actions</strong></TableCell>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Registered</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.patients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(patient => (
                <TableRow key={patient.patient_id}>
                  <TableCell>
                    <Tooltip title="View Patient">
                      <IconButton onClick={() => viewPatient(patient.patient_id ?? '0')} aria-label="view">
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {patient.patient_first_name} {patient.patient_last_name}
                  </TableCell>
                  <TableCell>{patient.patient_created_at?.substring(0,10)}</TableCell>
                </TableRow>
              )) ?? []}
            </TableBody>
          </Table>
        </TableContainer>
      </CpContentContainer>
      {props.patients.length > rowsPerPage
        ? <TablePagination
          component="div"
          count={props.patients.length}
          rowsPerPageOptions={[10]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
        : null
      }

    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  section: {
    padding: '1em',
  },
  tableSection: {
    marginTop: '0.5em'
  }
})));


export default PatientTableList;
