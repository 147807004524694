import React, {FunctionComponent} from 'react';
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import {LocalLibrary, School} from "@material-ui/icons";

const SurgeonHelpActions: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const history = useHistory();

  const changePageHandler = (route: string) => {
    history.push(route);
  }

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Typography paragraph variant="h6" align="center"><strong>Information about Consent Plus and your Surgeon Portal</strong></Typography>
      </Grid>
      <Grid item xs={12}>
        {getSurgeonActions().map((action) => {
          return <div key={action.text} className={classes.actionContainer} style={{backgroundColor: action.color}} onClick={() => changePageHandler(action.route)}>
            {action.icon}
            <Typography className={classes.actionText} variant="h6">{action.text}</Typography>
          </div>
        })}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1.5em',
      [theme.breakpoints.down('xs')]: {
        padding: '1em 0',
      },
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      padding: '1.5em 2.5em',
      marginTop: '2em',
      color: '#fff',
      '&:hover': {
        transform: 'scale(1.04)',
        cursor: 'pointer',
      },
    },
    actionText: {
      marginLeft: '1em',
    }
  }),
);

type homeAction = {
  text: string,
  icon: JSX.Element
  route: string,
  color: string,
}

const getSurgeonActions = (): homeAction[] => [
  {
    text: 'Learn about the Consent Process',
    icon: <School fontSize="large" />,
    route: '/surgeon/help/information',
    color: '#23bbbb',
  },
  {
    text: "Understand the content your Patient's will see",
    icon: <LocalLibrary fontSize="large" />,
    route: '/surgeon/help/consent-process',
    color: '#26aac7',
  },
  // {
  //   text: 'FAQs',
  //   icon: <LibraryBooks fontSize="large" />,
  //   route: '/surgeon/help/faq/',
  //   color: '#356891',
  // },
];

export default SurgeonHelpActions;
