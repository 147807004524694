import {createSlice} from "@reduxjs/toolkit";
import {assessmentClient} from "../../api/Container";
import {QuestionCategory} from "../../api/Types";
import {Error, Loading} from "../store";

const initialState: surgeonAssessmentState = {
  surgeryQuestionAnswers: {
    error: null,
    loading: false,
    data: null,
  },
}

export const surgeonAssessmentSlice = createSlice({
  name: 'surgeonAssessment',
  initialState,
  reducers: {
    setQuestionAnswers: (state, action) => {
      state.surgeryQuestionAnswers.data = action.payload.assessment;
      state.surgeryQuestionAnswers.error = null;
      state.surgeryQuestionAnswers.loading = false;
    },
    clearQuestionAnswers: (state) => {
      state.surgeryQuestionAnswers = initialState.surgeryQuestionAnswers;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default surgeonAssessmentSlice.reducer;

// @ts-ignore as this is a middleware
export const getSurgeryQuestionAnswers = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(surgeonAssessmentSlice.actions.setStart({index: 'surgeryQuestionAnswers'}))

  try {
    const response = await assessmentClient.getFullSurgeryAssessment(surgeryId);
    dispatch(surgeonAssessmentSlice.actions.setQuestionAnswers(response.data));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonAssessmentSlice.actions.setEnd({index: 'surgeryQuestionAnswers', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const clearSurgeryQuestionAnswers = (): Promise<any> => async dispatch => {
  dispatch(surgeonAssessmentSlice.actions.clearQuestionAnswers());
  return Promise.resolve();
}

type surgeonAssessmentState = {
  surgeryQuestionAnswers: {
    error: Error
    loading: Loading
    data: {
      surgeon_id: string
      categories: QuestionCategory[]
    }|null
  }
}
