import Joi from "joi";

// Minimum eight characters, at least one letter and one number
export const passwordRegex = '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@£#}\\[{\\]€$%^&*\\|()_;\\-:"\\\'\\\\]{8,}$';
// Must contain 7 digits
const gmcNumberRegex = '^\\d{7}$';
// UK Phone: Must start +447 and be digits only up to a maximum of 14
export const mobileNumberRegex = '^(\\+447\\d{7,11})$';

export const surgeonRegisterSchema = Joi.object({
  title: Joi.string()
    .label('Title')
    .allow(null, ''), // makes it optional
  first_name: Joi.string()
    .label('First Name')
    .required(),
  last_name: Joi.string()
    .label('Last Name')
    .required(),
  gmc_number: Joi.string()
    .label('GMC Number')
    .pattern(new RegExp(gmcNumberRegex))
    .required(),
  email: Joi.string()
    .label('Email Address')
    .email({ tlds: false })
    .required(),
  mobile_number: Joi.string()
    .label('Mobile Number')
    .pattern(new RegExp(mobileNumberRegex))
    .allow(null, ''), // makes it optional
  password: Joi.string()
    .label('Password')
    .pattern(new RegExp(passwordRegex)),
  confirmPassword: Joi.any().valid(Joi.ref('password')),
});

export const patientRegisterSchema = Joi.object({
  title: Joi.string()
    .label('Title')
    .allow(null, ''), // makes it optional
  first_name: Joi.string()
    .label('First Name')
    .required(),
  last_name: Joi.string()
    .label('Last Name')
    .required(),
  email: Joi.string()
    .label('Email Address')
    .email({ tlds: false })
    .required(),
  mobile_number: Joi.string()
    .label('Mobile Number')
    .pattern(new RegExp(mobileNumberRegex))
    .allow(null, ''), // makes it optional
  password: Joi.string()
    .label('Password')
    .pattern(new RegExp(passwordRegex)),
  confirmPassword: Joi.any().valid(Joi.ref('password')),
});

export const loginSchema = Joi.object({
  email: Joi.string()
    .label('Email Address')
    .email({ tlds: false })
    .required(),
  password: Joi.string(), // no need to check regex pattern
});

export const passwordResetRequestSchema = Joi.object({
  email: Joi.string()
    .label('Email Address')
    .email({ tlds: false })
    .required(),
});

export const passwordResetSchema = Joi.object({
  password: Joi.string()
    .label('Password')
    .pattern(new RegExp(passwordRegex)),
  confirmPassword: Joi.any().valid(Joi.ref('password')),
});
