import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpBreadcrumbs from "../../components/UI/CpBreadcrumbs";
import {Typography} from "@material-ui/core";
import {useParams} from "react-router-dom";
import CpAddNote from "../../components/UI/CpAddNote";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {authClient} from "../../api/Container";
import {linkPatientSurgeries} from "../../app/helpers";
import CpContentContainer from "../../components/UI/CpContentContainer";
import CpDisplayNotes from "../../components/UI/CpDisplayNotes";
import {getSurgeries} from "../../store/surgeon/surgeonSurgerySlice";
import {getListOfSurgeonPatients} from "../../store/surgeon/surgeonAuthSlice";
import {addNote, getSurgeryNotes} from "../../store/surgeon/surgeonNoteSlice";
import CpNoteSurgeonListItem from "../../components/UI/CpNoteSurgeonListItem";

const SurgeonSurgeryNotesPage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const surgeries = useSelector((state: RootState) => state.surgeon.surgery.surgeries.data);
  const patients = useSelector((state: RootState) => state.surgeon.auth.patients.data);
  const notes = useSelector((state: RootState) => state.surgeon.note.current.data);
  const error = useSelector((state: RootState) => state.surgeon.note.current.error);
  const loading = useSelector((state: RootState) => state.surgeon.note.current.loading);

  useEffect(() => {
    const surgeonId = authClient.getSurgeonId();
    if (surgeries === null && surgeonId !== null) {
      dispatch(getSurgeries());
    }

    if (patients === null) {
      dispatch(getListOfSurgeonPatients());
    }

    if (paramsExist()) {
      dispatch(getSurgeryNotes(params.id));
    }
    // eslint-disable-next-line
  }, [params.id]);

  const breadcrumbs = [
    {to: '/surgeon/dashboard', name: 'Home', click: true},
    {to: `/surgeon/notes/${params.id}`, name: 'Notes', click: false},
  ];

  const paramsExist = () => {
    return params.id !== undefined && params.id !== null && params.id !== '';
  }

  const noteAddHandler = async (data: string) => {
    if (paramsExist()) {
      await dispatch(addNote(params.id, data));
    }
  }

  const surgeryNotes = linkPatientSurgeries(patients ?? [], surgeries ?? []);
  const emptyText = 'Your patient has not created any notes for this surgery yet'

  return (
    <>
      <div className={classes.leftContainer}>
        <CpBreadcrumbs className={classes.breadcrumbs} breadcrumbs={breadcrumbs} />
        <div className={classes.messageListContainer}>
          {surgeryNotes
            ? surgeryNotes.map((surgery) => <CpNoteSurgeonListItem
              active={params.id === surgery.surgery.surgery_id}
              key={surgery.surgery.surgery_id}
              surgery={surgery}
            />)
            : <Typography>You currently have no surgeries to view notes for</Typography>
          }
        </div>
      </div>
      <div className={classes.messagesContainer}>
        <CpContentContainer loading={loading} error={error}>
          {paramsExist()
            ? <CpDisplayNotes emptyDisplayText={emptyText} notes={notes} />
            : <Typography variant="h5" className={classes.emptyNoteTitle}>Please select a surgery from the list</Typography>
          }
        </CpContentContainer>
      </div>
      <div className={classes.editorContainer}>
        <CpAddNote id="patient-add-note" onSubmit={noteAddHandler} />
      </div>
    </>
  );
}

const listWidth = '220px';
const editorHeight = '201px';
const useStyles = makeStyles((theme: Theme) => (createStyles({
  surgeryContainer: {
    position: 'absolute',
    marginTop: '64px',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  leftContainer: {
    position: 'fixed',
    marginTop: '64px',
    top: 0,
    left: 0,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    height: '100%',
    width: listWidth,
    backgroundColor: '#fff',
  },
  breadcrumbs: {
    padding: '1em',
    backgroundColor: theme.palette.background.default,
  },
  messageListContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    overflow: 'hidden',
  },
  messagesContainer: {
    position: 'fixed',
    marginTop: '64px',
    paddingBottom: '70px', // 222px - 180px (plus a random 20)
    top: 0,
    right: 0,
    width: `calc(100% - ${listWidth})`,
    maxWidth: `calc(100% - ${listWidth})`,
    height: `calc(100% - ${editorHeight})`,
    maxHeight: `calc(100% - ${editorHeight})`,
    overflow: 'scroll',
  },
  editorContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    marginLeft: listWidth,
    width: `calc(100% - ${listWidth})`,
    maxWidth: `calc(100% - ${listWidth})`,
    backgroundColor: '#fff',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    minHeight: editorHeight,
    height: editorHeight,
  },
  emptyNoteTitle: {
    width: '100%',
    height: '100%',
    textAlign: 'justify',
    padding: '0 3em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@global': { // set the height of the Editor. Unfortunately this must be done globally.
    '.ck-editor__editable': {
      height: '160px',
      maxHeight: '160px',
      overflow: 'scroll',
    },
  },
})));

export default SurgeonSurgeryNotesPage;
