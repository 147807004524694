import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ConsentStep, SyllabusType} from "../../../../api/Types";
import {getFullResource} from "../../../../store/patient/patientResourceSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import CpContentContainer from "../../../UI/CpContentContainer";
import {useParams} from "react-router-dom";
import SubmitStep from "../SubmitStep";
import {nextConsentCompletedStep} from "../../../../store/patient/patientConsentSlice";
import {findResourceByType} from "../../../../app/helpers";
import ResourceEngagementContainer from "../../Resource/ResourceEngagementContainer";
import ConsentProcessSaveButton from "../ConsentProcessSaveButton";

interface LearningResourceProcessProps {
  step: ConsentStep
}

const LearningResourceProcess: FunctionComponent<LearningResourceProcessProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const resources = useSelector((state: RootState) => state.patient.resource.full.data);
  const error = useSelector((state: RootState) => state.patient.resource.full.error);
  const loading = useSelector((state: RootState) => state.patient.resource.full.loading);
  const syllabus = findResourceByType(params.id, SyllabusType.INITIAL_LEARNING);
  const [saveDisable, setSaveDisable] = React.useState(false);

  useEffect(() => {
    if (resources === null || resources.surgery_id !== params.id) {
      dispatch(getFullResource(params.id));
    }

    return () => {
      dispatch(props.step.save(params.id))
    }
    // eslint-disable-next-line
  }, [params.id]);

  const onSubmit = async (nextStep?: boolean) => {
    try {
      await dispatch(props.step.save(params.id));
      setSaveDisable(true);
      setTimeout(() => {
        if (!saveDisable) {
          setSaveDisable(false)
        }
      }, (30 * 1000)) // 30 seconds
    } catch (e: any) {
      // do nothing, redux should handle error
    }

    if (nextStep === true) {
      await dispatch(nextConsentCompletedStep(params.id));
    }
  }

  if (syllabus === null) {
    return null;
  }

  return (
    <>
      <CpContentContainer error={error} loading={loading}>
        <div className={classes.buttonContainer}>
          <ConsentProcessSaveButton className={classes.button} onSave={() => onSubmit(false)} disabled={saveDisable} />
          <SubmitStep surgery={params.id} className={classes.button} step={props.step} onSubmit={() => onSubmit(true)} disabled={props.step.completionPercentage(params.id) !== 100} />
        </div>
      </CpContentContainer>
      <ResourceEngagementContainer surgeryId={params.id} onVideoComplete={(id: string) => onSubmit(false)} syllabus={syllabus} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      width: '100%',
      margin: '2em 1.5em',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-around',
        margin: '1em 0',
      },
    },
    button: {
      padding: '0.5em 5em',
      margin: '0 3em',
      [theme.breakpoints.down('sm')]: {
        margin: '0 0.1em',
        padding: '0.5em 2em',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0.5em 1em',
      },
    }
  }),
);

export default LearningResourceProcess;
