import React, {FunctionComponent} from 'react';
import {createStyles, fade, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {PatientSurgery} from "../../api/Types";
import {useHistory} from "react-router-dom";

interface CpNoteSurgeonListItemProps {
  surgery: PatientSurgery
  active?: boolean
}

const CpNoteSurgeonListItem: FunctionComponent<CpNoteSurgeonListItemProps> = props => {
  const classes = useStyles();
  const history = useHistory();

  const onChangeNoteHandler = () => {
    history.push(`/surgeon/notes/${props.surgery.surgery.surgery_id}`);
  }

  const onSurgeryLink = () => {
    history.push(`/surgeon/surgeries/${props.surgery.surgery.surgery_id}`);
  }

  const title = props.surgery.patient?.patient_title ?? '';
  const patientName = `${title} ${props.surgery.patient.patient_first_name} ${props.surgery.patient.patient_last_name}`;

  const activeClass = props.active ?? false ? classes.active : '';

  return <div key={props.surgery.surgery.surgery_id} className={[classes.surgeryNoteItem, activeClass].join(' ')}>
    <div onClick={onChangeNoteHandler} className={classes.noteLink}>
      <Typography variant="body1" paragraph><strong>{patientName}</strong></Typography>
      <Typography variant="body2">{props.surgery.surgery.operation_name}</Typography>
      <Typography noWrap variant="body2">{props.surgery.surgery.hospital_name}</Typography>
    </div>
    <Typography onClick={onSurgeryLink} className={classes.surgeryLink} noWrap variant="body2">View Surgery Details</Typography>
  </div>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    surgeryNoteItem: {
      width: '100%',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    noteLink: {
      padding: '1em',
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.2),
        cursor: 'pointer',
      },
    },
    surgeryLink: {
      padding: '1em',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    },
    active: {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    }
  }),
);

export default CpNoteSurgeonListItem;
