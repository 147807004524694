import React, {FunctionComponent, useEffect} from 'react';
import {Avatar, Divider, Grid, Tooltip, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {getSurgeries} from "../../store/surgeon/surgeonSurgerySlice";
import {getListOfSurgeonPatients} from "../../store/surgeon/surgeonAuthSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import PatientTableList from "../../components/Surgeon/PatientTableList";
import SurgeriesTableList from "../../components/Surgeon/SurgeriesTableList";
import {linkPatientSurgeries} from "../../app/helpers";
import {Chat, CreateNewFolder, MergeType} from "@material-ui/icons";
import {useHistory} from "react-router-dom";

const SurgeonHome: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const patients = useSelector((state: RootState) => state.surgeon.auth.patients);
  const surgeries = useSelector((state: RootState) => state.surgeon.surgery.surgeries);

  useEffect(() => {
    if (patients.data === null && patients.error === null) {
      dispatch(getListOfSurgeonPatients())
    }

    if (surgeries.data === null && surgeries.error === null) {
      dispatch(getSurgeries())
    }
    // eslint-disable-next-line
  }, []);

  const navigateHandler = (route: string) => {
    history.push(route);
  }

  const getPatientSurgeries = () => {
    if (patients.data === null || surgeries.data === null) {
      return [];
    }

    return linkPatientSurgeries(patients.data, surgeries.data.slice(0, 5));
  }

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Welcome to your Surgeon Dashboard</Typography>
        <Divider />
      </div>
      <Grid container spacing={3}>
        <Grid container className={classes.quickButtonContainer}>
          {getPatientActions().map((actionButton) => {
            return <div key={actionButton.color} className={classes.avatarContainer}>
              <Typography className={classes.avatarTitle} variant="subtitle2">{actionButton.title}</Typography>
              <Avatar onClick={() => navigateHandler(actionButton.route)} style={{backgroundColor: actionButton.color}}>
              <Tooltip title={actionButton.tooltip}>{actionButton.icon}</Tooltip>
            </Avatar>
            </div>
          })}
        </Grid>
        <Grid item xs={12}>
          <SurgeriesTableList error={surgeries.error} loading={surgeries.loading} patientSurgeries={getPatientSurgeries()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PatientTableList error={patients.error} loading={patients.loading} patients={patients.data?.slice(0, 5) ?? []} />
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  section: {
    marginRight: '1em',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarTitle: {
    marginBottom: '2px',
  },
  quickButtonContainer: {
    padding: 0,
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& > *': {
      cursor: 'pointer',
    },
  },
})));

type actionButton = {
  title: string,
  tooltip: string,
  icon: JSX.Element
  route: string,
  color: string,
}

const getPatientActions = (): actionButton[] => [
  {
    title: 'Create',
    tooltip: 'Create Patient Consent Process',
    icon: <CreateNewFolder />,
    route: '/surgeon/consent-process/create',
    color: '#23bbbb',
  },
  {
    title: 'Link',
    tooltip: 'Link Consent Process Using Unique Code',
    icon: <MergeType />,
    route: '/surgeon/consent-process/link',
    color: '#26aac7',
  },
  {
    title: 'Notes',
    tooltip: 'View Notes',
    icon: <Chat />,
    route: '/surgeon/notes/',
    color: '#356891',
  },
];

export default SurgeonHome;
