import Joi from "joi";

export const operationSchema = Joi.object({
  operation: Joi.string()
    .label('Operation')
    .required(),
});

export const dateSchema = Joi.object({
  date_of_surgery: Joi.date()
    .raw()
    .required(),
});

export const userDetailsStepSchema = Joi.object({
  gender: Joi.string()
    .label('Gender')
    .valid('Male','Female','Gender Diverse')
    .required(),
  laterality: Joi.string()
    .label('Laterality')
    .valid('Right','Left','Bilateral')
    .required(),
  date_of_birth: Joi.date()
    .raw()
    .required(),
});

export const consentSchema = Joi.object({
  signature_name: Joi.string()
    .label('Patient Name')
    .required(),
  signature: Joi.string()
    .label('Signature')
    .required()
    .valid(Joi.ref('signature_name')),
});
