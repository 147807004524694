import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {findAssessmentByType} from "../../../../app/helpers";
import {AssessmentType, ConsentStep} from "../../../../api/Types";
import PatientSummaryChart from "../PatientSummaryChart";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {RootState} from "../../../../store/store";
import CpContentContainer from "../../../UI/CpContentContainer";
import {getFullAssessment} from "../../../../store/patient/patientAssessmentSlice";

interface InitialAssessmentSummaryProps {
  step: ConsentStep
}

const InitialAssessmentSummary: FunctionComponent<InitialAssessmentSummaryProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const assessments = useSelector((state: RootState) => state.patient.assessment.full.data);
  const error = useSelector((state: RootState) => state.patient.assessment.full.error);
  const loading = useSelector((state: RootState) => state.patient.assessment.full.loading);

  useEffect(() => {
    if (assessments === null || assessments.surgery_id !== params.id) {
      dispatch(getFullAssessment(params.id));
    }
    // eslint-disable-next-line
  }, [params.id]);

  const assessment = findAssessmentByType(params.id, AssessmentType.INITIAL_ASSESSMENT);

  if (assessment === null) {
    return null;
  }

  return (
    <CpContentContainer error={error} loading={loading}>
      <PatientSummaryChart name={props.step.name} userCount={assessment.user_answer_count} totalCount={assessment.total_question_count} />
      <div className={classes.descriptionContainer}>
        <Typography paragraph variant="h5"><strong>{props.step.name}</strong></Typography>
        <Typography paragraph variant="body1">
          The pre assessment section helps you get an understanding of what you already know.
          This will help us tailor the assessment to your specific needs and surgery.
        </Typography>
      </div>
    </CpContentContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionContainer: {
      width: '40%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      textAlign: 'justify'
    },
  }),
);

export default InitialAssessmentSummary;
