import React, {FunctionComponent} from 'react';
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import moment from "moment/moment";
import {Surgery} from "../../api/Types";
import CpButton from "../UI/CpButton";
import {ArrowForward, Visibility} from "@material-ui/icons";
import CertificateAvatar from "../../pages/Surgeon/CompleteSurgeryAvatars/CertificateAvatar";
import ConsentFormAvatar from "../../pages/Surgeon/CompleteSurgeryAvatars/ConsentFormAvatar";
import {setActiveStep} from "../../store/patient/patientConsentSlice";
import {findConsentStepById} from "../../entities/ConsentStep";
import {CERTIFICATE_STEP_ID, CONSENT_FORM_STEP_ID} from "../../app/constants";

const PatientActiveSurgeries: FunctionComponent<{}> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const surgeries = useSelector((state: RootState) => state.patient.surgery.surgeries.data);
  const [revealCode, setRevealCode] = React.useState<string|null>(null);

  const surgeryContinueHandler = (surgeryId: string) => {
    history.push(`/patient/surgery/${surgeryId}`);
  }

  const toConsentProcess = async (surgeryId: string, stepId: string) => {
    await dispatch(setActiveStep(findConsentStepById(stepId)));
    history.push(`/patient/consent-process/${surgeryId}`);
  }

  const revealCodeHandler = (surgeryId: string) => {
    setRevealCode(surgeryId);
  }

  const sortedSurgeries = [...surgeries ?? []].sort((a: Surgery, b: Surgery) => {
    if (!a.surgery_date) {
      return 1;
    }

    if (!b.surgery_date) {
      return -1;
    }

    // @ts-ignore
    return new Date(a.surgery_date) - new Date(b.surgery_date ?? 1)
  });

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Typography paragraph variant="h6"><strong>List of Your Surgeries</strong></Typography>
      </Grid>
      <Grid item xs={12}>
        {surgeries !== null && surgeries.length > 0 ? sortedSurgeries.map((surgery) => {
          return <div className={classes.surgeryContainer} key={surgery.surgery_id}>
            <div className={classes.completeSection}>
              <CertificateAvatar onClick={(certificate) => certificate ? toConsentProcess(surgery.surgery_id, CERTIFICATE_STEP_ID) : null} className={classes.avatar} surgery_id={surgery.surgery_id} />
              <ConsentFormAvatar onClick={(consentForm) => consentForm ? toConsentProcess(surgery.surgery_id, CONSENT_FORM_STEP_ID) : null} className={classes.avatar} surgery_id={surgery.surgery_id} />
            </div>
            <div className={classes.surgeryTextContainer}>
              <Typography paragraph variant="body1"><strong>Operation:</strong> {surgery.operation_name}</Typography>
              { surgery.hospital_name ? <Typography paragraph variant="body1"><strong>Hospital:</strong> {surgery.hospital_name}, {surgery.hospital_city}</Typography> : null}
              <Typography variant="body1">
                  <strong>Expected Surgery Date:</strong> { surgery.surgery_date ? moment(surgery.surgery_date).format("MMM Do YY") : 'Unknown'}
              </Typography>
              <Typography variant="body1" className={revealCode !== null && surgery.surgery_id === revealCode ? classes.uniqueCodeContainer : undefined}>
                <strong>Unique code:</strong>
                { revealCode !== null && surgery.surgery_id === revealCode
                  ? <span className={classes.uniqueCodeText}>{surgery.unique_code}</span>
                  : <Tooltip className={classes.revealCode} title="Reveal Unique Code">
                    <IconButton onClick={() => revealCodeHandler(surgery.surgery_id)} aria-label="view">
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                }
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <CpButton
                variant="contained"
                color="primary"
                size="large"
                endIcon={<ArrowForward />}
                onClick={() => surgeryContinueHandler(surgery.surgery_id)}
              >
                View
              </CpButton>
            </div>
          </div>
        }) : <div className={classes.surgeryContainer}>
          <Typography className={classes.noSurgeryHeader} paragraph variant="h6"><strong>No active surgeries</strong></Typography>
          <Typography paragraph>If you have discussed surgery with your surgeon already, please contact them to create your consent form for you. <strong>Recommended.</strong></Typography>
          <Typography paragraph>If you are here to learn about the risks of your operation <strong>before</strong> deciding if surgery is the best route, please chose <strong>Start your Consent Process</strong> from the action list.</Typography>
        </div>
        }
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1.5em',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '1em 0',
      },
    },
    surgeryContainer: {
      width: '100%',
      padding: '2em 1.5em',
      marginTop: '2em',
      backgroundColor: 'white',
      boxShadow: '2px 2px 5px 5px #cccccc',
      textAlign: 'left',
    },
    surgeryTextContainer: {
      minHeight: '150px',
    },
    noSurgeryHeader: {
      textAlign: 'center',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '1em',
    },
    revealCode: {
      padding: '8px',
      margin: '8px',
    },
    uniqueCodeContainer: {
      paddingTop: '9px',
      paddingBottom: '14px',
    },
    uniqueCodeText: {
      paddingLeft: '10px',
      fontFamily: 'open sans',
      letterSpacing: '7px',
      textTransform: 'uppercase',
      fontSize: '22px',
    },
    completeSection: {
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        float: 'right',
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        float: 'none',
        paddingLeft: 0,
      },
      [theme.breakpoints.up('lg')]: {
        float: 'right',
        paddingLeft: theme.spacing(2),
      },
    },
    avatar: {
      paddingTop: '10px',
      '&:hover': {
        transform: 'scale(1.05)',
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  }),
);

export default PatientActiveSurgeries;
