import React, {FunctionComponent} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpBreadcrumbs from "../../components/UI/CpBreadcrumbs";
import ReactPlayer from "react-player/vimeo";
import CpButton from "../../components/UI/CpButton";
import CreateSurgeryForm from "../../components/Forms/CreateSurgeryForm";
import {NavigateNext} from "@material-ui/icons";

const PatientCreateConsentProcessPage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const [step, setStep] = React.useState('video');

  const breadcrumbs = [
    {to: '/patient/home', name: 'Home', click: true},
    {to: '/patient/consent-process/create', name: 'Create Consent Process', click: false},
  ];

  const onVideoComplete = (nextStep: string) => {
    if (step === 'video' || step === 'operation') {
      setStep(nextStep)
    }
  }

  return (
    <div className={classes.container}>
      <CpBreadcrumbs className={classes.breadcrumbs} breadcrumbs={breadcrumbs} />
      <Typography className={classes.headerTitle} variant="h5">
        Start Your Consent Journey
      </Typography>
      <div className={classes.resourceContainer}>
        { step === 'video'
          ? <>
            <Typography variant="h6" align="center" paragraph>Please watch this quick video that helps explain the consent process</Typography>
            <Grid container item xs={12}>
              <CpButton
                className={classes.skipButton}
                variant="contained"
                color="primary"
                size="large"
                endIcon={<NavigateNext />}
                onClick={() => onVideoComplete('operation')}
              >
                Skip Video
              </CpButton>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.embedContainer}>
                <ReactPlayer
                  url={`https://player.vimeo.com/video/207014941?autoplay=1title=0&byline=0&portrait=0`}
                  onEnded={() => onVideoComplete('operation')}
                  controls={true}
                  playing={true}
                />
              </div>
            </Grid>
          </> : null
        }
        { step === 'operation'
          ? <>
            <CreateSurgeryForm />
          </> : null
        }
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    padding: '0 1.5em',
    marginBottom: '5em',
    width:'97vw',
    overflow: 'hidden',
    margin: 0,
  },
  breadcrumbs: {
    margin: '0.5em 1.9em',
  },
  headerTitle: {
    marginBottom: '1em',
    padding: '0.5em 0',
    textAlign: 'center',
  },
  resourceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '5em',
    width: '65%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: 'auto',
  },
  skipButton: {
    width: '50%',
    margin: ' 1.5em auto',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  embedContainer: {
    margin: 'auto',
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'none',
    maxWidth: '100%',
    zIndex: 10,
    "& iframe": {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
})));

export default PatientCreateConsentProcessPage;
