import React, {FunctionComponent} from 'react';
import {createStyles, fade, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {SurgeonSurgery} from "../../api/Types";
import {useHistory} from "react-router-dom";

interface CpNotePatientListItemProps {
  surgery: SurgeonSurgery
  active?: boolean
}

const CpNotePatientListItem: FunctionComponent<CpNotePatientListItemProps> = props => {
  const classes = useStyles();
  const history = useHistory();

  const onChangeNoteHandler = () => {
    history.push(`/patient/notes/${props.surgery.surgery.surgery_id}`)
  }

  const title = props.surgery.surgeon?.surgeon_title ?? '';
  const surgeonName = props.surgery.surgeon
    ? `${title} ${props.surgery.surgeon.surgeon_first_name} ${props.surgery.surgeon.surgeon_last_name}`
    : 'Surgeon to be confirmed';

  const activeClass = props.active ?? false ? classes.active : '';

  return <div onClick={onChangeNoteHandler} key={props.surgery.surgery.surgery_id} className={[classes.surgeryNoteItem, activeClass].join(' ')}>
    <Typography variant="body1" paragraph><strong>{surgeonName}</strong></Typography>
    <Typography variant="body2">{props.surgery.surgery.operation_name}</Typography>
    <Typography noWrap variant="body2">{props.surgery.surgery.hospital_name}</Typography>
  </div>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    surgeryNoteItem: {
      width: '100%',
      padding: '1em',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.2),
        cursor: 'pointer',
      }
    },
    active: {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    }
  }),
);

export default CpNotePatientListItem;
