import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {ConsentStep, SyllabusType} from "../../../../api/Types";
import PatientSummaryChart from "../PatientSummaryChart";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {RootState} from "../../../../store/store";
import {getFullResource} from "../../../../store/patient/patientResourceSlice";
import CpContentContainer from "../../../UI/CpContentContainer";
import {findResourceByType} from "../../../../app/helpers";

interface LearningResourceSummaryProps {
  step: ConsentStep
}

const LearningResourceSummary: FunctionComponent<LearningResourceSummaryProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const resources = useSelector((state: RootState) => state.patient.resource.full.data);
  const error = useSelector((state: RootState) => state.patient.resource.full.error);
  const loading = useSelector((state: RootState) => state.patient.resource.full.loading);

  useEffect(() => {
    if (resources === null || resources.surgery_id !== params.id) {
      dispatch(getFullResource(params.id));
    }
    // eslint-disable-next-line
  }, [params.id]);

  const resource = findResourceByType(params.id, SyllabusType.INITIAL_LEARNING);

  if (resource === null) {
    return null;
  }

  return (
    <CpContentContainer error={error} loading={loading}>
      <PatientSummaryChart name={props.step.name} userCount={resource.user_engagement_count} totalCount={resource.total_resource_count} />
      <div className={classes.descriptionContainer}>
        <Typography paragraph variant="h5"><strong>{props.step.name}</strong></Typography>
        <Typography paragraph variant="body1">
          This section provides you all the learning resources required for your surgery. You can revisit this section at anytime.
        </Typography>
      </div>
    </CpContentContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionContainer: {
      width: '40%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      textAlign: 'justify'
    },
  }),
);

export default LearningResourceSummary;
