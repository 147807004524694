import React from "react";
import {AssessmentType, ConsentStep, SyllabusType} from "../api/Types";
import {
  Assessment,
  Assignment,
  CardMembership,
  Description,
  Done, LocalHospital,
  Person,
  QuestionAnswer,
  VideoLabel
} from "@material-ui/icons";
import {
  CONSENT_ASSESSMENT_STEP_ID,
  FEEDBACK_ASSESSMENT_STEP_ID,
  INITIAL_ASSESSMENT_STEP_ID,
  LEARNING_RESOURCE_STEP_ID,
  CERTIFICATE_STEP_ID,
  USER_DETAILS_STEP_ID,
  COMPLETED_STEP_ID, CONSENT_FORM_STEP_ID, FINALISE_SURGERY_DETAILS_STEP_ID,
} from "../app/constants";
import {findAssessmentByType, findResourceByType} from "../app/helpers";
import InitialAssessmentSummary from "../components/Patient/Steps/StepSummary/InitialAssessmentSummary";
import LearningResourceSummary from "../components/Patient/Steps/StepSummary/LearningResourceSummary";
import ConsentAssessmentSummary from "../components/Patient/Steps/StepSummary/ConsentAssessmentSummary";
import FeedbackAssessmentSummary from "../components/Patient/Steps/StepSummary/FeedbackAssessmentSummary";
import CertificateSummary from "../components/Patient/Steps/StepSummary/CertificateSummary";
import InitialAssessmentProcess from "../components/Patient/Steps/StepProcess/InitialAssessmentProcess";
import LearningResourceProcess from "../components/Patient/Steps/StepProcess/LearningResourceProcess";
import ConsentAssessmentProcess from "../components/Patient/Steps/StepProcess/ConsentAssessmentProcess";
import CertificateProcess from "../components/Patient/Steps/StepProcess/CertificateProcess";
import FeedbackAssessmentProcess from "../components/Patient/Steps/StepProcess/FeedbackAssessmentProcess";
import {submitAssessment} from "../store/patient/patientAssessmentSlice";
import {submitSyllabus} from "../store/patient/patientResourceSlice";
import {createCertificate} from "../store/patient/patientCertificateSlice";
import store from "../store/store";
import UserDetailsSummary from "../components/Patient/Steps/StepSummary/UserDetailsSummary";
import UserDetailsProcess from "../components/Patient/Steps/StepProcess/UserDetailsProcess";
import CompletedSummary from "../components/Patient/Steps/StepSummary/CompletedSummary";
import CompletedProcess from "../components/Patient/Steps/StepProcess/CompletedProcess";
import SignConsentFormProcess from "../components/Patient/Steps/StepProcess/SignConsentFormProcess";
import SignConsentFormSummary from "../components/Patient/Steps/StepSummary/SignConsentFormSummary";
import FinaliseSurgeryDetailsSummary from "../components/Patient/Steps/StepSummary/FinaliseSurgeryDetailsSummary";
import FinaliseSurgeryDetailsProcess from "../components/Patient/Steps/StepProcess/FinaliseSurgeryDetailsProcess";

export const getConsentSteps = () => {
  return [
    initialAssessmentStep,
    learningResourceStep,
    consentAssessmentStep,
    feedbackAssessmentStep,
    certificateConsentStep,
    userDetailsConsentStep,
    finaliseSurgeryDetailsConsentStep,
    consentFormConsentStep,
    completedConsentStep,
  ];
}

export const initialAssessmentStep: ConsentStep = {
  id: INITIAL_ASSESSMENT_STEP_ID,
  name: 'Initial Assessment',
  completed: function (surgeryId: string) { return this.completionPercentage(surgeryId) === 100 && assessmentCompleted(surgeryId, AssessmentType.INITIAL_ASSESSMENT) },
  completionPercentage: (surgeryId: string) => assessmentCompletion(surgeryId, AssessmentType.INITIAL_ASSESSMENT),
  getIcon: () => <Assessment />,
  getSummaryComponent: function () { return <InitialAssessmentSummary step={this} /> },
  getProcessComponent: function () { return <InitialAssessmentProcess step={this} /> },
  save: (surgeryId: string) => submitAssessment(surgeryId, findAssessmentByType(surgeryId, AssessmentType.INITIAL_ASSESSMENT)),
  required: true,
  continueButton: 'View Initial Assessment',
};

export const learningResourceStep: ConsentStep = {
  id: LEARNING_RESOURCE_STEP_ID,
  name: 'Learning Resources',
  completed: function (surgeryId: string) { return this.completionPercentage(surgeryId) === 100 && resourceCompleted(surgeryId, SyllabusType.INITIAL_LEARNING) },
  completionPercentage: (surgeryId: string) => resourceCompletion(surgeryId, SyllabusType.INITIAL_LEARNING),
  getIcon: () => <VideoLabel />,
  getSummaryComponent: function () { return <LearningResourceSummary step={this} /> },
  getProcessComponent: function () { return <LearningResourceProcess step={this} /> },
  save: (surgeryId: string) => submitSyllabus(surgeryId, findResourceByType(surgeryId, SyllabusType.INITIAL_LEARNING)),
  required: true,
  continueButton: 'View Resources',
};

export const consentAssessmentStep: ConsentStep = {
  id: CONSENT_ASSESSMENT_STEP_ID,
  name: 'Consent Assessment',
  completed: function (surgeryId: string) { return this.completionPercentage(surgeryId) === 100 && assessmentCompleted(surgeryId, AssessmentType.CONSENT_ASSESSMENT) },
  completionPercentage: (surgeryId: string) =>  assessmentCompletion(surgeryId, AssessmentType.CONSENT_ASSESSMENT),
  getIcon: () => <Assignment />,
  getSummaryComponent: function () { return <ConsentAssessmentSummary step={this} /> },
  getProcessComponent: function () { return <ConsentAssessmentProcess step={this} /> },
  save: (surgeryId: string) => submitAssessment(surgeryId, findAssessmentByType(surgeryId, AssessmentType.CONSENT_ASSESSMENT)),
  required: true,
  continueButton: 'View Assessment',
};

export const feedbackAssessmentStep: ConsentStep = {
  id: FEEDBACK_ASSESSMENT_STEP_ID,
  name: 'Feedback',
  completed: function (surgeryId: string) { return this.completionPercentage(surgeryId) === 100 && assessmentCompleted(surgeryId, AssessmentType.FEEDBACK_ASSESSMENT) },
  completionPercentage: (surgeryId: string) => assessmentCompletion(surgeryId, AssessmentType.FEEDBACK_ASSESSMENT),
  getIcon: () => <QuestionAnswer />,
  getSummaryComponent: function () { return <FeedbackAssessmentSummary step={this} /> },
  getProcessComponent: function () { return <FeedbackAssessmentProcess step={this} /> },
  save: (surgeryId: string) => submitAssessment(surgeryId, findAssessmentByType(surgeryId, AssessmentType.FEEDBACK_ASSESSMENT)),
  required: false,
  continueButton: 'View Feedback',
};

export const certificateConsentStep: ConsentStep = {
  id: CERTIFICATE_STEP_ID,
  name: 'Certificate',
  completed: function (surgeryId: string) { return this.completionPercentage(surgeryId) === 100 },
  completionPercentage: (surgeryId: string) => certificateComplete(surgeryId) ? 100 : 0,
  getIcon: () => <CardMembership />,
  getSummaryComponent: function () { return <CertificateSummary step={this} /> },
  getProcessComponent: function () { return <CertificateProcess step={this} /> },
  save: (surgeryId: string, consented: boolean) => createCertificate(surgeryId, consented),
  required: true,
  continueButton: 'View Certificate',
};

export const userDetailsConsentStep: ConsentStep = {
  id: USER_DETAILS_STEP_ID,
  name: 'Patient Details',
  completed: function (surgeryId: string) { return this.completionPercentage(surgeryId) === 100 },
  completionPercentage: (surgeryId: string) => userCompleted() ? 100 : 0,
  getIcon: () => <Person />,
  getSummaryComponent: function () { return <UserDetailsSummary step={this} /> },
  getProcessComponent: function () { return <UserDetailsProcess step={this} /> },
  save: (surgeryId: string) => Promise.resolve(),
  required: true,
  continueButton: 'View Patient Details',
};

export const finaliseSurgeryDetailsConsentStep: ConsentStep = {
  id: FINALISE_SURGERY_DETAILS_STEP_ID,
  name: 'Surgery Details',
  completed: function (surgeryId: string) { return this.completionPercentage(surgeryId) === 100 },
  completionPercentage: (surgeryId: string) => surgeryCompleted() ? 100 : 0,
  getIcon: () => <LocalHospital />,
  getSummaryComponent: function () { return <FinaliseSurgeryDetailsSummary step={this} /> },
  getProcessComponent: function () { return <FinaliseSurgeryDetailsProcess step={this} /> },
  save: (surgeryId: string) => Promise.resolve(),
  required: false,
  continueButton: 'View Surgery Details',
};

export const consentFormConsentStep: ConsentStep = {
  id: CONSENT_FORM_STEP_ID,
  name: 'Consent Form',
  completed: function (surgeryId: string) { return this.completionPercentage(surgeryId) === 100 },
  completionPercentage: (surgeryId: string) => consentFormComplete(surgeryId) ? 0 : 0, // currently last step, do not complete
  getIcon: () => <Description />,
  getSummaryComponent: function () { return <SignConsentFormSummary step={this} /> },
  getProcessComponent: function () { return <SignConsentFormProcess step={this} /> },
  save: (surgeryId: string) => Promise.resolve(),
  required: true,
  continueButton: 'View Consent Form',
};

export const completedConsentStep: ConsentStep = {
  id: COMPLETED_STEP_ID,
  name: 'Congratulations',
  completed: function (surgeryId: string) { return false },
  completionPercentage: (surgeryId: string) => 0,
  getIcon: () => <Done />,
  getSummaryComponent: function () { return <CompletedSummary step={this} /> },
  getProcessComponent: function () { return <CompletedProcess step={this} /> },
  save: (surgeryId: string) => Promise.resolve(),
  required: false,
  continueButton: 'Completed',
};

export const findConsentStepById = (id: string): ConsentStep => {
  for (let step of getConsentSteps()) {
    if (step.id === id) {
      return step;
    }
  }

  throw new Error(`No step found with ID ${id}`);
}

export const getConsentStepsByIds = (ids: string[]) => ids.map((id) => findConsentStepById(id))

export const resetCurrentConsentStep = (surgeryId: string) => {
  let currentStep = null;
  for (let step of getConsentSteps()) {
    if (!step.completed(surgeryId)) {
      currentStep = step
      break;
    }
  }

  if (currentStep === null) {
    // todo: think about returning a specific step for completing the process / displaying an error
    throw new Error('Unable to find and initialise step');
  }

  return currentStep;
}

const certificateComplete = (surgeryId: string): boolean => {
  const certificate = store.getState().patient.certificate.current.data

  if (certificate === null || certificate.surgery_id !== surgeryId) {
    return false;
  }

  return certificate.consented;
}

const consentFormComplete = (surgeryId: string): boolean => {
  const consentForm = store.getState().patient.consentForm.current.data

  if (consentForm === null || consentForm.surgery_id !== surgeryId) {
    return false;
  }

  return consentForm.consented && consentForm.patient_signed;
}

const assessmentCompletion = (surgeryId: string, stepType: AssessmentType): number => {
  const step = findAssessmentByType(surgeryId, stepType);

  if (step === null) {
    return 0;
  }

  return Math.round((step.user_answer_count / step.total_question_count) * 100);
}

const assessmentCompleted = (surgeryId: string, stepType: AssessmentType): boolean => {
  const step = findAssessmentByType(surgeryId, stepType);

  return step !== null && step.completed;
}

const resourceCompletion = (surgeryId: string, stepType: SyllabusType): number => {
  const step = findResourceByType(surgeryId, stepType);

  if (step === null) {
    return 0;
  }

  return Math.round((step.user_engagement_count / step.total_resource_count) * 100);
}

const resourceCompleted = (surgeryId: string, stepType: SyllabusType): boolean => {
  const step = findResourceByType(surgeryId, stepType);

  return step !== null && step.completed;
}

const userCompleted = (): boolean => {
  const patient = store.getState().patient.auth.account.data;

  return patient !== null &&
    patient.patient_gender !== null &&
    patient.patient_laterality !== null &&
    patient.patient_date_of_birth !== null;
}

const surgeryCompleted = (): boolean => {
  const surgery = store.getState().patient.surgery.current.data;

  return surgery !== null &&
    surgery.surgery_id !== null &&
    surgery.operation_id !== null &&
    surgery.surgeon_id !== null &&
    surgery.hospital_id !== null;
}
