import React from "react";
import { Route } from "react-router-dom";
import HomePage from "../pages/Public/HomePage";
import SurgeonLoginPage from "../pages/Public/SurgeonLoginPage";
import SurgeonRegisterPage from "../pages/Public/SurgeonRegisterPage";
import PatientLoginPage from "../pages/Public/PatientLoginPage";
import PatientRegisterPage from "../pages/Public/PatientRegisterPage";
import HomeContainer from "../components/Public/HomeContainer";
import PasswordResetPage from "../pages/Public/PasswordResetPage";
import ErrorPage404 from "../errors/pages/ErrorPage404";
import SurgeonHomeContainer from "../components/Public/SurgeonHomeContainer";
import PatientHomeContainer from "../components/Public/PatientHomeContainer";
import TermsPage from "../pages/Public/TermsPage";
import PrivacyStatementPage from "../pages/Public/PrivacyStatementPage";

const PublicRoutes = () => {
  const homeContainer = (component: JSX.Element) => {
    return <HomeContainer>{component}</HomeContainer>
  }

  const surgeonContainer = (component: JSX.Element) => {
    return <SurgeonHomeContainer>{component}</SurgeonHomeContainer>
  }

  const patientContainer = (component: JSX.Element) => {
    return <PatientHomeContainer>{component}</PatientHomeContainer>
  }

  return [
    <Route key="public/login" path="/login" component={() => patientContainer(<PatientLoginPage />)} />,
    <Route key="public/register" path="/register" component={() => patientContainer(<PatientRegisterPage />)} />,
    <Route key="public/patient/login" path="/patient/login" component={() => patientContainer(<PatientLoginPage />)} />,
    <Route key="public/patient/register" path="/patient/register" component={() => patientContainer(<PatientRegisterPage />)} />,
    <Route key="public/surgeon/login" path="/surgeon/login" component={() => surgeonContainer(<SurgeonLoginPage />)} />,
    <Route key="public/surgeon/register" path="/surgeon/register" component={() => surgeonContainer(<SurgeonRegisterPage />)} />,

    <Route key="public/password-reset" path="/password-reset" component={() => surgeonContainer(<PasswordResetPage />)} />,
    <Route key="public/terms" path="/terms" component={() => homeContainer(<TermsPage />)} />,
    <Route key="public/pages/terms" path="/pages/terms" component={() => homeContainer(<TermsPage />)} />,
    <Route key="public/privacy" path="/privacy" component={() => homeContainer(<PrivacyStatementPage />)} />,
    <Route key="public/pages/privacy" path="/pages/privacy" component={() => homeContainer(<PrivacyStatementPage />)} />,
    // Old site routes fallback to homepage to reduce confusion
    <Route key="public/media" path="/media" component={() => homeContainer(<HomePage />)} />,
    <Route key="public/faq" path="/faq" component={() => homeContainer(<HomePage />)} />,
    <Route key="public/contact-us" path="/contact-us" component={() => homeContainer(<HomePage />)} />,
    <Route key="public/about" path="/about" component={() => homeContainer(<HomePage />)} />,
    <Route key="public/pages" path="/pages" component={() => homeContainer(<HomePage />)} />,
    <Route key="public/home" path="/home" component={() => homeContainer(<HomePage />)} />,
    <Route key="public/" path="/" exact component={() => homeContainer(<HomePage />)} />,
    <Route key="public/*" path="*" component={() => <ErrorPage404 />} />,
  ];
}

export default PublicRoutes;
