import React, {FunctionComponent} from 'react';
import CpButton from "../../UI/CpButton";
import {Done, Save} from "@material-ui/icons";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface ConsentProcessSaveButtonProps {
  onSave: () => void
  className?: string
  disabled?: boolean
}

const ConsentProcessSaveButton: FunctionComponent<ConsentProcessSaveButtonProps> = props => {
  const classes = useStyles();

  return <CpButton
    disabled={props.disabled ?? false}
    className={props.className}
    variant="contained"
    color="primary"
    classes={{containedPrimary: classes.containedPrimary}}
    size="large"
    startIcon={!props.disabled ? <Save /> : <Done />}
    onClick={() => props.onSave()}
  >
    {!props.disabled ? 'Save' : 'Saved'}
  </CpButton>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containedPrimary: {
      backgroundColor: '#1f6f95',
      '&:hover': {
        backgroundColor: '#1b6386',
      }
    }
  }),
);

export default ConsentProcessSaveButton;
