import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import CpButton from "../UI/CpButton";
import {Search} from "@material-ui/icons";
import CpForm from "../UI/CpForm";
import React, {FunctionComponent} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ValidationErrorItem} from "joi";
import {useDispatch} from "react-redux";
import CpContentContainer from "../UI/CpContentContainer";
import {emailSchema} from "../../schemas/surgeonSchemas";
import CpTextField from "../UI/CpTextField";
import {findPatientByEmail} from "../../store/surgeon/surgeonAuthSlice";
import {Patient} from "../../api/Types";

interface FindPatientByEmailFormProps {
  onSelect: (patient: Patient) => void
}

const FindPatientByEmailForm: FunctionComponent<FindPatientByEmailFormProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [patientEmail, setPatientEmail] = React.useState<string|null>(null);
  const [joiErrors, setJoiErrors] = React.useState<ValidationErrorItem[]|null>(null);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string|null>(null);

  const submitHandler = async () => {
    setSubmitLoading(true);
    setSubmitError(null);
    setJoiErrors(null);

    try {
      await emailSchema.validateAsync(
        { email: patientEmail },
        { abortEarly: false }
      );

      if (patientEmail === null) {
        return;
      }

      const response = await dispatch(findPatientByEmail(patientEmail));
      setSubmitLoading(false);
      if (response.status === 'success') {
        props.onSelect(response.data);
        return;
      }
    } catch (e: any) {
      if (e.status === undefined) {
        setJoiErrors(e.details);
        setSubmitLoading(false)
        return;
      }
      setSubmitLoading(false)
      setSubmitError(e.data.data.errors[0])
    }
  }

  const patientEmailChangeHandler = (patientEmail: any) => {
    setPatientEmail(patientEmail.toUpperCase());
  }

  return <div className={classes.codeForm}>
    <CpForm onSubmit={submitHandler} name="Find your patient by email">
      <CpTextField
        id="find-patient-email"
        disabled={submitLoading}
        className={classes.field}
        type="email"
        label="Email"
        variant="outlined"
        onChange={(e) => patientEmailChangeHandler(e.target.value)}
        error={getJoiErrorMessage('email', joiErrors) !== null}
        helperText={getJoiErrorMessage('email', joiErrors)}
      />
      <CpButton
        disabled={submitLoading}
        type="submit"
        className={classes.field}
        variant="contained"
        color="primary"
        size="large"
        startIcon={<Search />}
        data-testid="login-button"
      >
        Find
      </CpButton>
      <CpContentContainer error={submitError} loading={submitLoading} />
    </CpForm>
  </div>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  codeForm: {
    margin: '0 5em',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    }
  },
  field: {
    width: '75%',
    minWidth: '200px',
    margin: '1em auto',
  },
})));

export default FindPatientByEmailForm;
