import {createSlice} from "@reduxjs/toolkit";
import {assessmentClient} from "../../api/Container";
import {Answer, AssessmentSummary, AssessmentType, QuestionAnswer, QuestionCategory} from "../../api/Types";
import store, {Error, Loading} from "../store";
import {filterNull, findAssessmentByType} from "../../app/helpers";

const initialState: patientAssessmentState = {
  full: {
    error: null,
    loading: false,
    data: null,
  },
  summary: {
    error: null,
    loading: false,
    data: null,
  }
}

export const patientAssessmentSlice = createSlice({
  name: 'patientAssessment',
  initialState,
  reducers: {
    setFullAssessment: (state, action) => {
      state.full.data = action.payload.assessment;
      state.full.error = null;
      state.full.loading = false;
    },
    submitFullAssessmentAnswer: (state, action) => {
      const category = action.payload.categoryIndex;
      const question = action.payload.questionIndex;

      if (state.full.data !== null) {
        // update user count
        if (state.full.data.categories[category].question_answers[question].patient_answer === null) {
          state.full.data.categories[category].user_answer_count += 1;
        }
        // add answer
        state.full.data.categories[category].question_answers[question].patient_answer = action.payload.answer;
      }
    },
    setSummaryAssessment: (state, action) => {
      state.summary.data = action.payload.assessment;
      state.summary.error = null;
      state.summary.loading = false;
    },
    clearFullAssessment: (state) => {
      state.full = initialState.full;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default patientAssessmentSlice.reducer;

// @ts-ignore as this is a middleware
export const submitAssessment = (surgeryId: string, assessment: QuestionCategory|null): Promise<any> => async dispatch => {
  if (assessment === null || assessment.completed) {
    return Promise.reject();
  }

  dispatch(patientAssessmentSlice.actions.setStart({index: 'full'}))
  const answers: Answer[] = assessment.question_answers.map((qa) => {
    return qa.patient_answer === null ?  null : {
      question_id: qa.question_id,
      answer: qa.patient_answer,
    };
  }).filter(filterNull);

  if (answers === []) {
    return Promise.reject();
  }

  try {
    const response = await assessmentClient.submitAnswers(surgeryId, assessment.assessment_id, answers);
    await dispatch(getFullAssessment(surgeryId)); // refresh answers
    dispatch(patientAssessmentSlice.actions.setEnd({index: 'full', error: null}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientAssessmentSlice.actions.setEnd({index: 'full', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getFullAssessment = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(patientAssessmentSlice.actions.setStart({index: 'full'}))

  try {
    const response = await assessmentClient.getFullSurgeryAssessment(surgeryId);
    dispatch(patientAssessmentSlice.actions.setFullAssessment(response.data));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientAssessmentSlice.actions.setEnd({index: 'full', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const submitAssessmentAnswer = (surgeryId: string, category: AssessmentType, question: QuestionAnswer, answer: string): Promise<any> => async dispatch => {
  try {
    const assessments = store.getState().patient.assessment.full.data;
    const assessment = findAssessmentByType(surgeryId, category);
    if (assessments === null || assessment === null) {
      return Promise.reject();
    }

    const categoryIndex = assessments.categories.findIndex((a) => a.category === assessment.category)
    const questionIndex = assessment.question_answers.findIndex((q) => q.question_id === question.question_id)

    if (categoryIndex === -1 || questionIndex === -1) {
      return Promise.reject();
    }

    dispatch(patientAssessmentSlice.actions.submitFullAssessmentAnswer({
      categoryIndex: categoryIndex,
      questionIndex: questionIndex,
      answer: answer,
    }));
    return Promise.resolve();
  } catch (err: any) {
    dispatch(patientAssessmentSlice.actions.setEnd({index: 'full', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getAssessmentSummary = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(patientAssessmentSlice.actions.setStart({index: 'summary'}))

  try {
    const response = await assessmentClient.getAssessmentSummary(surgeryId);
    dispatch(patientAssessmentSlice.actions.setSummaryAssessment(response.data));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientAssessmentSlice.actions.setEnd({index: 'summary', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const clearFullAssessment = (): Promise<any> => async dispatch => {
  dispatch(patientAssessmentSlice.actions.clearFullAssessment());
  return Promise.resolve();
}

type patientAssessmentState = {
  full: {
    error: Error
    loading: Loading
    data: {
      surgery_id: string
      categories: QuestionCategory[]
    }|null
  }
  summary: {
    error: Error
    loading: Loading
    data: AssessmentSummary|null,
  }
}
