import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {ConsentStep} from "../../../../api/Types";
import CpButton from "../../../UI/CpButton";
import {CardMembership, Chat} from "@material-ui/icons";
import CpContentContainer from "../../../UI/CpContentContainer";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {getSurgery} from "../../../../store/surgeon/surgeonSurgerySlice";
import {downloadConsentForm, findConsentForm} from "../../../../store/patient/patientConsentFormSlice";

interface SignConsentFormSummaryProps {
  step: ConsentStep
}

const SignConsentFormSummary: FunctionComponent<SignConsentFormSummaryProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const consentForm = useSelector((state: RootState) => state.patient.consentForm.current.data);
  const error = useSelector((state: RootState) => state.patient.consentForm.current.error);
  const loading = useSelector((state: RootState) => state.patient.consentForm.current.loading);

  useEffect(() => {
    if (consentForm === null || consentForm.surgery_id !== params.id) {
      Promise.all([
        dispatch(getSurgery(params.id)),
        dispatch(findConsentForm(params.id)),
      ]);
    }
    // eslint-disable-next-line
  }, [params.id]);

  const navigateNoteHandler = () => {
    history.push(`/patient/notes/${params.id}`);
  }

  const previewConsentFormHandler = () => {
    dispatch(downloadConsentForm(params.id));
  }

  const consentFormSigned = (): boolean => {
    return consentForm !== null && consentForm.patient_signed && consentForm.surgeon_signed;
  }

  return (
    <><div className={classes.buttonContainer}>
      <CpButton
        variant="contained"
        color="primary"
        classes={{containedPrimary: classes.noteButtonColour}}
        size="large"
        startIcon={<Chat />}
        onClick={navigateNoteHandler}
      >
        View Notes
      </CpButton>
      <CpButton
        disabled={loading || !consentFormSigned()}
        variant="contained"
        color="primary"
        classes={{containedPrimary: classes.downloadButtonColour}}
        size="large"
        startIcon={<CardMembership />}
        onClick={previewConsentFormHandler}
      >
        Download Consent Form
      </CpButton>
    </div>
  <div className={classes.descriptionContainer}>
    <Typography paragraph variant="h5"><strong>{props.step.name}</strong></Typography>
    <CpContentContainer error={error} loading={loading}>
      {
        consentForm ?
          <><Typography paragraph variant="body1">
            Well done on completing your consent form! If you have not done so, please review your consent form and if you are confident surgery is the correct option you may sign.
          </Typography>
            <Typography paragraph variant="body1">
            You can only download the consent form once both parties have signed.
          </Typography></>
          : <Typography paragraph variant="body1">
            Please consent before downloading your consent form.
            <br />Please click <strong>"Continue"</strong> below for more details.
          </Typography>
      }
    </CpContentContainer>
  </div></>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionContainer: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      textAlign: 'justify'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'space-around',
      height: '100%',
    },
    noteButtonColour: {
      backgroundColor: '#356891',
      '&:hover': {
        backgroundColor: '#2a5374',
      }
    },
    downloadButtonColour: {
      backgroundColor: '#26aac7',
      '&:hover': {
        backgroundColor: '#1e889f',
      },
    },
    preview: {
      padding: '4em',
      border: `1px solid ${theme.palette.primary.main}`
    },
  }),
);

export default SignConsentFormSummary;
