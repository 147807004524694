import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, Divider, Grid, Typography} from "@material-ui/core";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {getSurgeries} from "../../store/surgeon/surgeonSurgerySlice";
import SurgeonSurgeriesTableList from "../../components/Surgeon/SurgeonSurgeriesTableList";
import {Surgery} from "../../api/Types";
import CpContentContainer from "../../components/UI/CpContentContainer";
import {getListOfSurgeonPatients} from "../../store/surgeon/surgeonAuthSlice";

const SurgeonSurgeries: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const patients = useSelector((state: RootState) => state.surgeon.auth.patients.data);
  const patientError = useSelector((state: RootState) => state.surgeon.auth.patients.error);
  const error = useSelector((state: RootState) => state.surgeon.surgery.surgeries.error);
  const loading = useSelector((state: RootState) => state.surgeon.surgery.surgeries.loading);
  const surgeries = useSelector((state: RootState) => state.surgeon.surgery.surgeries.data);

  useEffect(() => {
    if (surgeries === null && error === null) {
      dispatch(getSurgeries());
    }

    if (patients === null && patientError === null) {
      dispatch(getListOfSurgeonPatients());
    }
    // eslint-disable-next-line
  }, []);

  const surgeriesCount: { [key: string]: number } = {};
  if (surgeries !== null) {
    surgeries.forEach((surgery: Surgery) => {
      surgeriesCount[surgery.operation_name] = surgeriesCount[surgery.operation_name] ? surgeriesCount[surgery.operation_name] + 1 : 1;
    });
  }

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Your Surgeries</Typography>
        <Divider />
      </div>
        <Grid container>
          <Grid container item xs={12} spacing={3} className={classes.section}>
            <Grid item xs={4}>
              <Card className={classes.card}>
                <Typography className={classes.headerTitle} variant="body1">Total Surgeries</Typography>
                <CpContentContainer error={error} loading={loading}>
                  <Typography className={classes.content} variant="h5">{surgeries?.length ?? 0}</Typography>
                </CpContentContainer>
              </Card>
            </Grid>
              {surgeries !== null && surgeriesCount ?
                Object.keys(surgeriesCount).sort((a,b) => surgeriesCount[b] - surgeriesCount[a]).slice(0, 2).map(operation => {
                  return <Grid key={operation} item xs={4}>
                    <Card className={classes.card}>
                      <Typography className={classes.headerTitle} variant="body1">{operation}</Typography>
                      <Typography className={classes.content} variant="h5">{surgeriesCount[operation]}</Typography>
                    </Card>
                  </Grid>
                })
              :null}
          </Grid>
          <Grid item xs={12}>
            <SurgeonSurgeriesTableList />
          </Grid>
        </Grid>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  content: {
    paddingLeft: '1em',
  },
  chart: {
    padding: '1em',
    minHeight: '500px',
  },
  section: {
    marginBottom: '1em',
  },
  card: {
    padding: '1em',
  },
})));

export default SurgeonSurgeries;
