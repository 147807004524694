import {createSlice} from "@reduxjs/toolkit";
import {consentFormClient} from "../../api/Container";
import {ConsentForm} from "../../api/Types";
import {Error, Loading} from "../store";

const initialState: patientConsentFormState = {
  current: {
    error: null,
    loading: false,
    data: null
  },
}

export const patientConsentFormSlice = createSlice({
  name: 'patientConsentForm',
  initialState,
  reducers: {
    setCurrentConsentForm: (state, action) => {
      state.current.data = action.payload.consentForm;
      state.current.error = null;
      state.current.loading = false;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default patientConsentFormSlice.reducer;

// @ts-ignore as this is a middleware
export const findConsentForm = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(patientConsentFormSlice.actions.setStart({index: 'current'}))

  try {
    const response = await consentFormClient.findConsentForm(surgeryId);
    dispatch(patientConsentFormSlice.actions.setCurrentConsentForm({consentForm: response.data.consent_form}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientConsentFormSlice.actions.setEnd({index: 'current', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const downloadConsentForm = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(patientConsentFormSlice.actions.setStart({index: 'current'}))

  try {
    const response = await consentFormClient.downloadConsentForm(surgeryId);
    dispatch(patientConsentFormSlice.actions.setEnd({index: 'current', error: null}))
    return Promise.resolve(response);
  } catch (err: any) {
    const error = 'Sorry, we were unable to download your consentForm, please try again in a few minutes';
    dispatch(patientConsentFormSlice.actions.setEnd({index: 'current', error: error}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const previewConsentForm = (surgeryId: string): Promise<any> => async dispatch => {
  try {
    const response = await consentFormClient.previewConsentForm(surgeryId);
    return Promise.resolve(response);
  } catch (err: any) {
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const createConsentForm = (surgeryId: string, signature: string): Promise<any> => async dispatch => {
  dispatch(patientConsentFormSlice.actions.setStart({index: 'current'}))

  try {
    const response = await consentFormClient.createConsentForm(surgeryId, signature);
    await dispatch(findConsentForm(surgeryId));
    dispatch(patientConsentFormSlice.actions.setEnd({index: 'current', error: null}))
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientConsentFormSlice.actions.setEnd({index: 'current', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

type patientConsentFormState = {
  current: {
    error: Error
    loading: Loading
    data: ConsentForm|null
  },
}
