import {ValidationErrorItem} from "joi";

export const getJoiErrorMessage = (fieldName: string, errors: ValidationErrorItem[]|null = null): string|null => {
  if (errors === null || !Array.isArray(errors) || errors.length === 0 ) {
    return null;
  }

  const index = errors.findIndex(function (error) {
    return error.context?.key === fieldName;
  });

  if (index !== -1) {
    return getAliasErrorMessage(errors[index]);
  }

  return null;
}

const getAliasErrorMessage = (error: ValidationErrorItem): string => {
  const key = error.context?.key ?? 'default'
  if (!errorAliases[key] || !errorAliases[key][error.type]) {
    return error.message;
  }

  return errorAliases[key][error.type];
}

type errorAlias = {
  [key: string]: {[key: string] : string}
}

const errorAliases: errorAlias = {
  title: {
    'string.base': 'Please provide a title e.g "Mr. Mrs. Miss. Dr."',
  },
  first_name: {
    'string.base': 'Please provide your first name',
    'string.empty': 'Please provide your first name',
  },
  last_name: {
    'string.base': 'Please provide your last name',
    'string.empty': 'Please provide your last name',
  },
  email: {
    'string.base': 'Email provided is invalid',
    'string.empty': 'Please provide your email',
    'string.email': 'Email provided is invalid',
    'any.required': 'Email provided is invalid',
  },
  password: {
    'string.base': 'Please provide a password',
    'string.empty': 'Please provide a password',
    'string.pattern.base': 'Your password must contain a minimum of eight characters containing at least one letter and one number',
  },
  confirmPassword: {
    'string.base': 'This must be the same as your password',
    'string.empty': 'This must be the same as your password',
    'string.pattern.base': 'This must be the same as your password',
    'any.only': 'This must be the same as your password',
  },
  gmc_number: {
    'string.base': 'Please provide your GMC Number',
    'string.empty': 'Please provide your GMC Number',
    'string.pattern.base': 'Your GMC Number must be 7 digits',
  },
  mobile_number: {
    'string.base': 'Please provide your Mobile Number',
    'string.empty': 'Please provide your Mobile Number',
    'string.pattern.base': 'Please provide a valid UK Mobile Number starting 44 or 07',
  },
  signature: {
    'string.base': 'This must match your name exactly, it is case sensitive',
    'string.empty': 'This must match your name exactly, it is case sensitive',
    'string.pattern.base': 'This must match your name exactly, it is case sensitive',
    'any.only': 'This must match your name exactly, it is case sensitive',
  },
  operation: {
    'string.base': 'Please select an operation',
    'string.empty': 'Please select an operation',
    'string.pattern.base': 'Please select an operation',
    'any.only': 'Please select an operation',
  },
  unique_code: {
    'string.base': 'Invalid code given, please try again',
    'string.empty': 'Invalid code given, please try again',
    'string.pattern.base': 'Invalid code given, please try again',
    'any.only': 'Invalid code given, please try again',
  },
  gender: {
    'string.base': 'Please select a valid option from the dropdown',
    'string.empty': 'Please select a valid option from the dropdown',
    'string.pattern.base': 'Please select a valid option from the dropdown',
    'any.only': 'Please select a valid option from the dropdown',
  },
  laterality: {
    'string.base': 'Please select a valid option from the dropdown',
    'string.empty': 'Please select a valid option from the dropdown',
    'string.pattern.base': 'Please select a valid option from the dropdown',
    'any.only': 'Please select a valid option from the dropdown',
  },
  date_of_birth: {
    'date.base': 'Invalid date given',
    'date.empty': 'Invalid date given',
    'date.pattern.base': 'Invalid date given',
    'any.only': 'Invalid date given',
    'any.required': 'Invalid date given',
  },
  date_of_surgery: {
    'date.base': 'Invalid date given',
    'date.empty': 'Invalid date given',
    'date.pattern.base': 'Invalid date given',
    'any.only': 'Invalid date given',
    'any.required': 'Invalid date given',
  },
  default: {
    'any.only': 'An error occurred',
    'any.required': 'An error occurred',
    'string.base': 'An error occurred',
    'string.empty': 'An error occurred',
    'string.pattern.base': 'An error occurred',
  }
};
