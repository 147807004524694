import React, {FunctionComponent, useEffect} from 'react';
import Chart, {ChartData, ChartOptions} from 'chart.js';

type CpChartProps = {
  id: string,
  type: string,
  data?: ChartData,
  options?: ChartOptions,
  className?: string,
}

const CpChart: FunctionComponent<CpChartProps> = (props) => {
  const chartRef = React.createRef<HTMLCanvasElement>();

  useEffect(() => {
    const chartRefContext = chartRef.current?.getContext("2d");

    if (!chartRefContext) {
      return;
    }

    new Chart(chartRefContext, {
      type: props.type,
      data: props.data,
      options: props.options,
    });
    // eslint-disable-next-line
  }, [props.id]);

  return (
    <div className={props.className}>
      <canvas
        id={props.id}
        ref={chartRef}
        height='100%'
        width='100%'
      />
    </div>
  );
}

export default CpChart;
