type environment = "production" | "staging" | "development";

// @ts-ignore
export const ENVIRONMENT: environment = process.env.NODE_ENV;

export const INITIAL_ASSESSMENT_STEP_ID: string = '1ec1e148-d7fe-424e-a852-10332c91d074';
export const LEARNING_RESOURCE_STEP_ID: string = '3949b403-a99f-4ff7-81a4-6257157d1633';
export const CONSENT_ASSESSMENT_STEP_ID: string = 'd0e53c50-6a88-4b53-bdf3-432b7cb69aa6';
export const FEEDBACK_ASSESSMENT_STEP_ID: string = '26eaa627-8ad0-4bf5-8cd9-b5e3b81b4f76';
export const CERTIFICATE_STEP_ID: string = '03fea42d-aced-46a3-a2fc-b2bc620021ec';
export const USER_DETAILS_STEP_ID: string = 'b19973bd-4b9e-4aba-8bc5-ec30277ea8a6';
export const FINALISE_SURGERY_DETAILS_STEP_ID: string = '715d1a18-9d7a-49b6-bac0-800ff72d30b0';
export const CONSENT_FORM_STEP_ID: string = '95a7af9c-e3e0-4d72-8c31-4f48188f9e5f';
export const COMPLETED_STEP_ID: string = '7a62c0e3-5217-4666-a4b2-c5d69eb096db';
