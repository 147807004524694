import {Button} from "@material-ui/core";
import {withStyles, createStyles, Theme} from "@material-ui/core/styles";

const CpButton = withStyles((theme: Theme) => createStyles({
  containedPrimary: {
    color: theme.palette.text.secondary,
  }
}))(Button);

export default CpButton;
