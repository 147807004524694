import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Divider, Typography} from "@material-ui/core";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {getSurgery} from "../../store/surgeon/surgeonSurgerySlice";
import SurgeryCertificateSection from "../../components/Surgeon/SurgeryCertificateSection";
import {useParams} from "react-router-dom";
import CpContentContainer from "../../components/UI/CpContentContainer";
import {findCertificate} from "../../store/surgeon/surgeonCertificateSlice";
import CpBreadcrumbs from "../../components/UI/CpBreadcrumbs";

const SurgeonCertificatePage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const error = useSelector((state: RootState) => state.surgeon.surgery.surgeries.error);
  const loading = useSelector((state: RootState) => state.surgeon.surgery.surgeries.loading);
  const surgery = useSelector((state: RootState) => state.surgeon.surgery.currentSurgery.data);
  const surgeon = useSelector((state: RootState) => state.surgeon.auth.account.data);
  const certificate = useSelector((state: RootState) => state.surgeon.certificate.current.data);

  useEffect(() => {
    if (surgery === null || params.id !== surgery.surgery_id) {
      dispatch(getSurgery(params.id));
    }

    if (certificate === null || certificate.surgery_id !== params.id) {
      dispatch(findCertificate(params.id));
    }

    // eslint-disable-next-line
  }, [params.id]);

  const breadcrumbs = [
    {to: '/surgeon/home', name: 'Home', click: true},
    {to: `/surgeon/surgeries/${params.id}`, name: 'Surgery Details', click: true},
    {to: `/surgeon/certificate/${params.id}`, name: 'Certificate Preview', click: false}
  ];

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Certificate</Typography>
        <Divider />
      </div>
      <><CpBreadcrumbs className={classes.breadcrumbs} breadcrumbs={breadcrumbs} />
      <CpContentContainer error={error} loading={loading}>
        { surgery && surgeon ? <SurgeryCertificateSection surgery={surgery} minHeight={750} /> : null}
      </CpContentContainer>
    </></>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  breadcrumbs: {
    paddingBottom: '1em',
    paddingLeft: '0.5em',
    backgroundColor: theme.palette.background.default,
  },
})));

export default SurgeonCertificatePage;
