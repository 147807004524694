export type ConsentStep = {
  id: string
  name: string
  completed: (surgeryId: string) => boolean
  completionPercentage: (surgeryId: string) => number
  getIcon: () => JSX.Element
  getProcessComponent: () => JSX.Element
  getSummaryComponent: () => JSX.Element
  save: (...args: any[]) => Promise<any>
  required: boolean
  continueButton: string
}

export type Surgeon = {
  surgeon_id?: string|null
  surgeon_title: string|null
  surgeon_first_name: string
  surgeon_last_name: string
  surgeon_gmc_number: string
  surgeon_status: SurgeonStatus
  surgeon_mobile_number: string|null
  surgeon_email: string
  surgeon_password?: string|null
}

export type Patient = {
  patient_id?: string|null
  patient_title: string|null
  patient_first_name: string
  patient_last_name: string
  patient_mobile_number: string|null
  patient_email: string
  patient_gender: string|null
  patient_laterality: string|null
  patient_date_of_birth: string|null
  patient_password?: string|null
  patient_created_at?: string
}

export type UpdatePatient = {
  gender?: string|null
  laterality?: string|null
  date_of_birth?: string|null
}

export type Surgery = {
  surgery_id: string
  unique_code: string
  patient_id: string
  surgeon_id: string|null
  operation_id: string
  hospital_id: string|null
  operation_name: string
  hospital_name: string|null
  hospital_city: string|null
  hospital_address_1: string|null
  hospital_address_2: string|null
  hospital_address_3: string|null
  hospital_county: string|null
  hospital_postcode: string|null
  surgery_date: string|null
  surgery_created_at: string
}

export type Operation = {
  operation_id: string
  operation_name: string
}

export type Hospital = {
  hospital_id: string
  hospital_name: string
  hospital_address_1: string|null
  hospital_address_2: string|null
  hospital_address_3: string|null
  hospital_city: string|null
  hospital_county: string|null
  hospital_postcode: string
}

export type QuestionCategory = {
  assessment_id: string
  category: AssessmentType
  question_answers: QuestionAnswer[]
  total_question_count: number
  user_answer_count: number
  completed: boolean
}

export type QuestionAnswer = {
  question_id: string
  question_heading: string
  question_text: string
  question_possible_answers: string[]
  question_allow_multiple: boolean
  patient_answer: string
}

export type Answer = {
  question_id: string
  answer: string
}

export type Credentials = {
  email: string
  password: string
}

export enum SurgeonStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
}

export enum Role {
  ADMIN = 'ADMIN',
  SURGEON = 'SURGEON',
  PATIENT = 'PATIENT',
}

export type PatientSurgery = {
  surgery: Surgery
  patient: Patient
}

export type SurgeonSurgery = {
  surgery: Surgery
  surgeon: Surgeon|null
}

export type ConsentFormSurgery = {
  surgery: Surgery
  patient: Patient
  form: ConsentForm
}

export type Assessment = {
  id: string
  operation_id: string
  type: AssessmentType
  name: string
  required: boolean
}

export type AssessmentSummary = {
  started: Assessment[]
  completed: Assessment[]
  todo: Assessment[]
  total: Assessment[]
}

export enum AssessmentType {
  INITIAL_ASSESSMENT = 'Pre Assessment',
  CONSENT_ASSESSMENT = 'Assessment',
  FEEDBACK_ASSESSMENT = 'Feedback',
}

export type Syllabus = {
  syllabus_id: string
  operation_id: string
  syllabus_type: Syllabus
  syllabus_name: string
  syllabus_required: boolean
}

export enum SyllabusType {
  INITIAL_LEARNING = 'Pre Assessment',
}

export enum ResourceType {
  VIMEO = 'VIMEO',
}

export type ResourceCategory = {
  syllabus_id: string
  category: SyllabusType
  resource_engagements: ResourceEngagement[]
  total_resource_count: number
  user_engagement_count: number
  completed: boolean
}

export type ResourceEngagement = {
  resource_id: string
  resource_heading: string
  resource_title: string
  resource_content: string
  resource_type: ResourceType
  patient_engagement: number
}

export type Engagement = {
  resource_id: string
  viewed: number
}

export type ResourceSummary = {
  started: Syllabus[]
  completed: Syllabus[]
  todo: Syllabus[]
  total: Syllabus[]
}

export type Note = {
  id: string
  surgery_id: string
  content: string
  author_id: string
  created_at: string
}

export type Certificate = {
  id: string
  surgery_id: string
  consented: boolean
}

export type ConsentForm = {
  id: string
  surgery_id: string
  patient_signed: boolean
  surgeon_signed: boolean
  patient_signed_at: string|null
  surgeon_signed_at: string|null
  key: string|null
  consented: boolean
}
