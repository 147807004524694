import {createSlice} from "@reduxjs/toolkit";
import {authClient} from "../../api/Container";
import {Surgeon, SurgeonStatus} from "../../api/Types";
import {Error, Loading} from "../store";

const initialState: adminAuthState = {
  unverifiedSurgeons: {
    error: null,
    loading: false,
    data: null
  },
}

export const adminAuthSlice = createSlice({
  name: 'adminAuth',
  initialState,
  reducers: {
    setUnverifiedSurgeons: (state, action) => {
      state.unverifiedSurgeons.data = action.payload.surgeons;
      state.unverifiedSurgeons.error = null;
      state.unverifiedSurgeons.loading = false;
    },
    removeUnverifiedSurgeon: (state, action) => {
      const index = state.unverifiedSurgeons.data?.findIndex(value => {
        return value.surgeon_id === action.payload.surgeonId
      });

      if (index !== undefined && index > -1) {
        state.unverifiedSurgeons.data?.splice(index, 1);
      }
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export const {removeUnverifiedSurgeon, setUnverifiedSurgeons} = adminAuthSlice.actions;

export default adminAuthSlice.reducer;

// @ts-ignore as this is a middleware
export const getUnverifiedSurgeons = (): Promise<any> => async dispatch => {
  dispatch(adminAuthSlice.actions.setStart({index: 'unverifiedSurgeons'}))

  try {
    const response = await authClient.getUnverifiedSurgeons();
    dispatch(adminAuthSlice.actions.setUnverifiedSurgeons({surgeons: response.data.surgeons}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(adminAuthSlice.actions.setEnd({index: 'unverifiedSurgeons', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const surgeonStatus = (surgeonId: string, status: SurgeonStatus): Promise<any> => async dispatch => {
  dispatch(adminAuthSlice.actions.setStart({index: 'unverifiedSurgeons'}))

  try {
    const response = await authClient.setSurgeonStatus(surgeonId, status);

    if (status !== SurgeonStatus.NOT_VERIFIED) {
      dispatch(adminAuthSlice.actions.removeUnverifiedSurgeon({surgeonId: surgeonId}));
    }

    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(adminAuthSlice.actions.setEnd({index: 'unverifiedSurgeons', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

type adminAuthState = {
  unverifiedSurgeons: {
    error: Error
    loading: Loading
    data: Surgeon[]|null
  }
}
