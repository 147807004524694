import axios from 'axios';
import {Answer} from "./Types";

const assessmentInstance = axios.create({
  baseURL: '/api/assessment',
});

assessmentInstance.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;

export default class AssessmentClient {
  getFullSurgeryAssessment = (surgeryId: string) => {
    this.authorize();

    return assessmentInstance.get(`/surgery/${surgeryId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getSurgeryAssessmentByOperation = (operationId: string) => {
    this.authorize();

    return assessmentInstance.get(`/operation/${operationId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  submitAnswers = (surgeryId: string, assessmentId: string, answers: Answer[]) => {
    this.authorize();

    return assessmentInstance.post('/surgery', {
      surgery_id: surgeryId,
      assessment_id: assessmentId,
      answers: answers
    })
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getAssessmentSummary = (surgeryId: string) => {
    this.authorize();

    return assessmentInstance.get(`/summary/surgery/${surgeryId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  private authorize = () => {
    assessmentInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }
}
