import {createSlice} from "@reduxjs/toolkit";
import {Hospital, Surgery} from "../../api/Types";
import {Error, Loading} from "../store";
import {surgeryClient} from "../../api/Container";
import {patientSurgerySlice} from "../patient/patientSurgerySlice";
import {Moment} from "moment";

const initialState: surgeonSurgeryState = {
  surgeries: {
    error: null,
    loading: false,
    data: null
  },
  hospitals: {
    error: null,
    loading: false,
    data: null
  },
  currentPatientSurgeries: {
    error: null,
    loading: false,
    data: null
  },
  currentSurgery: {
    error: null,
    loading: false,
    data: null
  },
}

export const surgeonSurgerySlice = createSlice({
  name: 'surgeonSurgery',
  initialState,
  reducers: {
    setSurgeries: (state, action) => {
      state.surgeries.data = action.payload.surgeries;
      state.surgeries.error = null;
      state.surgeries.loading = false;
    },
    setHospitals: (state, action) => {
      state.hospitals.data = action.payload.hospitals;
      state.hospitals.error = null;
      state.hospitals.loading = false;
    },
    setCurrentPatientSurgeries: (state, action) => {
      state.currentPatientSurgeries.data = action.payload.surgeries;
      state.currentPatientSurgeries.error = null;
      state.currentPatientSurgeries.loading = false;
    },
    setCurrentSurgery: (state, action) => {
      state.currentSurgery.data = action.payload.surgery;
      state.currentSurgery.error = null;
      state.currentSurgery.loading = false;
    },
    clearPatientSurgeries: (state) => {
      state.currentPatientSurgeries = initialState.currentPatientSurgeries;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default surgeonSurgerySlice.reducer;

// @ts-ignore as this is a middleware
export const getPatientSurgeries = (patientId: string): Promise<any> => async dispatch => {
  dispatch(surgeonSurgerySlice.actions.setStart({index: 'currentPatientSurgeries'}))

  try {
    const response = await surgeryClient.getPatientSurgeries(patientId);
    const sortedSurgeries = [...response.data.surgeries];
    sortedSurgeries.sort((a: Surgery, b: Surgery) => a.surgery_created_at > b.surgery_created_at ? -1 : 1);
    dispatch(surgeonSurgerySlice.actions.setCurrentPatientSurgeries({surgeries: sortedSurgeries}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonSurgerySlice.actions.setEnd({index: 'currentPatientSurgeries', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getSurgeries = (): Promise<any> => async dispatch => {
  dispatch(surgeonSurgerySlice.actions.setStart({index: 'surgeries'}))

  try {
    const response = await surgeryClient.getSurgeonSurgeries();
    const sortedSurgeries = [...response.data.surgeries];
    sortedSurgeries.sort((a: Surgery, b: Surgery) => a.surgery_created_at > b.surgery_created_at ? -1 : 1);
    dispatch(surgeonSurgerySlice.actions.setSurgeries({surgeries: sortedSurgeries}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonSurgerySlice.actions.setEnd({index: 'surgeries', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getSurgery = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(surgeonSurgerySlice.actions.setStart({index: 'currentSurgery'}))

  try {
    const response = await surgeryClient.getSurgery(surgeryId);
    dispatch(surgeonSurgerySlice.actions.setCurrentSurgery({surgery: response.data.surgery}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonSurgerySlice.actions.setEnd({index: 'currentSurgery', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getHospitals = (): Promise<any> => async dispatch => {
  dispatch(surgeonSurgerySlice.actions.setStart({index: 'hospitals'}))

  try {
    const response = await surgeryClient.getHospitals();
    const hospitals = response.data.hospitals.sort((h1: Hospital, h2: Hospital) => (h1.hospital_city ?? '1') > (h2.hospital_city ?? '-1'));
    dispatch(surgeonSurgerySlice.actions.setHospitals({hospitals}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonSurgerySlice.actions.setEnd({index: 'hospitals', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getSurgeryByUniqueCode = (uniqueCode: string): Promise<any> => async dispatch => {
  dispatch(surgeonSurgerySlice.actions.setStart({index: 'currentSurgery'}))

  try {
    const response = await surgeryClient.getSurgeryByUniqueCode(uniqueCode);
    dispatch(surgeonSurgerySlice.actions.setCurrentSurgery({surgery: response.data.surgery}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonSurgerySlice.actions.setEnd({index: 'currentSurgery', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const updateSurgery = (hospitalId: string, surgeonId: string, surgeryId: string, dateOfSurgery: Moment|null = null): Promise<any> => async dispatch => {
  try {
    const response = await surgeryClient.updateSurgery(hospitalId, surgeonId, surgeryId, dateOfSurgery);
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientSurgerySlice.actions.setEnd({index: 'current', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const clearPatientSurgeries = (): Promise<any> => async dispatch => {
  dispatch(surgeonSurgerySlice.actions.clearPatientSurgeries());
  return Promise.resolve();
}

type surgeonSurgeryState = {
  surgeries: {
    error: Error
    loading: Loading
    data: Surgery[]|null
  }
  hospitals: {
    error: Error
    loading: Loading
    data: Hospital[]|null
  }
  currentPatientSurgeries: {
    error: Error
    loading: Loading
    data: Surgery[]|null
  }
  currentSurgery: {
    error: Error
    loading: Loading
    data: Surgery|null
  }
}
