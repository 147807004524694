import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import PatientConsentProcess from "../../components/Patient/PatientConsentProcess";
import CpBreadcrumbs from "../../components/UI/CpBreadcrumbs";

const PatientConsentProcessPage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const currentSurgery = useSelector((state: RootState) => state.patient.surgery.current.data);

  if (currentSurgery === null) {
    return null;
  }

  const breadcrumbs = [
    {to: '/patient/home', name: 'Home', click: true},
    {to: `/patient/surgery/${currentSurgery.surgery_id}`, name: 'Summary', click: true},
    {to: `/patient/consent-process/${currentSurgery.surgery_id}`, name: 'Consent Process', click: false}
  ];

  return (
    <>
      <CpBreadcrumbs className={classes.breadcrumbs} breadcrumbs={breadcrumbs} />
      <PatientConsentProcess surgery={currentSurgery} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  breadcrumbs: {
    marginLeft: '7%',
    marginBottom: '1.2em',
  }
})));

export default PatientConsentProcessPage;
