import React, {FunctionComponent} from 'react';
import {useSelector} from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Grid, Hidden,
  Typography
} from "@material-ui/core";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpChart from "../../components/UI/CpChart";
import {ExpandMore} from "@material-ui/icons";

const SurgeryQuestionAnswersSection: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const questionCategories = useSelector((state: RootState) => state.surgeon.assessment.surgeryQuestionAnswers.data?.categories);

  if (!questionCategories) {
    return null;
  }

  const categoryChartData: any[] = [];
  questionCategories.forEach((category) => {
    if (category.category !== 'Feedback') {
      categoryChartData.push({
        name: category.category,
        data: {
          labels: ["Complete", "Incomplete"],
          datasets: [{
            data: [category.user_answer_count, (category.total_question_count - category.user_answer_count)],
            backgroundColor: [
              'rgba(35, 187, 186, 0.6)',
            ]
          }],
        },
        options: {
          legend: { display: false },
          title: {
            display: true,
            text: `${category.category} ${Math.floor(category.user_answer_count / category.total_question_count * 100)}% complete`
          },
        },
      });
    }
  });

  return (
    <Card className={classes.cardSection}>
      <CardHeader title="Question & Answers" />
        <Grid container direction="row">
          {categoryChartData.map(chart => {
            return <Hidden smDown key={chart.name}>
                <Grid item xs={6} className={classes.gridChartSection}>
                  <CpChart id={chart.name} type={'doughnut'} data={chart.data} options={chart.options} />
                </Grid>
            </Hidden>
          })}
          <Grid item xs={12}>
            {questionCategories.map((questionCategory) => {
              return <Accordion style={{marginTop: '1em'}} key={questionCategory.category}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id={questionCategory.category}
                >
                  <Typography className={classes.accordionHeader}>{questionCategory.category}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardContent className={classes.cardContent}>
                    {questionCategory.question_answers.map((questionAnswer, key) => {
                      return <Grid container key={key}>
                        <Grid item xs={9}>
                          <Typography variant="body2" paragraph dangerouslySetInnerHTML={{__html: questionAnswer.question_text}} />
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.answerText} variant="body2" paragraph>{questionAnswer.patient_answer}</Typography>
                        </Grid>
                      </Grid>
                    })}
                  </CardContent>
                </AccordionDetails>
              </Accordion>
            })}
          </Grid>
        </Grid>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  gridChartSection: {
    padding: '0.5em',
  },
  cardSection: {
    marginRight: '1em',
  },
  cardContent: {
    width: '100%',
    paddingTop: 0,
  },
  accordionHeader: {
    paddingTop: '0.75em',
    paddingBottom: '0.75em',
  },
  answerText: {
    textAlign: 'right',
    fontWeight: 600,
  },
})));

export default SurgeryQuestionAnswersSection;
