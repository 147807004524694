import React, {FunctionComponent} from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

type NavListProps = {
  listItems: {text: string, icon: JSX.Element, route: string}[],
  onNavSelect: (itemSelected: string) => void,
};

const NavList: FunctionComponent<NavListProps> = (props) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {props.listItems.map(item => (
          <ListItem onClick={() => props.onNavSelect(item.text)} button key={item.route}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
  }),
);

export default NavList;
