import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import {ConsentStep} from "../../../../api/Types";
import CpContentContainer from "../../../UI/CpContentContainer";
import CpButton from "../../../UI/CpButton";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {BorderColor, CardMembership} from "@material-ui/icons";
import CpTextField from "../../../UI/CpTextField";
import {getJoiErrorMessage} from "../../../../errors/joiErrorHandler";
import {ValidationErrorItem} from "joi";
import {createConsentForm, findConsentForm} from "../../../../store/patient/patientConsentFormSlice";
import {consentSchema} from "../../../../schemas/patientSchemas";
import {downloadConsentForm, previewConsentForm} from "../../../../store/patient/patientConsentFormSlice";

interface SignConsentFormProcessProps {
  step: ConsentStep
}

const SignConsentFormProcess: FunctionComponent<SignConsentFormProcessProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const [previewError, setPreviewError] = React.useState<string|null>(null);
  const [previewPdf, setConsentFormPreview] = React.useState<string|null>(null);
  const [signature, setSignature] = React.useState<string>('');
  const [signatureError, setSignatureError] = React.useState<ValidationErrorItem[]|null>(null);
  const patient = useSelector((state: RootState) => state.patient.auth.account.data);
  const consentForm = useSelector((state: RootState) => state.patient.consentForm.current.data);
  const consentFormError = useSelector((state: RootState) => state.patient.consentForm.current.error);
  const consentFormLoading = useSelector((state: RootState) => state.patient.consentForm.current.loading);

  useEffect(() => {
    if(consentForm === null || consentForm.surgery_id !== params.id) {
      dispatch(findConsentForm(params.id));
    }

    let mounted = true;
    if (previewPdf === null || consentForm === null || consentForm.surgery_id !== params.id) {
      dispatch(previewConsentForm(params.id)).then(filePath => {
        if (mounted) {
          setConsentFormPreview(filePath)
        }
      }).catch(() => {
        if (mounted) {
          setPreviewError('We were unable to download your consent form at this time');
        }
      });
    }

    return () => { mounted = false };
    // eslint-disable-next-line
  }, [params.id]);

  const submitHandler = async (e: React.FormEvent) => {
    if (patient === null) {
      window.location.reload();
      return;
    }

    setSignatureError(null)
    try {
      const patientName = `${patient.patient_first_name} ${patient.patient_last_name}`
      await consentSchema.validateAsync(
        {signature: signature, signature_name: patientName},
        { abortEarly: false }
      );

      await dispatch(createConsentForm(params.id, signature));

      history.push(`/patient/surgery/${params.id}`);
    } catch (e: any) {
      if (e.status === undefined) {
        setSignatureError(e.details);
      }
    }
  }

  const previewConsentFormHandler = () => {
    dispatch(downloadConsentForm(params.id));
  }

  const signatureChangeHandler = (value: string) => {
    setSignature(value);
  }

  const consentFormSigned = (): boolean => {
    return consentForm !== null && consentForm.patient_signed && consentForm.surgeon_signed;
  }

  return (
    <>
      {consentForm !== null && consentForm.patient_signed && consentForm.consented
        ? <div className={classes.consentFormExistsText}><Typography paragraph variant="h5">You have signed your consent form</Typography>
          {!consentFormSigned() ? <Typography paragraph variant="body1">You will be able to download your consent form when both parties have signed</Typography> : null}
          <CpButton
            disabled={consentFormLoading || !consentFormSigned()}
            variant="contained"
            color="primary"
            className={classes.downloadButton}
            classes={{containedPrimary: classes.downloadButtonColour}}
            size="large"
            startIcon={<CardMembership/>}
            onClick={previewConsentFormHandler}
          >
            Download Consent Form
          </CpButton>
          {previewPdf === null
            ? <CpContentContainer error={previewError} loading={previewError === null} />
            : <iframe title="Consent Form preview" src={previewPdf} className={classes.previewIframe} />
          }
          </div>
        : <div className={classes.descriptionContainer}>
          <Typography paragraph variant="h5"><strong>{props.step.name}</strong></Typography>
          <Typography paragraph variant="body1">
            Your surgeon will not be able to see this until you sign below.
          </Typography>
          {previewPdf === null
            ? <CpContentContainer error={previewError} loading={previewError === null} />
            : <iframe title="Consent Form preview" src={previewPdf} className={classes.previewIframe} />
          }
          <div className={classes.consentTextContainer}>
            <Typography paragraph variant="body1">
              Your signature must match your name exactly: <strong>{patient?.patient_first_name} {patient?.patient_last_name}</strong>
            </Typography>
          </div>
          <Grid container item xs={12} direction="column" alignItems="center" justify="space-between">
            <CpTextField
              id="login-email"
              disabled={consentFormLoading}
              className={classes.signatureField}
              type="text"
              label="Signature"
              variant="outlined"
              onChange={(e) => signatureChangeHandler(e.target.value)}
              error={getJoiErrorMessage('signature', signatureError) !== null}
              helperText={getJoiErrorMessage('signature', signatureError)}
            />
            <CpButton
              disabled={consentFormLoading}
              variant="contained"
              className={classes.signButton}
              color="primary"
              size="large"
              startIcon={<BorderColor />}
              onClick={submitHandler}
            >
              Sign
            </CpButton>
          </Grid>
          <CpContentContainer error={consentFormError} loading={consentFormLoading} />
        </div>
      }
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionContainer: {
      width: '100%',
      textAlign: 'center',
      padding: '3em',
    },
    consentTextContainer: {
      marginTop: '3.5em',
    },
    buttonContainer: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      width: '100%',
      margin: '2em 0em',
      [theme.breakpoints.down('sm')]: {
        margin: '1em 0',
      },
    },
    downloadButtonColour: {
      backgroundColor: '#26aac7',
        '&:hover': {
        backgroundColor: '#1e889f',
      },
    },
    signatureField: {
      width: '50%',
      minWidth: '200px',
      margin: '1em',
    },
    signButton: {
      padding: '1em 6em',
      margin: '1em'
    },
    consentFormExistsText: {
      padding: '5em 1em',
      textAlign: 'center',
    },
    downloadButton: {
      marginTop: '1em',
      marginBottom: '3em',
    },
    previewIframe: {
      display: 'block',
      height: '100%',
      width: '100%',
      minHeight: '600px',
      maxWidth: '750px',
      margin: 'auto',
    },
  }),
);

export default SignConsentFormProcess;
