import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {FormHelperText, Select, Typography} from "@material-ui/core";
import {ConsentStep} from "../../../../api/Types";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CpSelectContainer from "../../../UI/CpSelectContainer";
import {getJoiErrorMessage} from "../../../../errors/joiErrorHandler";
import CpButton from "../../../UI/CpButton";
import {ExitToApp} from "@material-ui/icons";
import CpForm from "../../../UI/CpForm";
import {ValidationErrorItem} from "joi";
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import CpDatePicker from "../../../UI/CpDatePicker";
import moment, {Moment} from "moment/moment";
import {userDetailsStepSchema} from "../../../../schemas/patientSchemas";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {updatePatientAccount} from "../../../../store/patient/patientAuthSlice";
import CpContentContainer from "../../../UI/CpContentContainer";
import {RootState} from "../../../../store/store";
import {navigateToLatestStep} from "../../../../store/patient/patientConsentSlice";

interface UserDetailsProcessProps {
  step: ConsentStep
}

const UserDetailsProcess: FunctionComponent<UserDetailsProcessProps> = props => {
  const classes = useStyles();
  const patient = useSelector((state: RootState) => state.patient.auth.account.data);
  const error = useSelector((state: RootState) => state.patient.auth.account.error);
  const loading = useSelector((state: RootState) => state.patient.auth.account.loading);
  const [joiErrors, setJoiErrors] = React.useState<ValidationErrorItem[]|null>(null);
  const [gender, setGender] = React.useState<string|null>(patient?.patient_gender ?? null);
  const [laterality, setLaterality] = React.useState<string|null>(patient?.patient_laterality ?? null);
  const [dateOfBirth, setDateOfBirth] = React.useState<Moment|null>(patient?.patient_date_of_birth ? moment(patient?.patient_date_of_birth) : null);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const dateFormat = 'DD-MM-YYYY';

  const submitHandler = async () => {
    try {
      setJoiErrors(null)
      await userDetailsStepSchema.validateAsync(
        {gender: gender, laterality: laterality, date_of_birth: dateOfBirth?.toDate()},
        { abortEarly: false }
      );

      await dispatch(updatePatientAccount({
        gender: gender,
        laterality: laterality,
        date_of_birth: dateOfBirth?.format(),
      }));

      await dispatch(navigateToLatestStep(params.id));
    } catch (e: any) {
      if (e.status === undefined) {
        setJoiErrors(e.details);
      }
    }
  }

  const dateChangeHandler = (date: MaterialUiPickersDate) => {
    setDateOfBirth(date);
  };

  const lateralityChangeHandler = (laterality: any) => {
    setLaterality(laterality);
  }

  const genderChangeHandler = (gender: any) => {
    setGender(gender);
  }

  return (
    <div className={classes.root}>
      <Typography paragraph variant="h5" align="center"><strong>{props.step.name}</strong></Typography>
      <Typography paragraph variant="h6" align="center">
        Please provide further details before continuing to your consent form
      </Typography>
      <CpForm onSubmit={submitHandler} name="Update Details">
        <CpSelectContainer className={classes.field} label="Gender" id="user-details-gender">
          <Select
            native
            value={gender ?? ''}
            label="Gender"
            onChange={(e) => genderChangeHandler(e.target.value)}
            error={getJoiErrorMessage('gender', joiErrors) !== null}
            inputProps={{name: 'gender', id: 'user-details-gender'}}
          >
            <option aria-label="Please Select" value="" />
            <option aria-label="Female" value="Female">Female</option>
            <option aria-label="Male" value="Male">Male</option>
            <option aria-label="Gender Diverse" value="Gender Diverse">Gender Diverse</option>
          </Select>
          <FormHelperText style={{color: 'red'}}>{getJoiErrorMessage('gender', joiErrors)}</FormHelperText>
        </CpSelectContainer>
        <CpSelectContainer className={classes.field} label="Laterality" id="user-details-laterality">
          <Select
            native
            value={laterality ?? ''}
            label="Laterality"
            onChange={(e) => lateralityChangeHandler(e.target.value)}
            error={getJoiErrorMessage('laterality', joiErrors) !== null}
            inputProps={{name: 'laterality', id: 'user-details-laterality'}}
          >
            <option aria-label="Please Select" value="" />
            <option aria-label="Left" value="Left">Left</option>
            <option aria-label="Right" value="Right">Right</option>
            <option aria-label="Bilateral" value="Bilateral">Bilateral</option>
          </Select>
          <FormHelperText style={{color: 'red'}}>{getJoiErrorMessage('laterality', joiErrors)}</FormHelperText>
        </CpSelectContainer>
        <div className={classes.field}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CpDatePicker
              margin="normal"
              id="user-details-date-of-birth"
              label="Date of Birth (DD-MM-YYYY)"
              format={dateFormat}
              style={{width: '100%', margin: 0}}
              inputVariant="outlined"
              value={dateOfBirth}
              onChange={dateChangeHandler}
              openTo="year"
              KeyboardButtonProps={{
                'aria-label': 'change date of birth',
              }}
            />
            <FormHelperText style={{color: 'red', marginLeft: '1.2em'}}>{getJoiErrorMessage('date_of_birth', joiErrors)}</FormHelperText>
          </MuiPickersUtilsProvider>
        </div>
        <CpButton
          type="submit"
          className={classes.field}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<ExitToApp />}
          data-testid="register-button"
        >
          Update
        </CpButton>
        <CpContentContainer error={error} loading={loading} />
      </CpForm>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '2em 0',
      padding: '1em 15em',
      [theme.breakpoints.down('md')]: {
        padding: '1em 10em',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '1em',
      },
    },
    field: {
      margin: '1.2em',
    },
  }),
);

export default UserDetailsProcess;
