import React from "react";
import {Checkbox, CheckboxProps} from "@material-ui/core";
import {withStyles, createStyles, Theme} from "@material-ui/core/styles";

const CpCheckbox = withStyles((theme: Theme) => createStyles({
  root: {
    color: theme.palette.primary.light,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export default CpCheckbox;
