import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import PatientConsentSummary from "../../components/Patient/PatientConsentSummary";
import CpBreadcrumbs from "../../components/UI/CpBreadcrumbs";
import {useParams} from "react-router-dom";

const PatientConsentSummaryPage: FunctionComponent<{}> = props => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();

  const breadcrumbs = [
    {to: '/patient/home', name: 'Home', click: true},
    {to: `/patient/surgery/${params.id}`, name: 'Summary', click: false}
  ];

  return (
    <div className={classes.container}>
      <CpBreadcrumbs breadcrumbs={breadcrumbs} />
      <div className={classes.surgeryContainer}>
        <PatientConsentSummary surgeryId={params.id} />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    width: '85%',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    margin: 'auto',
    paddingTop: '0.3em',
  },
  surgeryContainer: {
    marginTop: '1em',
    padding: '10px 10px 20px 10px',
    backgroundColor: 'white',
    boxShadow: '2px 2px 5px 5px #cccccc',
  },
})));

export default PatientConsentSummaryPage;
