import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Typography} from "@material-ui/core";
import {RootState} from "../../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {findCertificate} from "../../../store/surgeon/surgeonCertificateSlice";
import clsx from "clsx";
import {CardMembership} from "@material-ui/icons";
import {Certificate} from "../../../api/Types";

type CertificateAvatarProps = {
  surgery_id: string
  className?: string
  onClick?: (certificate: Certificate|null) => void
}

const CertificateAvatar: FunctionComponent<CertificateAvatarProps> = props => {
  const classes = useStyles();
  const [certificate, setCertificate] = React.useState<Certificate|null>(null);
  const loading = useSelector((state: RootState) => state.surgeon.certificate.current.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    dispatch(findCertificate(props.surgery_id)).then(res => {
      if (mounted) {
        setCertificate(res.data.certificate)
      }
    }).catch(e => { return }); // ignore error

    return () => { mounted = false };
    // eslint-disable-next-line
  }, [props.surgery_id]);

  if (loading) {
    return null;
  }

  const onClickHandler = () => {
    if (props.onClick === undefined) {
      return;
    }

    props.onClick(certificate);
  }

  return <div onClick={onClickHandler} className={[classes.root, props.className].join(' ')}>
    <Avatar className={clsx(classes.avatarContainer, {
      [classes.complete]: certificate !== null,
      [classes.incomplete]: certificate === null,
    })}><CardMembership fontSize="small" /></Avatar>
    <Typography variant="body2"><strong>{certificate !== null
      ? 'Certificate complete'
      : 'Certificate incomplete'
    }</strong></Typography>
  </div>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarContainer: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },
  complete: {
    backgroundColor: '#009A49',
  },
  incomplete: {
    backgroundColor: '#cccccc',
  },
})));

export default CertificateAvatar;
