import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Chip, Divider, Grid, Typography} from "@material-ui/core";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {getAllSurgeonConsentForms} from "../../store/surgeon/surgeonConsentFormSlice";
import {authClient} from "../../api/Container";
import ConsentFormTableList from "../../components/Surgeon/ConsentFormTableList";
import CpContentContainer from "../../components/UI/CpContentContainer";
import {getListOfSurgeonPatients} from "../../store/surgeon/surgeonAuthSlice";
import {getSurgeries} from "../../store/surgeon/surgeonSurgerySlice";
import {linkConsentFormSurgeries} from "../../app/helpers";
import {ConsentFormSurgery} from "../../api/Types";
import {Check} from "@material-ui/icons";

const SurgeonConsentFormsPage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.surgeon.consentForm.all.error);
  const loading = useSelector((state: RootState) => state.surgeon.consentForm.all.loading);
  const consentForms = useSelector((state: RootState) => state.surgeon.consentForm.all.data);
  const patients = useSelector((state: RootState) => state.surgeon.auth.patients.data);
  const surgeries = useSelector((state: RootState) => state.surgeon.surgery.surgeries.data);
  const [filterIndex, setFilterIndex] = React.useState<number>(0);
  const [consentFormFiltered, setConsentFormFiltered] = React.useState<null|ConsentFormSurgery[]>(null);

  useEffect(() => {
    const surgeonId = authClient.getSurgeonId();
    if (surgeonId === null) {
      return;
    }

    if (consentForms === null) {
      dispatch(getAllSurgeonConsentForms());
    }

    if (patients === null) {
      dispatch(getListOfSurgeonPatients());
    }

    if (surgeries === null) {
      dispatch(getSurgeries());
    }
    // eslint-disable-next-line
  }, []);

  const getConsentSurgeries = (): ConsentFormSurgery[] => {
    return linkConsentFormSurgeries(patients ?? [], surgeries ?? [], consentForms ?? []);
  }

  const filterAllConsentForms = () => {
    setConsentFormFiltered(null);
    setFilterIndex(0);
  }

  const filterSurgeonRequireSigning = () => {
    let consentFormSurgeries = getConsentSurgeries().filter((consent) => {
      return !consent.form.surgeon_signed;
    });

    setConsentFormFiltered(consentFormSurgeries);
    setFilterIndex(1);
  }

  const filterPatientRequireSigning = () => {
    let consentFormSurgeries = getConsentSurgeries().filter((consent) => {
      return !consent.form.patient_signed;
    });

    setConsentFormFiltered(consentFormSurgeries);
    setFilterIndex(2);
  }

  const filterCompletedConsentForms = () => {
    let consentFormSurgeries = getConsentSurgeries().filter((consent) => {
      return consent.form.patient_signed && consent.form.surgeon_signed;
    });

    setConsentFormFiltered(consentFormSurgeries);
    setFilterIndex(3);
  }

  const consentFormSurgeries = consentFormFiltered === null ? getConsentSurgeries() : consentFormFiltered;

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Your Consent Forms</Typography>
        <Divider />
      </div>
      <Grid item xs={12}>
        <Grid container className={classes.quickButtonContainer}>
          <Typography paragraph>Filters: </Typography>
          <Chip icon={filterIndex === 0 ? <Check /> : undefined} label="All Consent Forms" onClick={filterAllConsentForms} color="primary" className={classes.filters} />
          <Chip icon={filterIndex === 1 ? <Check /> : undefined} label="Requires Surgeon Signature" onClick={filterSurgeonRequireSigning} color="primary" className={classes.filters} />
          <Chip icon={filterIndex === 2 ? <Check /> : undefined} label="Requires Patient Signature" onClick={filterPatientRequireSigning} color="primary" className={classes.filters} />
          <Chip icon={filterIndex === 3 ? <Check /> : undefined} label="Completed Consent Forms" onClick={filterCompletedConsentForms} color="primary" className={classes.filters} />
        </Grid>
        <CpContentContainer error={error} loading={loading}>
          <ConsentFormTableList title="Consent Forms" consentSurgeries={consentFormSurgeries ?? []} />
        </CpContentContainer>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  quickButtonContainer: {
    padding: 0,
    marginBottom: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  filters: {
    cursor: 'pointer',
    marginLeft: '1em',
    marginBottom: '16px',
    padding: '5px',
  },
})));

export default SurgeonConsentFormsPage;
