import React, {FunctionComponent} from 'react';
import {Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpBreadcrumbs from "../../components/UI/CpBreadcrumbs";
import {Link} from "react-router-dom";

const PatientInformationPage: FunctionComponent<{}> = () => {
  const classes = useStyles();

  const breadcrumbs = [
    {to: '/patient/home', name: 'Home', click: true},
    {to: '/patient/information/', name: 'Learn', click: false},
  ];

  return (
    <div className={classes.container}>
      <CpBreadcrumbs className={classes.breadcrumbs} breadcrumbs={breadcrumbs} />
      <Typography paragraph variant="h4" align="center">
        Welcome to Consent Plus
      </Typography>
      <div className={classes.textContainer}>
        <Typography variant="h5" align="justify" paragraph>
          Who are we?
        </Typography>
        <Typography variant="body1" align="justify" paragraph>
          Consent Plus is a new process for dealing with consent in the UK healthcare industry. It is interactive as we understand your surgery is specific to you. You will enter the operation you are having (or your surgeon may have already done this for you) and you will need to complete the following process:
        </Typography>
        <Typography variant="h5" align="justify" paragraph>
          The process
        </Typography>
        <Typography variant="body1" align="justify" paragraph>
          If you are considering surgery or you've already decided on surgery, then you're in the right place! The process requires the patient to watch the educational resources and complete a couple of assessments so the surgeon is aware of what you do / don't understand.
          These assessments also allow us to make this process bespoke to you so you get the most out of our system. When you have completed the assessments and learning resources, you will be able to receive your consent certificate which you can show your surgeon before surgery is decided. If surgery is the route you want to go for, then you must fill in extra information and sign the consent form before you proceed with surgery.
          If you started the consent process then you <strong>must</strong> get verification from the surgeon that you have completed the correct process. It's possible for them to add more details regarding your surgery too. If the surgeon has setup the process for you, then you will be able to verify and sign straight away.
        </Typography>
        <ul>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Start Your Consent Process</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              If you have already discussed surgery with your surgeon and decided that surgery is the best option, please ask them to create your consent process for you. This will allow you to complete the process much faster and sign the form without having to wait for surgeon verification.
              <br /><br /> If you are here to understand more about your surgery or you're contemplating the risks associated with your surgery, then you should create your own consent process.
              You can do this by accessing the <Link to="/patient/home">homepage</Link> and clicking <strong>Start your Consent Process</strong> which will take you to the <Link to="/patient/consent-process/create">consent process creation page</Link>.
              <br /><br />Please complete the form by filling in the operation you are having and click <strong>Create</strong>. You will be able to save your progress throughout the duration of this process.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Information</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              A very basic set of questions you need to complete so we can understand what you want to get out of the process. From here, we will tailor the process specifically to your needs.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Learning</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              This section will provide all the learning resources you need to understand the risks, benefits and complications associated with your surgery. You <strong>must</strong> complete all learning resources before continuing to the next section.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Assessment</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              The assessment will help the surgeon understand what you learned in the previous section. Our system will highlight areas that need focus, so when you go for your consultation with the surgeon they are able to give you more information in the areas you don't understand and "fill in the missing gaps".
              Remember, there are no wrong answers in this assessment so please complete it honestly and don't worry; you're in good hands.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Notes</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              This is not a mandatory part of the assessment, but once you have completed the assessment you will be able to create notes for your surgery. If you have any questions about your operation then please write them in here, these notes will be visible to the surgeon. Please be aware they are <strong>not</strong> obliged to respond, you will be able to view the notes when you go for your consultation. Your <strong>first</strong> note will appear on your certificate so please make it count.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Certificate</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              This section is where you will receive your certificate outlining the details of your surgery along with the completed assessment and learning resources. This is <strong>not</strong> a consent form and has no legal standing.
              It is for the Patient's records to prove what they have completed <strong>before</strong> signing the Consent Form. This document also helps the surgeon identify missing information and provides a foundation for discussions on whether surgery is even right for the patient.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Consent Form</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              This section is where you will sign your consent form <strong>if you want to proceed with surgery</strong>. If you created the consent process yourself then you must wait for surgeon verification before you are able to sign. You will be given a unique code that you must give to your surgeon so they can view your progress.
              If the surgeon created the consent process for you, then you will be able to sign the form straight away and your surgeon will be notified when it is complete. This will form as a legal document for you and the surgeon, so it must be completed before surgery takes place.
              <br />If you have learnt all you need and your decision is <strong>not</strong> to proceed with surgery then you have completed this process and you still have your certificate to prove that.
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    padding: '1em 0.5em',
    marginBottom: '5em',
  },
  textContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '1em 5em',
    },
  },
  breadcrumbs: {
    margin: '0.5em 1.9em',
  },
})));

export default PatientInformationPage;
