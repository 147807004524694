import React, {PureComponent} from 'react';
import CpTextField from "../../components/UI/CpTextField";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import {ExitToApp} from "@material-ui/icons";
import CpForm, {CpFormState} from "../UI/CpForm";
import {surgeonRegisterSchema} from "../../schemas/publicSchemas";
import CpButton from "../UI/CpButton";
import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {login, registerSurgeon} from "../../store/public/publicAuthSlice";
import CpLoading from "../UI/CpLoading";
import {FormHelperText, Select} from "@material-ui/core";
import CpSelectContainer from "../UI/CpSelectContainer";
import {formatMobileNumber} from "../../app/helpers";
import {SurgeonStatus} from "../../api/Types";

interface SurgeonRegisterFormProps extends WithStyles, RouteComponentProps {
  registerSurgeon: typeof registerSurgeon;
  login: typeof login;
  loading: boolean;
}

interface SurgeonRegisterFormState extends CpFormState {}

class SurgeonRegisterForm extends PureComponent<SurgeonRegisterFormProps, SurgeonRegisterFormState> {
  state: SurgeonRegisterFormState = {
    fields: {
      title: null,
      other_title: null,
      first_name: null,
      last_name: null,
      gmc_number: null,
      mobile_number: null,
      email: null,
      password: null,
      confirmPassword: null,
    },
    errors: null,
  }

  submitHandler = async (e: React.FormEvent) => {
    this.setState({errors: []});
    const fields = {...this.state.fields};
    fields.title = fields.title !== 'Other' ? fields.title : fields.other_title;
    delete fields.other_title;

    fields.mobile_number = formatMobileNumber(fields.mobile_number);

    try {
      await surgeonRegisterSchema.validateAsync(
        fields,
        { abortEarly: false }
      );

      await this.props.registerSurgeon({
        surgeon_title: fields.title ?? '',
        surgeon_first_name: fields.first_name ?? '',
        surgeon_last_name: fields.last_name ?? '',
        surgeon_gmc_number: fields.gmc_number ?? '',
        surgeon_mobile_number: fields.mobile_number,
        surgeon_email: fields.email ?? '',
        surgeon_password: fields.password,
        surgeon_status: SurgeonStatus.NOT_VERIFIED,
      });

      await this.props.login({
        email: fields.email ?? '',
        password: fields.password ?? '',
      });

      this.props.history.push('/surgeon/dashboard');
    } catch (e: any) {
      if (e.status === undefined) {
        this.setState({errors: e.details});
      }
    }
  }

  inputChangeHandler = (inputName: string, value: any) => {
    const fields = {...this.state.fields};
    fields[inputName] = value;

    this.setState({fields: fields});
  }

  render() {
    const { classes } = this.props;

    return (
      <CpForm onSubmit={this.submitHandler} name="Surgeon Portal Registration">
        <CpSelectContainer className={classes.field} label="Title (optional)" id="register-title-select">
          <Select
            native
            value={this.state.fields.title ?? ''}
            label="Title (optional)"
            onChange={(e) => this.inputChangeHandler('title', e.target.value)}
            error={getJoiErrorMessage('title', this.state.errors) !== null}
            inputProps={{name: 'title', id: 'register-title-select'}}
          >
            <option aria-label="Please Select" value="" />
            <option aria-label="Prof" value="Prof">Prof</option>
            <option aria-label="Dr" value="Dr">Dr</option>
            <option aria-label="Mr" value="Mr">Mr</option>
            <option aria-label="Mr" value="Miss">Miss</option>
            <option aria-label="Mr" value="Mrs">Mrs</option>
            <option aria-label="Mr" value="Ms">Ms</option>
            <option aria-label="Mr" value="Ms">Mx</option>
            <option aria-label="Other" value="Other">Other</option>
          </Select>
          <FormHelperText>{getJoiErrorMessage('title', this.state.errors)}</FormHelperText>
        </CpSelectContainer>
        {this.state.fields.title === 'Other'
          ? <CpTextField
            className={classes.field}
            type="text"
            label="Other Title"
            variant="outlined"
            onChange={(e) => this.inputChangeHandler('other_title', e.target.value)}
            error={getJoiErrorMessage('title', this.state.errors) !== null}
            helperText={getJoiErrorMessage('title', this.state.errors)}
          />
          : null
        }
        <CpTextField
          id='register-first-name'
          className={classes.field}
          type="text"
          label="First Name"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('first_name', e.target.value)}
          error={getJoiErrorMessage('first_name', this.state.errors) !== null}
          helperText={getJoiErrorMessage('first_name', this.state.errors)}
        />
        <CpTextField
          id='register-last-name'
          className={classes.field}
          type="text"
          label="Last Name"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('last_name', e.target.value)}
          error={getJoiErrorMessage('last_name', this.state.errors) !== null}
          helperText={getJoiErrorMessage('last_name', this.state.errors)}
        />
        <CpTextField
          id='register-gmc-number'
          className={classes.field}
          type="text"
          label="GMC Number"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('gmc_number', e.target.value)}
          error={getJoiErrorMessage('gmc_number', this.state.errors) !== null}
          helperText={getJoiErrorMessage('gmc_number', this.state.errors)}
        />
        <CpTextField
          id='register-mobile-number'
          className={classes.field}
          type="text"
          label="Mobile Number (optional)"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('mobile_number', e.target.value)}
          error={getJoiErrorMessage('mobile_number', this.state.errors) !== null}
          helperText={getJoiErrorMessage('mobile_number', this.state.errors)}
        />
        <CpTextField
          id='register-email'
          className={classes.field}
          type="email"
          label="Email"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('email', e.target.value)}
          error={getJoiErrorMessage('email', this.state.errors) !== null}
          helperText={getJoiErrorMessage('email', this.state.errors)}
        />
        <CpTextField
          id='register-password'
          className={classes.field}
          type="password"
          label="Password"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('password', e.target.value)}
          error={getJoiErrorMessage('password', this.state.errors) !== null}
          helperText={getJoiErrorMessage('password', this.state.errors)}
        />
        <CpTextField
          id='register-confirm-password'
          className={classes.field}
          type="password"
          label="Confirm Password"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('confirmPassword', e.target.value)}
          error={getJoiErrorMessage('confirmPassword', this.state.errors) !== null}
          helperText={getJoiErrorMessage('confirmPassword', this.state.errors)}
        />
        <CpButton
          type="submit"
          className={classes.field}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<ExitToApp />}
          data-testid="register-button"
        >
          Register
        </CpButton>
        <CpLoading loading={this.props.loading} />
      </CpForm>
    )
  }
}

const styles = (theme: Theme) => createStyles({
  field: {
    margin: '1em 1.5em',
  },
  label: {
    color: theme.palette.text.primary,
    opacity: 0.8,
  },
});

const mapStateToProps = (state: any) => {
  return {
    loading: state.public.auth.loading,
  };
};

const actionCreators = { registerSurgeon, login };

export default connect( mapStateToProps, actionCreators )(withStyles(styles)(withRouter(SurgeonRegisterForm)));
