import React, {FunctionComponent} from 'react';
import {Grid, Typography} from "@material-ui/core";
import SurgeonRegisterForm from "../../components/Public/SurgeonRegisterForm";
import {Link} from "react-router-dom";

const SurgeonRegisterPage: FunctionComponent<{}> = () => {
  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={9} md={6}>
        <Typography paragraph>Got here accidentally? <Link to="/home">Go home</Link></Typography>
        <Typography>Already have an account? <Link to="/surgeon/login">Please login</Link></Typography>
        <SurgeonRegisterForm />
      </Grid>
    </Grid>
  )
}

export default SurgeonRegisterPage;
