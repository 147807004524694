import {combineReducers} from "@reduxjs/toolkit";
import SurgeonAssessmentReducer from './surgeonAssessmentSlice';
import SurgeonAuthReducer from './surgeonAuthSlice';
import SurgeonCertificateReducer from './surgeonCertificateSlice';
import SurgeonConsentFormReducer from './surgeonConsentFormSlice';
import SurgeonSurgeryReducer from './surgeonSurgerySlice';
import SurgeonNoteReducer from './surgeonNoteSlice';

const surgeonReducers = () => combineReducers({
  assessment: SurgeonAssessmentReducer,
  auth: SurgeonAuthReducer,
  certificate: SurgeonCertificateReducer,
  consentForm: SurgeonConsentFormReducer,
  surgery: SurgeonSurgeryReducer,
  note: SurgeonNoteReducer,
});

export default surgeonReducers;
