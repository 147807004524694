import React, {FunctionComponent} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from '@material-ui/icons/Menu';
import {IconButton, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ENVIRONMENT} from "../../app/constants";

type CpAppBarProps = {
  className?: string,
  menuIconClick?: () => void,
}

const CpAppBar: FunctionComponent<CpAppBarProps> = (props) => {
  const classes = useStyles();

  let staging = null
  if(ENVIRONMENT === "staging") {
    staging = <Typography className={classes.staging} noWrap>Testing</Typography>
  }

  let menubar = null;
  if (props.menuIconClick !== undefined) {
    menubar = <Hidden smUp implementation="css">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={props.menuIconClick}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
    </Hidden>
  }

  return (
    <div className={classes.appBar}>
      <Toolbar classes={{root: props.className}}>
        {menubar}
        {staging}
        {props.children}
      </Toolbar>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.primary.main,
      height: '64px',
      lineHeight: '64px',
      color: theme.palette.text.secondary,
      zIndex: theme.zIndex.drawer + 1
    },
    staging: {
      color: 'red',
      zIndex: theme.zIndex.drawer + 1,
      fontSize: '1.5em',
      fontWeight: 600,
      width: '100px',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
);

export default CpAppBar;
