import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Divider, Grid, Typography} from "@material-ui/core";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {getListOfSurgeonPatients} from "../../store/surgeon/surgeonAuthSlice";
import SurgeonPatientsTableList from "../../components/Surgeon/SurgeonPatientsTableList";
import SurgeonPatientsYearlyBarChart from "../../components/Surgeon/SurgeonPatientsYearlyBarChart";

const SurgeonPatients: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.surgeon.auth.patients.error);
  const patientsNotExist = useSelector((state: RootState) => state.surgeon.auth.patients.data === null);

  useEffect(() => {
    if (patientsNotExist && error === null) {
      dispatch(getListOfSurgeonPatients())
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Your Patients</Typography>
        <Divider />
      </div>
      <Grid container>
        <Grid item xs={12} md={7}>
          <SurgeonPatientsTableList />
        </Grid>
        <Grid item xs={12} md={5}>
          <SurgeonPatientsYearlyBarChart />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  content: {
    paddingLeft: '1em',
  },
  chart: {
    padding: '1em',
    minHeight: '500px',
  },
  section: {
    marginRight: '1em',
    marginBottom: '1em',
  },
  chip: {
    marginTop: '0.8em',
    marginRight: '0.5em',
  },
})));

export default SurgeonPatients;
