import {createSlice} from "@reduxjs/toolkit";
import {authClient} from "../../api/Container";
import {Patient, Surgeon, UpdatePatient} from "../../api/Types";
import {Error, Loading} from "../store";

const initialState: patientAuthState = {
  account: {
    error: null,
    loading: false,
    data: null
  },
  currentSurgeon: {
    error: null,
    loading: false,
    data: null
  },
  surgeons: {
    error: null,
    loading: false,
    data: null
  },
}

export const patientAuthSlice = createSlice({
  name: 'patientAuth',
  initialState,
  reducers: {
    setPatient: (state, action) => {
      state.account.data = action.payload.account;
      state.account.error = null;
      state.account.loading = false;
    },
    setSurgeon: (state, action) => {
      state.currentSurgeon.data = action.payload.surgeon;
      state.currentSurgeon.error = null;
      state.currentSurgeon.loading = false;
    },
    setSurgeons: (state, action) => {
      state.surgeons.data = action.payload.surgeons;
      state.surgeons.error = null;
      state.surgeons.loading = false;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default patientAuthSlice.reducer;

// @ts-ignore as this is a middleware
export const initialisePatientAccount = (): Promise<any> => async dispatch => {
  dispatch(patientAuthSlice.actions.setStart({index: 'account'}))

  try {
    const response = await authClient.getPatient(authClient.getPatientId());
    const patient = response.data.patient;
    dispatch(patientAuthSlice.actions.setPatient({account: patient}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientAuthSlice.actions.setEnd({index: 'account', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const updatePatientAccount = (patient: UpdatePatient): Promise<any> => async dispatch => {
  dispatch(patientAuthSlice.actions.setStart({index: 'account'}))

  try {
    const response = await authClient.updatePatient(authClient.getPatientId(), patient);
    await dispatch(initialisePatientAccount());
    dispatch(patientAuthSlice.actions.setEnd({index: 'account', error: null}))
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientAuthSlice.actions.setEnd({index: 'account', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getCurrentSurgeon = (surgeonId: string): Promise<any> => async dispatch => {
  dispatch(patientAuthSlice.actions.setStart({index: 'currentSurgeon'}))

  try {
    const response = await authClient.getSurgeon(surgeonId);
    dispatch(patientAuthSlice.actions.setSurgeon({surgeon: response.data.surgeon}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientAuthSlice.actions.setEnd({index: 'currentSurgeon', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getPatientSurgeons = (): Promise<any> => async dispatch => {
  dispatch(patientAuthSlice.actions.setStart({index: 'surgeons'}))

  try {
    const response = await authClient.getListOfSurgeons();
    dispatch(patientAuthSlice.actions.setSurgeons({surgeons: response.data.surgeons}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientAuthSlice.actions.setEnd({index: 'surgeons', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

type patientAuthState = {
  account: {
    error: Error
    loading: Loading
    data: Patient|null
  },
  currentSurgeon: {
    error: Error
    loading: Loading
    data: Surgeon|null
  }
  surgeons: {
    error: Error
    loading: Loading
    data: Surgeon[]|null
  }
}
