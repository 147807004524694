import React, {FunctionComponent} from 'react';
import {Grid, Hidden, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {LocalHospital, Person} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import background from '../../resources/signing-paper.jpg';
import backgroundMobile from '../../resources/signing-paper-mobile.jpg';
import consentPlusTeam from '../../resources/consent_plus_team.png';

const HomePage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const history = useHistory();

  const navBarHandler = (route: string) => {
    history.push(route);
  }

  return (
    <Grid container>
      <Grid className={classes.imageContainer} item xs={12}>
        <Typography className={classes.headerText} variant="h2" align="center" paragraph>
          <strong>Welcome to Consent Plus</strong>
        </Typography>
        <Typography className={classes.subHeaderText} variant="h4" align="center" paragraph>
          Gain more understanding of your surgery
        </Typography>
        <Grid direction="row" justify="center" alignItems="center" spacing={0} className={classes.homeLinkContainer} item container xs={12}>
          <div className={`${classes.homeLink} ${classes.patientLink}`} onClick={() => navBarHandler('/patient/login')}>
            <Person className={classes.homeLinkIcon} />
            <Typography className={classes.homeLinkText}>Patient Portal <span className={classes.chevron}>&rsaquo;</span></Typography>
          </div>
          <div className={`${classes.homeLink} ${classes.surgeonLink}`} onClick={() => navBarHandler('/surgeon/login')}>
            <LocalHospital className={classes.homeLinkIcon} />
            <Typography className={classes.homeLinkText}>Surgeon Portal <span className={classes.chevron}>&rsaquo;</span></Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="center" className={classes.container}>
        <Typography variant="h4" align="center" paragraph>
          About us
        </Typography>
        <Typography className={classes.aboutText} variant="h6" align="center" paragraph>
          With Consent Plus you can learn about the risks and benefits associated with your surgery.
          <br />Please watch the video below to gain more understanding about the services we offer.
        </Typography>
        <Hidden smDown>
          <Grid container item md={5} lg={6} justify="center" >
            <img src={consentPlusTeam} className={classes.aboutImage} alt="Consent Plus Team" />
          </Grid>
        </Hidden>
        <Grid container item md={7} lg={6} justify="center">
          <iframe src="https://player.vimeo.com/video/207013674?title=0&byline=0&portrait=0"
                  title="Consent Plus Welcome Video" width="640" height="360" frameBorder="0" allowFullScreen />
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.familyContainer}>
        <Typography variant="h6" color="textPrimary" paragraph>
          <strong>For you and your family</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          You can register by clicking 'Register as a Patient' below. Please watch the videos for your operation as many times as you wish. This is a good opportunity for family members, neighbours and friends to have a greater understanding prior to your surgery!
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          This program has been designed to give you all the information you need to learn about your surgery, its benefits and potential complications. This will enable your Surgical team and you to have a more in depth discussion specific to you. We appreciate that not everybody may have access to the internet. Access to the website may be provided at your follow-up appointment.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          You will be seen in a pre-assessment / Joint School / consent clinic to answer any questions you may have.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          When you have finished you will be awarded a certificate; (you can print this for your records). Please ensure to keep a record of your certificate. There is no pass or fail!
        </Typography>
      </Grid>
      <Grid container item xs={12} className={classes.registerContainer}>
        <Typography className={classes.getStartedText} variant="h5" align="center" paragraph>
          Give it a try, it's easy to get started!
        </Typography>
        <Grid container item xs={12} direction="row" justify="center" alignItems="center" className={classes.registerLinkContainer}>
          <div className={`${classes.registerLink} ${classes.patientLink}`} onClick={() => navBarHandler('/patient/register')}>
            <Typography className={classes.homeLinkText}>Register as a Patient</Typography>
          </div>
          <div className={`${classes.registerLink} ${classes.surgeonLink}`} onClick={() => navBarHandler('/surgeon/register')}>
            <Typography className={classes.homeLinkText}>Register as a Surgeon</Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    padding: '2em',
    [theme.breakpoints.up('sm')]: {
      padding: '2em 5em',
    },
  },
  headerText: {
    margin: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  subHeaderText: {
    margin: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem',
    },
  },
  imageContainer: {
    backgroundImage: `url(${background})`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${backgroundMobile})`,
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minWidth: '100%',
    minHeight: '100%',
    padding: '2em',
    color: '#fff'
  },
  homeLinkContainer: {
    marginTop: '9em',
    marginBottom: '3em',
    padding: '0.5em',
  },
  homeLink: {
    height: '10em',
    width: '15em',
    textAlign: 'center',
    padding: '1.5em 0',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  surgeonLink: {
    backgroundColor: '#23bbbb',
  },
  patientLink: {
    backgroundColor: theme.palette.primary.main,
  },
  homeLinkIcon: {
    fontSize: 50,
    verticalAlign: 'middle',
  },
  homeLinkText: {
    fontSize: 20,
  },
  chevron: {
    position: 'relative',
    top: '3px',
    fontSize: 38,
    fontWeight: 600,
    marginLeft: '0.1em',
  },
  videoContainer: {
    padding: '2.5em 0',
  },
  aboutImage: {
    maxWidth: '425px',
    maxHeight: '363px',
    height: 'auto',
    width: 'auto',
  },
  aboutText: {
    display: 'block',
    width: '100%',
    margin: '1em 0 3.5em 0',
  },
  getStartedText: {
    display: 'block',
    width: '100%',
    margin: '1em 0 2em 0',
  },
  registerLink: {
    textAlign: 'center',
    padding: '1.5em',
    cursor: 'pointer',
    margin: '1em 5em',
    width: '20em',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  registerLinkContainer: {
    color: '#fff'
  },
  familyContainer: {
    backgroundColor: '#e6f5f6',
    padding: '2em',
    [theme.breakpoints.up('sm')]: {
      padding: '2em 5em',
    },
  },
  registerContainer: {
    padding: '2em 2em 4em 2em',
    [theme.breakpoints.up('sm')]: {
      padding: '2em 2em 5em 2em',
    },
  },
})));

export default HomePage;
