import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Divider, Grid, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import CpContentContainer from "../UI/CpContentContainer";
import {getCurrentSurgeon} from "../../store/patient/patientAuthSlice";
import {ArrowForward} from "@material-ui/icons";
import CpButton from "../UI/CpButton";
import PatientSummaryFlow from "./PatientSummaryFlow";
import {clearSteps, navigateToLatestStep} from "../../store/patient/patientConsentSlice";
import {useHistory} from "react-router-dom";
import {getFullAssessment} from "../../store/patient/patientAssessmentSlice";
import {getFullResource} from "../../store/patient/patientResourceSlice";
import {findConsentStepById} from "../../entities/ConsentStep";
import {INITIAL_ASSESSMENT_STEP_ID} from "../../app/constants";
import moment from "moment/moment";
import {findCertificate} from "../../store/patient/patientCertificateSlice";
import CpLoading from "../UI/CpLoading";

interface PatientConsentSummaryProps {
  surgeryId: string,
}

const PatientConsentSummary: FunctionComponent<PatientConsentSummaryProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const surgery = useSelector((state: RootState) => state.patient.surgery.current.data);
  const surgeon = useSelector((state: RootState) => state.patient.auth.currentSurgeon.data);
  const error = useSelector((state: RootState) => state.patient.auth.currentSurgeon.error);
  const loading = useSelector((state: RootState) => state.patient.auth.currentSurgeon.loading);
  const resourceError = useSelector((state: RootState) => state.patient.resource.full.error);
  const assessmentError = useSelector((state: RootState) => state.patient.assessment.full.error);
  const activeStep = findConsentStepById(useSelector((state: RootState) => state.patient.consent.activeStep));
  const [initialLoading, setInitialLoading] = React.useState(true);

  useEffect(() => {
    if (surgery === null || surgery.surgery_id !== props.surgeryId) {
      return;
    }

    Promise.all([
      dispatch(getFullAssessment(surgery.surgery_id)),
      dispatch(getFullResource(surgery.surgery_id)),
      dispatch(findCertificate(surgery.surgery_id)),
      dispatch(clearSteps()),
    ]).then(() => {
      dispatch(navigateToLatestStep(surgery.surgery_id)).then(() => {
        setInitialLoading(false);
      });
    }).catch(e => {
      setInitialLoading(false);
    });

    if (surgery.surgeon_id === null) {
      return;
    }

    if (surgeon === null || surgeon.surgeon_id !== surgery.surgeon_id) {
      dispatch(getCurrentSurgeon(surgery.surgeon_id));
    }
    // eslint-disable-next-line
  }, [surgery?.surgery_id]);

  if (surgery === null || surgery.surgery_id !== props.surgeryId) {
    return <CpLoading loading={true} />
  }

  const continueHandler = () => {
    history.push(`/patient/consent-process/${surgery.surgery_id}`);
  }

  const hasError = error ? error : resourceError ? resourceError : assessmentError ? assessmentError : null;

  return (
    <div className={classes.root}>
      <CpContentContainer error={hasError} loading={loading || initialLoading}>
        {surgery.operation_name && !hasError
        ? <Grid container>
            <Grid item xs={12} md={4} className={classes.textContainer}>
              <Typography noWrap paragraph><strong>Operation:</strong> {surgery.operation_name}</Typography>
            </Grid>
            {surgery.hospital_name
              ? <Grid item xs={12} md={5} className={classes.textContainer}>
                <Typography noWrap paragraph><strong>Hospital:</strong> {surgery.hospital_name}</Typography>
              </Grid> : null
            }
            {surgeon
              ? <Grid item xs={12} md={3} className={classes.textContainer}>
                <Typography noWrap paragraph>
                  <strong>Surgeon:</strong> {`${surgeon.surgeon_title ? surgeon.surgeon_title : ''} ${surgeon.surgeon_last_name}`}
                </Typography>
              </Grid> : null
            }
            {surgeon
              ? <Grid item xs={12} className={classes.textContainer}>
                <Typography noWrap paragraph>
                  <strong>Expected Surgery
                    Date:</strong> {surgery.surgery_date ? moment(surgery.surgery_date).format("MMM Do YY") : 'Unknown'}
                </Typography>
              </Grid> : null
            }
            <Divider variant="middle" className={classes.divider} />
            <PatientSummaryFlow />
            <Grid className={classes.footer} container item xs={12} direction="row" justify="center" alignItems="center">
              <CpButton
                className={classes.continueButton}
                disabled={loading}
                variant="contained"
                color="primary"
                size="large"
                endIcon={<ArrowForward />}
                onClick={() => continueHandler()}
              >
                {activeStep.id === INITIAL_ASSESSMENT_STEP_ID && activeStep.completionPercentage(surgery.surgery_id) === 0
                  ? 'Start Assessment'
                  : activeStep.continueButton
                }
              </CpButton>
            </Grid>
          </Grid>
        : null}
      </CpContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      padding: '2em 1em',
      [theme.breakpoints.down('sm')]: {
        padding: '1em 0',
      },
    },
    divider: {
      margin: '1.5em',
      width: '90%',
    },
    textContainer: {
      padding: '0 0.5em',
    },
    sectionContainer: {
      width: '100%',
      padding: '1em',
    },
    footer:{
      marginTop: '2em',
    },
    continueButton: {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '90%',
      },
    }
  }),
);

export default PatientConsentSummary;
