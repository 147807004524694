import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpChart from "../../UI/CpChart";

interface PatientSummaryChartProps {
  name: string
  userCount: number
  totalCount: number
}

const PatientSummaryChart: FunctionComponent<PatientSummaryChartProps> = props => {
  const classes = useStyles();

  const chart = {
    name: props.name,
    data: {
      labels: ["Complete", "Incomplete"],
      datasets: [{
        data: [props.userCount, (props.totalCount - props.userCount)],
        backgroundColor: [
          'rgba(35, 187, 186, 0.6)',
        ]
      }],
    },
    options: {
      legend: { display: false },
      title: {
        display: true,
        text: `${Math.floor(props.userCount / props.totalCount * 100)}% complete`
      },
    },
  };

  return <CpChart
    className={classes.chart}
    id={chart.name}
    type={'doughnut'}
    data={chart.data}
    options={chart.options}
  />
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chart: {
      width: '175px',
    },
  }),
);

export default PatientSummaryChart;
