import React, {FunctionComponent, useState} from 'react';
import {
  Card, Grid, IconButton, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  Typography
} from "@material-ui/core";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import {Surgeon} from "../../api/Types";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpSearch from "../UI/CpSearch";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";

interface SurgeonTableListProps extends LoadingErrorProps {
  surgeons: Surgeon[],
  selected: string|null,
  onSelectSurgeon: (surgeonId: string) => void,
  className?: string,
}

const SurgeonTableList: FunctionComponent<SurgeonTableListProps> = props => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const [searchedSurgeons, setSearchedSurgeons] = useState<Surgeon[]|null>(null);

  const selectSurgeon = (surgeonId: string) => {
    props.onSelectSurgeon(surgeonId);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSearch = (searchText: string) => {
    if (page !== 0) {
      setPage(0);
    }

    const searchList = [...props.surgeons].filter((surgeon) => {
      return `${surgeon.surgeon_title} ${surgeon.surgeon_first_name} ${surgeon.surgeon_last_name} ${surgeon.surgeon_gmc_number}`.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });

    setSearchedSurgeons(searchList);
  };

  const surgeons = searchedSurgeons !== null ? searchedSurgeons : props.surgeons ?? []

  return (
    <Card className={props.className}>
      <Grid container>
        <Grid item xs={12} md={7} lg={5} className={classes.section}>
          <Typography variant="h5">Surgeons</Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={7} className={classes.section}>
          <CpSearch onSearch={onSearch} />
        </Grid>
      </Grid>
      <CpContentContainer error={props.error} loading={props.loading}>
        <TableContainer className={classes.tableSection} component={Paper}>
          <Table aria-label="Latest surgeons table" size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Select</strong></TableCell>
                <TableCell><strong>First Name</strong></TableCell>
                <TableCell><strong>Last Name</strong></TableCell>
                <TableCell><strong>GMC Number</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surgeons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(surgeon => (
                <TableRow onClick={() => selectSurgeon(surgeon.surgeon_id ?? '0')} key={surgeon.surgeon_id}>
                  <TableCell>
                    <IconButton aria-label="view">
                      {props.selected && props.selected === surgeon.surgeon_id
                        ? <CheckBox />
                        : <CheckBoxOutlineBlank />
                      }
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {surgeon.surgeon_first_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {surgeon.surgeon_last_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {surgeon.surgeon_gmc_number}
                  </TableCell>
                </TableRow>
              )) ?? []}
            </TableBody>
          </Table>
        </TableContainer>
      </CpContentContainer>
      {surgeons.length > rowsPerPage
        ? <TablePagination
          component="div"
          count={surgeons.length}
          rowsPerPageOptions={[10]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
        : null
      }
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  section: {
    padding: '1em',
  },
  tableSection: {
    marginTop: '0.5em'
  }
})));


export default SurgeonTableList;
