import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpBreadcrumbs from "../../components/UI/CpBreadcrumbs";
import SelectOperationForm from "../../components/Forms/SelectOperationForm";
import {useDispatch} from "react-redux";
import {getAssessmentByOperation, getResourceByOperation} from "../../store/surgeon/surgeonConsentProcessSlice";
import {QuestionAnswer, ResourceEngagement} from "../../api/Types";
import ResourceContainer from "../../components/Patient/Resource/ResourceContainer";
import QuestionAnswerContainer from "../../components/Patient/Assessment/QuestionAnswerContainer";
import {Paper, Tab, Tabs} from "@material-ui/core";
import {Assignment, VideoLabel} from "@material-ui/icons";

const SurgeonPatientConsentProcessHelpPage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setSubmitLoading] = React.useState(false);
  const [error, setSubmitError] = React.useState<string|null>(null);
  const [assessment, setAssessment] = React.useState<QuestionAnswer[]|null>(null);
  const [resource, setResource] = React.useState<ResourceEngagement[]|null>(null);
  const [value, setValue] = React.useState(0);

  const breadcrumbs = [
    {to: '/surgeon/dashboard', name: 'Surgeon Dashboard', click: true},
    {to: `/surgeon/help`, name: 'Help Page', click: true},
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const submitOperation = async (operation: string) => {
    try {
      setSubmitLoading(true)
      const [assessment, resource] = await Promise.all([
        dispatch(getAssessmentByOperation(operation)),
        dispatch(getResourceByOperation(operation))
      ]);
      setAssessment(assessment.data.questions)
      setResource(resource.data.resources)
      setSubmitLoading(false)
    } catch (e: any) {
      setSubmitLoading(false)
      setSubmitError(e.data.data.errors[0])
    }
  }

  return (
    <div className={classes.container}>
      <CpBreadcrumbs className={classes.breadcrumbs} breadcrumbs={breadcrumbs} />
      <div className={classes.operationContainer}>
        {resource || assessment ? null : <SelectOperationForm onSubmit={submitOperation} error={error} loading={loading} />}
      </div>
      <div className={classes.learningContainer}>
        {assessment && resource
          ? <><Paper square className={classes.tabs}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
            >
              <Tab icon={<VideoLabel />} label="LEARNING RESOURCES" />
              <Tab icon={<Assignment />} label="ASSESSMENT" />
            </Tabs>
          </Paper>
          <div className={classes.tabContent}>
            { value === 0 ? <ResourceContainer resourceEngagements={resource} />
              : value === 1 ? <QuestionAnswerContainer questionAnswers={assessment} />
                : null
            }
          </div></> : null
        }
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    padding: '1em 0.5em',
    marginBottom: '5em',
  },
  breadcrumbs: {
    marginLeft: '5%',
    marginBottom: '2em',
  },
  operationContainer: {
    margin: '1em 15em',
    [theme.breakpoints.down('md')]: {
      margin: '1em 10em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1em',
    },
  },
  learningContainer: {
    padding: '0 2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  tabs: {
    flexGrow: 1,
    maxWidth: 750,
    margin: 'auto',
  },
  tabContent: {
    marginTop: '5em',
  },
})));

export default SurgeonPatientConsentProcessHelpPage;
