import React, {FunctionComponent} from "react";
import {useDispatch} from "react-redux";
import {createSurgery} from "../../store/patient/patientSurgerySlice";
import {useHistory} from "react-router-dom";
import SelectOperationForm from "./SelectOperationForm";
import {authClient} from "../../api/Container";
import CpContentContainer from "../UI/CpContentContainer";
import SelectHospitalForm from "./SelectHospitalForm";
import SelectSurgeonForm from "./SelectSurgeonForm";
import ConfirmSurgery from "./ConfirmSurgery";
import {Typography} from "@material-ui/core";
import {ArrowBack, ArrowForward, NavigateNext} from "@material-ui/icons";
import CpButton from "../UI/CpButton";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Moment} from "moment/moment";
import SelectSurgeryDateForm from "./SelectSurgeryDateForm";
import EmailSurgeonForm from "./EmailSurgeonForm";

const CreateSurgeryForm: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [operation, setOperation] = React.useState<undefined|null|string>(undefined);
  const [surgeon, setSurgeon] = React.useState<undefined|null|string>(undefined);
  const [hospital, setHospital] = React.useState<undefined|null|string>(undefined);
  const [surgeryDate, setSurgeryDate] = React.useState<undefined|null|Moment>(undefined);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string|null>(null);
  const [emailSurgeon, setEmailSurgeon] = React.useState<boolean>(false);

  const operationHandler = (selectedOperation: string) => {
    setOperation(selectedOperation);
  }

  const surgeonHandler = (selectedSurgeon: string) => {
    setSurgeon(selectedSurgeon);
  }

  const emailSurgeonHandler = (findSurgeon: boolean) => {
    setEmailSurgeon(findSurgeon)
  }

  const hospitalHandler = (selectedHospital: string) => {
    setHospital(selectedHospital);
  }

  const surgeryDateHandler = (selectedSurgeryDate: Moment) => {
    setSurgeryDate(selectedSurgeryDate);
  };

  const resetHandler = () => {
    setHospital(undefined);
    setOperation(undefined);
    setSurgeon(undefined);
    setSurgeryDate(undefined);
    setSubmitError(null);
    setSubmitLoading(false);
  }

  const submitHandler = async () => {
    try {
      setSubmitLoading(true);

      if(operation === null || operation === undefined) {
        setSubmitError('Unable to find operation, please try again');
        return;
      }

      const response = await dispatch(createSurgery(authClient.getPatientId() ?? '', operation, surgeon, hospital, surgeryDate));
      setSubmitLoading(false);
      history.push(`/patient/surgery/${response.data.surgery_id}`);
    } catch (e: any) {
      setSubmitLoading(false)
      setSubmitError(e.data.data.errors[0])
    }
  }

  return <>
    {/* OPERATION */}
    {operation === undefined
    ? <><Typography variant="h6" align="center" paragraph>Choose your operation from the list below</Typography>
      <SelectOperationForm onSubmit={operationHandler} /></>
    : null}

    {/* SURGEON */}
    {operation !== undefined && surgeon === undefined
    ? <><CpButton className={classes.skipButton} variant="contained" color="primary" size="large" endIcon={<NavigateNext />} onClick={() => setSurgeon(null)}>Skip Surgeon</CpButton>
      {emailSurgeon
        ? <Typography className={classes.surgeonEmailLink} onClick={() => emailSurgeonHandler(false)} variant="h6" align="center" paragraph><span className={classes.arrow}><ArrowBack /></span> Go back to select your surgeon from our verified list</Typography>
        : <Typography className={classes.surgeonEmailLink} onClick={() => emailSurgeonHandler(true)} variant="h6" align="center" paragraph>If you cannot find your surgeon please provide their email <span className={classes.arrow}><ArrowForward /></span></Typography>
      }
      {emailSurgeon ? <EmailSurgeonForm onComplete={() => setSurgeon(null)} /> : <SelectSurgeonForm onSubmit={surgeonHandler} />}</>
    : null}

    {/* HOSPITAL */}
    {surgeon !== undefined && hospital === undefined
    ? <><CpButton className={classes.skipButton} variant="contained" color="primary" size="large" endIcon={<NavigateNext />} onClick={() => setHospital(null)}>Skip Hospital</CpButton>
      <Typography variant="h6" align="center" paragraph>We recommend you select your hospital, however please use the skip button if unsure.</Typography>
      <SelectHospitalForm onSubmit={hospitalHandler} /></>
    : null}

    {/* SURGERY DATE */}
    {hospital !== undefined && surgeryDate === undefined
    ? <><CpButton className={classes.skipButton} variant="contained" color="primary" size="large" endIcon={<NavigateNext />} onClick={() => setSurgeryDate(null)}>Skip Surgery Date</CpButton>
      <Typography variant="h6" align="center" paragraph>We recommend you select your expected date of surgery, however please use the skip button if unsure.</Typography>
      <SelectSurgeryDateForm onSubmit={surgeryDateHandler} /></>
    : null}
  {/* CONFIRM SURGERY */}
  {operation !== undefined && surgeon !== undefined && hospital !== undefined && surgeryDate !== undefined
    ? <ConfirmSurgery
      operationId={operation ?? ''}
      surgeonId={surgeon ?? ''}
      hospitalId={hospital ?? ''}
      surgeryDate={surgeryDate ?? null}
      onConfirm={submitHandler}
      onCancel={resetHandler}
    />
    : null
  }
    <CpContentContainer error={submitError} loading={submitLoading} />
  </>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  skipButton: {
    width: '50%',
    margin: '0 auto 1.5em auto',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  arrow: {
    position: 'relative',
    top: '5px',
    fontSize: 38,
    fontWeight: 600,
    marginLeft: '0.1em',
  },
  surgeonEmailLink: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
})));

export default CreateSurgeryForm;
