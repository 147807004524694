import React from "react";
import {Radio, RadioProps} from "@material-ui/core";
import {withStyles, createStyles, Theme} from "@material-ui/core/styles";
import {CheckCircle} from "@material-ui/icons";

const CpRadio = withStyles((theme: Theme) => createStyles({
  root: {
    color: theme.palette.primary.light,
  },
  checked: {},
}))((props: RadioProps) => <Radio color="primary" checkedIcon={<CheckCircle />} {...props} />);

export default CpRadio;
