import {combineReducers} from "@reduxjs/toolkit";
import patientAuthReducer from "./patientAuthSlice";
import patientSurgeryReducer from "./patientSurgerySlice";
import patientAssessmentReducer from "./patientAssessmentSlice";
import patientResourceReducer from "./patientResourceSlice";
import patientConsentReducer from "./patientConsentSlice";
import patientNoteReducer from "./patientNoteSlice";
import patientCertificateReducer from "./patientCertificateSlice";
import patientConsentFormReducer from "./patientConsentFormSlice";

const patientReducers = () => combineReducers({
  auth: patientAuthReducer,
  surgery: patientSurgeryReducer,
  assessment: patientAssessmentReducer,
  resource: patientResourceReducer,
  consent: patientConsentReducer,
  note: patientNoteReducer,
  certificate: patientCertificateReducer,
  consentForm: patientConsentFormReducer,
});

export default patientReducers;
