import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardContent, CardHeader, Divider, Grid, Hidden, Tooltip, Typography} from "@material-ui/core";
import {RootState} from "../../store/store";
import {Link, useHistory, useParams} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {getSurgery} from "../../store/surgeon/surgeonSurgerySlice";
import {clearSurgeryQuestionAnswers, getSurgeryQuestionAnswers} from "../../store/surgeon/surgeonAssessmentSlice";
import {Accessibility, Cake, LocalHospital, PanTool, Person, Schedule, Wc} from "@material-ui/icons";
import moment from "moment/moment";
import SurgeryQuestionAnswersSection from "../../components/Surgeon/SurgeryQuestionAnswersSection";
import CpContentContainer from "../../components/UI/CpContentContainer";
import {getSurgeonPatient} from "../../store/surgeon/surgeonAuthSlice";
import CertificateAvatar from "./CompleteSurgeryAvatars/CertificateAvatar";
import ConsentFormAvatar from "./CompleteSurgeryAvatars/ConsentFormAvatar";
import SurgeryActionListItems from "./SurgeryActionListItems";
import SurgeryCertificateSection from "../../components/Surgeon/SurgeryCertificateSection";
import SurgeryConsentFormSection from "../../components/Surgeon/SurgeryConsentFormSection";

type PatientParamTypes = {
  id: string
}

const SurgeonPatientSurgery: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<PatientParamTypes>();
  const questionCategories = useSelector((state: RootState) => state.surgeon.assessment.surgeryQuestionAnswers.data?.categories);
  const error = useSelector((state: RootState) => state.surgeon.surgery.currentSurgery.error);
  const surgery = useSelector((state: RootState) => state.surgeon.surgery.currentSurgery.data);
  const patient = useSelector((state: RootState) => state.surgeon.auth.currentPatient.data);

  useEffect(() => {
    if (!params.id) {
      return;
    }

    if ((params.id !== surgery?.surgery_id ?? 0) || !questionCategories) {
      dispatch(clearSurgeryQuestionAnswers())
      Promise.all([
        dispatch(getSurgery(params.id)).then(data => {
          dispatch(getSurgeonPatient(data.data.surgery.patient_id))
        }),
        dispatch(getSurgeryQuestionAnswers(params.id)),
      ]);
    }

    // eslint-disable-next-line
  }, [params.id]);

  const toConsentFormPage = () => {
    history.push(`/surgeon/consent-form/${params.id}`);
  }

  const toCertificatePage = () => {
    history.push(`/surgeon/certificate/${params.id}`);
  }

  if (params.id === undefined || params.id === null) {
    return <Typography paragraph>Please specify a patient to view their account</Typography>
  }

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Surgery Details</Typography>
        <Divider />
      </div>
      <Grid container spacing={3}>
        <SurgeryActionListItems surgeryId={params.id} />
        <Grid item xs={12} md={5} className={classes.gridSection}>
          <Card className={classes.cardSection}>
            <CardHeader title="Surgery" />
            <div className={classes.completeSection}>
              <ConsentFormAvatar onClick={toConsentFormPage} className={classes.consentFormAvatar} surgery_id={params.id} />
              <CertificateAvatar onClick={toCertificatePage} className={classes.certificateAvatar} surgery_id={params.id} />
            </div>
            <CpContentContainer error={error} loading={surgery === null || patient === null}>
              {surgery === null || patient === null
                ? null
                : <CardContent>
                  <Link className={classes.link} to={`/surgeon/patients/${surgery.patient_id}`}>
                    <Typography variant="body2" paragraph><Person className={classes.icon} />
                      Name: {patient.patient_first_name} {patient.patient_last_name}
                    </Typography>
                  </Link>
                  <Typography variant="body2" paragraph><Accessibility className={classes.icon} />{surgery.operation_name} </Typography>
                  <Typography variant="body2" paragraph><LocalHospital className={classes.icon} />{surgery.hospital_name}, {surgery.hospital_city}</Typography>
                  <Typography variant="body2" paragraph><Schedule className={classes.icon} />
                    <Tooltip title="Expected date of surgery" placement="right">
                      <span>{surgery.surgery_date ? moment(surgery.surgery_date).format('LL') : 'Date of surgery unknown'}</span>
                    </Tooltip>
                  </Typography>
                  {patient.patient_laterality
                    ? <Typography variant="body2" paragraph><PanTool className={classes.icon} />
                        <Tooltip title="Patient Laterality" placement="right">
                          <span>{patient.patient_laterality}</span>
                        </Tooltip>
                      </Typography>
                    : null
                  }
                  {patient.patient_gender
                    ? <Typography variant="body2" paragraph><Wc className={classes.icon} />
                        <Tooltip title="Patient Gender" placement="right">
                          <span>{patient.patient_gender}</span>
                        </Tooltip>
                      </Typography>
                    : null
                  }
                  {patient.patient_date_of_birth
                    ? <Typography variant="body2" paragraph><Cake className={classes.icon} />
                        <Tooltip title="Patient Date of Birth" placement="right">
                          <span>{moment(patient.patient_date_of_birth).format('Do MMMM YYYY')}</span>
                        </Tooltip>
                      </Typography>
                    : null
                  }
                </CardContent>
              }
            </CpContentContainer>
          </Card>
          <Hidden smDown>
            <SurgeryQuestionAnswersSection />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={7} className={classes.gridSection}>
          <CpContentContainer loading={params.id !== surgery?.surgery_id ?? 0}>
            { surgery ? <div className={classes.section}><SurgeryConsentFormSection onClick={toConsentFormPage} surgery={surgery} /></div> : null}
            { surgery ? <div className={classes.section}><SurgeryCertificateSection onClick={toCertificatePage} surgery={surgery} /></div> : null}
          </CpContentContainer>
          {/*below here are less important things listed on the left*/}
          <Hidden smUp>
            <SurgeryQuestionAnswersSection />
          </Hidden>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  completeSection: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  gridSection: {
    padding: '1em 0',
  },
  section: {
    marginBottom: '2em',
  },
  cardSection: {
    marginRight: '1em',
    marginBottom: '2em',
  },
  certificateAvatar: {
    paddingTop: '10px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  consentFormAvatar: {
    paddingBottom: '10px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  icon: {
    verticalAlign: '-6px',
    marginRight: '0.5em',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
    },
  },
})));

export default SurgeonPatientSurgery;
