import React, {FunctionComponent} from 'react';
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import {Chat, CreateNewFolder, School} from "@material-ui/icons";

const PatientHomepageActions: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const history = useHistory();

  const changePageHandler = (route: string) => {
    history.push(route);
  }

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Typography paragraph variant="h6" align="center"><strong>Your Consent Process</strong></Typography>
      </Grid>
      <Grid item xs={12}>
        {getPatientActions().map((action) => {
          return <div key={action.text} className={classes.actionContainer} style={{backgroundColor: action.color}} onClick={() => changePageHandler(action.route)}>
            {action.icon}
            <Typography className={classes.actionText} variant="h6">{action.text}</Typography>
          </div>
        })}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1.5em',
      [theme.breakpoints.down('xs')]: {
        padding: '1em 0',
      },
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      padding: '1.5em 2.5em',
      marginTop: '2em',
      color: '#fff',
      '&:hover': {
        transform: 'scale(1.04)',
        cursor: 'pointer',
      },
    },
    actionText: {
      marginLeft: '1em',
    }
  }),
);

type homeAction = {
  text: string,
  icon: JSX.Element
  route: string,
  color: string,
}

const getPatientActions = (): homeAction[] => [
  {
    text: 'Learn about the Consent Process',
    icon: <School fontSize="large" />,
    route: '/patient/information',
    color: '#23bbbb',
  },
  {
    text: 'Start your Consent Process',
    icon: <CreateNewFolder fontSize="large" />,
    route: '/patient/consent-process/create',
    color: '#26aac7',
  },
  {
    text: 'Notes',
    icon: <Chat fontSize="large" />,
    route: '/patient/notes/',
    color: '#356891',
  },
];

export default PatientHomepageActions;
