import axios from 'axios';
import {Engagement} from "./Types";

const resourceInstance = axios.create({
  baseURL: '/api/resource',
});

resourceInstance.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;

export default class ResourceClient {
  getFullSurgeryResource = (surgeryId: string) => {
    this.authorize();

    return resourceInstance.get(`/surgery/${surgeryId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getResourceByOperation = (operationId: string) => {
    this.authorize();

    return resourceInstance.get(`/operation/${operationId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  submitEngagements = (surgeryId: string, syllabusId: string, engagements: Engagement[]) => {
    this.authorize();

    return resourceInstance.post('/surgery', {
      surgery_id: surgeryId,
      syllabus_id: syllabusId,
      engagements: engagements
    })
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getResourceSummary = (surgeryId: string) => {
    this.authorize();

    return resourceInstance.get(`/summary/surgery/${surgeryId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  private authorize = () => {
    resourceInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }
}
