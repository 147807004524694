import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import SurgeryStepper from "./Steps/SurgeryStepper";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {findConsentStepById} from "../../entities/ConsentStep";
import {useParams} from "react-router-dom";

const PatientSummaryFlow: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const activeStep = findConsentStepById(useSelector((state: RootState) => state.patient.consent.activeStep));

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Grid direction="row" justify="space-around" alignItems="center" spacing={0} className={classes.container} item container xs={12}>
          <SurgeryStepper surgery={params.id} />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.summaryContainer}>
        {activeStep.getSummaryComponent()}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '1em 0',
    },
    summaryContainer: {
      height: '215px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '1em',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        height: '370px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '390px',
      },
    },
  }),
);

export default PatientSummaryFlow;
