import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Surgery} from "../../api/Types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {navigateToLatestStep, setLatestCompletedStep, setStarted} from "../../store/patient/patientConsentSlice";
import {findConsentStepById, getConsentSteps, getConsentStepsByIds} from "../../entities/ConsentStep";
import StartConsentProcess from "./Steps/StepProcess/StartConsentProcess";
import SurgeryStepper from "./Steps/SurgeryStepper";
import {Grid} from "@material-ui/core";
import {getFullAssessment} from "../../store/patient/patientAssessmentSlice";
import CpContentContainer from "../UI/CpContentContainer";
import {getFullResource} from "../../store/patient/patientResourceSlice";
import {findCertificate} from "../../store/patient/patientCertificateSlice";
import {useParams} from "react-router-dom";

interface PatientConsentProcessProps {
  surgery: Surgery,
}

const PatientConsentProcess: FunctionComponent<PatientConsentProcessProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const steps = getConsentStepsByIds(useSelector((state: RootState) => state.patient.consent.steps));
  const consentStarted = useSelector((state: RootState) => state.patient.consent.started);
  const activeStep = findConsentStepById(useSelector((state: RootState) => state.patient.consent.activeStep));
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (params.id === props.surgery.surgery_id) {
      Promise.all([
        dispatch(getFullAssessment(props.surgery.surgery_id)),
        dispatch(getFullResource(props.surgery.surgery_id)),
        dispatch(findCertificate(props.surgery.surgery_id)),
      ]).then(() => {
        dispatch(setLatestCompletedStep(props.surgery.surgery_id));

        if (activeStep.id === getConsentSteps()[0].id) {
          dispatch(navigateToLatestStep(props.surgery.surgery_id));
        }

        const processIndex = steps.findIndex((step) => step.id === activeStep.id);
        if (processIndex > 0 || (processIndex === 0 && activeStep.completionPercentage(props.surgery.surgery_id) !== 0)) {
          dispatch(setStarted());
        }
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [props.surgery.surgery_id]);

  let processComponent = activeStep.getProcessComponent();
  if (!consentStarted) {
    processComponent = <StartConsentProcess />
  }

  return (
    <div className={classes.root}>
      <CpContentContainer loading={loading}>
        <Grid item xs={12}>
          <SurgeryStepper surgery={props.surgery.surgery_id} />
        </Grid>
        <Grid item xs={12}>
            {processComponent}
        </Grid>
      </CpContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
  }),
);

export default PatientConsentProcess;
