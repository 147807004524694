import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {QuestionAnswer} from "../../../api/Types";
import {FormControl, FormControlLabel, RadioGroup} from "@material-ui/core";
import CpRadio from "../../UI/CpRadio";

type AnswerRadioProps = {
  question: QuestionAnswer
  answerHandler: (answer: string) => void,
  disabled?: boolean
}

const AnswerRadio: FunctionComponent<AnswerRadioProps> = props => {
  const classes = useStyles();

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const answer = event.target.value;

    if (answer) {
      props.answerHandler(answer)
    }
  }

  return (
    <div className={classes.answerContainer}>
      <FormControl component="fieldset">
        <RadioGroup onChange={inputHandler} value={props.question.patient_answer} aria-label={props.question.question_text} name="assessment-radios">
          {props.question.question_possible_answers.map((answer) => {
            return <FormControlLabel disabled={props.disabled ?? false} className={classes.radio} key={answer} value={answer} control={<CpRadio />} label={answer} />
          })}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    answerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    radio: {
      color: theme.palette.primary.main,
    },
  }),
);

export default AnswerRadio;
