import React, {FunctionComponent} from 'react';
import {Card, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography} from "@material-ui/core";
import {BorderColor, Cancel, CheckCircle, Visibility} from "@material-ui/icons";
import {ConsentFormSurgery} from "../../api/Types";
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpSearch from "../UI/CpSearch";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";

interface ConsentFormTableListProps extends LoadingErrorProps {
  consentSurgeries: ConsentFormSurgery[],
  className?: string,
  onSearch?: (searchText: string) => void,
  title: string
}

const ConsentFormTableList: FunctionComponent<ConsentFormTableListProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const viewSurgery = (surgeryId: string) => {
    history.push(`/surgeon/surgeries/${surgeryId}`);
  }

  const viewConsentForm = (surgeryId: string) => {
    history.push(`/surgeon/consent-form/${surgeryId}`);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSearch = (searchText: string) => {
    if (page !== 0) {
      setPage(0);
    }

    if (props.onSearch !== undefined) {
      props.onSearch(searchText);
    }
  };

  return (
    <Card className={props.className}>
      <Grid container>
        <Grid item xs={12} md={7} lg={5} className={classes.section}>
          <Typography variant="h5">{props.title}</Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={7} className={classes.section}>
          {props.onSearch !== undefined ? <CpSearch onSearch={onSearch} /> : null }
        </Grid>
      </Grid>
      <CpContentContainer error={props.error} loading={props.loading}>
        <TableContainer className={classes.tableSection} component={Paper}>
          <Table aria-label="Latest consent form table" size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell><Typography align="center"><strong>Actions</strong></Typography></TableCell>
                <TableCell><Typography align="center"><strong>Patient Name</strong></Typography></TableCell>
                <TableCell><Typography align="center"><strong>Operation</strong></Typography></TableCell>
                <TableCell><Typography align="center"><strong>Patient Signed</strong></Typography></TableCell>
                <TableCell><Typography align="center"><strong>Surgeon Signed</strong></Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.consentSurgeries.length <= 0
                ? <TableRow><TableCell colSpan={5} align="center" component="th" scope="row">
                  <br/><Typography align="center" paragraph variant="h6">No consent forms found</Typography>
                </TableCell></TableRow>
                : props.consentSurgeries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(consent => (
                <TableRow key={consent.form.surgery_id}>
                  <TableCell align="center">
                    <Tooltip className={classes.action} title="View Surgery">
                      <IconButton onClick={() => viewSurgery(consent.form.surgery_id)} aria-label="view">
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    <Tooltip className={classes.action} title="Sign Consent Form">
                      <IconButton onClick={() => viewConsentForm(consent.form.surgery_id)} aria-label="view">
                        <BorderColor />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {consent.patient.patient_first_name} {consent.patient.patient_last_name}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {consent.surgery.operation_name}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {consent.form.patient_signed ? <CheckCircle className={classes.check} fontSize="large"/> : <Cancel className={classes.cancel} fontSize="large"/>}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {consent.form.surgeon_signed ? <CheckCircle className={classes.check} fontSize="large"/> : <Cancel className={classes.cancel} fontSize="large"/>}
                  </TableCell>
                </TableRow>
              )) ?? []
              }
            </TableBody>
          </Table>
        </TableContainer>
      </CpContentContainer>
      {props.consentSurgeries.length > rowsPerPage
        ? <TablePagination
          component="div"
          count={props.consentSurgeries.length}
          rowsPerPageOptions={[10]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
        : null
      }

    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  section: {
    padding: '1em',
  },
  tableSection: {
    marginTop: '1em'
  },
  action: {
    margin: '0 10px'
  },
  check: {
    marginLeft: '15px',
    color: '#009A49',
  },
  cancel: {
    marginLeft: '15px',
    color: 'red',
  },
})));


export default ConsentFormTableList;
