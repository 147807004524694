import React, {FunctionComponent, useEffect} from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {getUnverifiedSurgeons, surgeonStatus} from "../../store/admin/adminAuthSlice";
import {Cancel, CheckCircle} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";
import {Surgeon, SurgeonStatus} from "../../api/Types";

const UnverifiedSurgeonsList: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const unverifiedSurgeons = useSelector((state: RootState) => state.admin.auth.unverifiedSurgeons.data);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    if (unverifiedSurgeons === null) {
      dispatch(getUnverifiedSurgeons());
    }
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setSuccessMessage(null);
  };

  const verifySurgeonHandler = (surgeonId: string) => {
    setSuccessMessage(null);
    dispatch(surgeonStatus(surgeonId, SurgeonStatus.VERIFIED)).then(() => {
      setSuccessMessage('Successfully Verified Surgeon');
    });
  };

  const rejectSurgeonHandler = (surgeonId: string) => {
    setSuccessMessage(null);
    dispatch(surgeonStatus(surgeonId, SurgeonStatus.REJECTED)).then(() => {
      setSuccessMessage('Successfully Rejected Surgeon');
    });
  };

  return (
    <Card className={classes.section}>
      <CardHeader title="Surgeons Require Verification"/>
      {(unverifiedSurgeons?.length ?? 0) < 1
        ? <CardContent>
          <Typography variant="h6">No unverified surgeons currently exist</Typography>
        </CardContent>
        : <>
          <Typography variant="body2" className={classes.legacyNotice}>
            Please double check the information <strong>before</strong> verification. You will be granting this person a lot of permissions throughout the site, make sure you know who it is. Thanks.
          </Typography>
          <TableContainer className={classes.tableSection} component={Paper}>
            <Table aria-label="Unverified surgeons table" size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>GMC Number</strong></TableCell>
                  <TableCell><strong>Email Address</strong></TableCell>
                  <TableCell><strong>Mobile Number</strong></TableCell>
                  <TableCell className={classes.actionTitle}><strong>Actions</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unverifiedSurgeons?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((surgeon: Surgeon) => (
                  <TableRow key={surgeon.surgeon_id}>
                    <TableCell component="th" scope="row">
                      {surgeon.surgeon_title ?? ''} {surgeon.surgeon_first_name} {surgeon.surgeon_last_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {surgeon.surgeon_gmc_number}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {surgeon.surgeon_email}
                    </TableCell>
                    <TableCell>
                      {surgeon.surgeon_mobile_number ?? 'Unknown'}
                    </TableCell>
                    <TableCell>
                      <div className={classes.actions}>
                        <Tooltip title={`Verify ${surgeon.surgeon_first_name}`} className={classes.verifyIcon}>
                          <IconButton
                            onClick={() => verifySurgeonHandler(surgeon.surgeon_id ?? '')}
                            aria-label="Verify"
                          >
                            <CheckCircle fontSize="large"/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={`Reject ${surgeon.surgeon_first_name}`} className={classes.rejectIcon}>
                          <IconButton
                            onClick={() => rejectSurgeonHandler(surgeon.surgeon_id ?? '')}
                            aria-label="Reject"
                          >
                            <Cancel fontSize="large"/>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                )) ?? []}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
      {(unverifiedSurgeons?.length ?? 0) > rowsPerPage
        ? <TablePagination
          component="div"
          count={unverifiedSurgeons?.length ?? 0}
          rowsPerPageOptions={[10]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
        : null
      }
      <Snackbar open={successMessage !== null} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  section: {
    marginRight: '1em',
  },
  legacyNotice: {
    padding: '1em 2em',
    color: 'red',
  },
  actionTitle: {
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  verifyIcon: {
    color: 'green',
  },
  rejectIcon: {
    color: 'red',
  },
  tableSection: {
    marginTop: '0.5em'
  }
})));

export default UnverifiedSurgeonsList;
