import React, {FunctionComponent} from 'react';
import {FormControl, Toolbar, Typography, Paper} from "@material-ui/core";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import {ValidationErrorItem} from "joi";

type CpFormProps = {
  className?: string
  name: string
  onSubmit: (event: React.FormEvent) => void
}

// This is the state that should be used by the parent component
export interface CpFormState {
  fields: { [key: string]: string|null };
  errors: ValidationErrorItem[]|null;
}

const CpForm: FunctionComponent<CpFormProps> = props => {
  const classes = useStyles();

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    props.onSubmit(event);
  }

  return (
    <Paper elevation={3} className={classes.root}>
      <div className={classes.header}>
        <Toolbar>
          <Typography>{props.name}</Typography>
        </Toolbar>
      </div>
      <form onSubmit={submitHandler} className={props.className}>
        <FormControl className={classes.form}>
          {props.children}
        </FormControl>
      </form>
    </Paper>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  root: {
    margin: '1em 0'
  },
  header: {
    position: 'static',
    backgroundColor: theme.palette.primary.main,
    height: '64px',
    lineHeight: '64px',
    color: theme.palette.text.secondary,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.4em 0.4em'
  },
})));

export default CpForm;
