import axios from 'axios';
import download from "downloadjs";

const consentFormInstance = axios.create({
  baseURL: '/api/consent-form',
});

consentFormInstance.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;

export default class ConsentFormClient {
  findConsentForm = (surgeryId: string) => {
    this.authorize();

    return consentFormInstance.get(`/surgery/${surgeryId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getAllSurgeonConsentForms = () => {
    this.authorize();

    return consentFormInstance.get(`/surgeon/`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  downloadConsentForm = (surgeryId: string) => {
    this.authorize();

    return consentFormInstance.get(`/download/surgery/${surgeryId}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    })
      .then(response => {
        download(new Blob([response.data], { type: "application/pdf" }), 'consent-form.pdf');
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  createConsentForm = (surgeryId: string, signature: string) => {
    this.authorize();

    return consentFormInstance.post(`/surgery/${surgeryId}`, {patient_signature: signature})
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  createSurgeonConsentForm = (surgeryId: string, signature: string) => {
    this.authorize();

    return consentFormInstance.post(`/surgery/${surgeryId}`, {surgeon_signature: signature})
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  previewConsentForm = (surgeryId: string) => {
    this.authorize();

    return consentFormInstance.get(`/preview/surgery/${surgeryId}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    })
      .then(response => {
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        return Promise.resolve(URL.createObjectURL(file))
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  private authorize = () => {
    consentFormInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }
}
