import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ResourceCategory, SyllabusType} from "../../../api/Types";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import ResourceVimeo from "./ResourceVimeo";
import ResourceList from "./ResourceList";
import {submitResourceEngagement} from "../../../store/patient/patientResourceSlice";

interface ResourceEngagementContainerProps {
  surgeryId: string
  syllabus: ResourceCategory
  onVideoComplete?: (resourceId: string) => void
}

const ResourceEngagementContainer: FunctionComponent<ResourceEngagementContainerProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentResourceKey, setCurrentResourceKey] = React.useState<number|null>(null); // null so we know to setup

  const selectResourceHandler = async (resourceId: string) => {
    const index = props.syllabus.resource_engagements.findIndex((r) => r.resource_id === resourceId);
    setCurrentResourceKey(index);
  }

  const setLatestResource = () => {
    for (const [key, resource] of Object.entries(props.syllabus.resource_engagements)) {
      if (resource.patient_engagement === 0) {
        setCurrentResourceKey(parseInt(key))
        break;
      }

      if (parseInt(key) === props.syllabus.resource_engagements.length - 1) {
        setCurrentResourceKey(0)
      }
    }
  }

  const videoCompleteHandler = (resourceId: string) => {
    const index = props.syllabus.resource_engagements.findIndex((r) => r.resource_id === resourceId);
    const viewedResource = props.syllabus.resource_engagements[index];

    if (index !== -1) {
      dispatch(submitResourceEngagement(props.surgeryId, SyllabusType.INITIAL_LEARNING, viewedResource));

      props.syllabus.resource_engagements[index + 1]
        ? setCurrentResourceKey(index + 1)
        : setCurrentResourceKey(0)

      if (props.onVideoComplete !== undefined) {
        props.onVideoComplete(resourceId)
      }
    }
  }

  if (currentResourceKey === null) {
    setLatestResource();
    return null;
  }

  return (
    <div className={classes.resourceContainer}>
      <Grid item xs={12} md={8}>
        <ResourceVimeo onVideoComplete={videoCompleteHandler} resource={props.syllabus.resource_engagements[currentResourceKey ?? 0]} />
      </Grid>
      <Grid item xs={12} md={4} className={classes.resourceListContainer}>
        <ResourceList
          resources={props.syllabus.resource_engagements}
          active={currentResourceKey ?? 0}
          completed={props.syllabus.user_engagement_count}
          total={props.syllabus.total_resource_count}
          onResourceClick={selectResourceHandler}
        />
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resourceContainer: {
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      marginBottom: '3em',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        padding: '0 2.5em',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
    resourceListContainer: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '1em',
      },
    }
  }),
);

export default ResourceEngagementContainer;
