import {createSlice} from "@reduxjs/toolkit";
import {resourceClient} from "../../api/Container";
import {Engagement, ResourceCategory, ResourceEngagement, ResourceSummary, SyllabusType} from "../../api/Types";
import store, {Error, Loading} from "../store";
import {filterNull, findResourceByType} from "../../app/helpers";

const initialState: patientResourceState = {
  full: {
    error: null,
    loading: false,
    data: null,
  },
  summary: {
    error: null,
    loading: false,
    data: null,
  },
}

export const patientResourceSlice = createSlice({
  name: 'patientResource',
  initialState,
  reducers: {
    setFullResource: (state, action) => {
      state.full.data = action.payload.resources;
      state.full.error = null;
      state.full.loading = false;
    },
    submitFullResourceEngagement: (state, action) => {
      const category = action.payload.categoryIndex;
      const resource = action.payload.resourceIndex;

      if (state.full.data !== null) {
        // update user count
        if (state.full.data.categories[category].resource_engagements[resource].patient_engagement === null) {
          state.full.data.categories[category].user_engagement_count += 1;
        }
        // add engagement
        state.full.data.categories[category].resource_engagements[resource].patient_engagement = action.payload.viewed;
      }
    },
    setSummaryResource: (state, action) => {
      state.summary.data = action.payload.resource;
      state.summary.error = null;
      state.summary.loading = false;
    },
    clearFullResource: (state) => {
      state.full = initialState.full;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default patientResourceSlice.reducer;

// @ts-ignore as this is a middleware
export const getFullResource = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(patientResourceSlice.actions.setStart({index: 'full'}))

  try {
    const response = await resourceClient.getFullSurgeryResource(surgeryId);
    dispatch(patientResourceSlice.actions.setFullResource(response.data));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientResourceSlice.actions.setEnd({index: 'full', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const submitSyllabus = (surgeryId: string, syllabus: ResourceCategory|null): Promise<any> => async dispatch => {
  if (syllabus === null) {
    return Promise.reject();
  }

  dispatch(patientResourceSlice.actions.setStart({index: 'full'}))
  const engagements: Engagement[] = syllabus.resource_engagements.map((re) => {
    return re.patient_engagement === null ?  null : {
      resource_id: re.resource_id,
      viewed: re.patient_engagement,
    };
  }).filter(filterNull);

  if (engagements === []) {
    return Promise.reject();
  }

  try {
    const response = await resourceClient.submitEngagements(surgeryId, syllabus.syllabus_id, engagements);
    await dispatch(getFullResource(surgeryId)); // refresh resources
    dispatch(patientResourceSlice.actions.setEnd({index: 'full', error: null}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientResourceSlice.actions.setEnd({index: 'full', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const submitResourceEngagement = (surgeryId: string, category: SyllabusType, resource: ResourceEngagement): Promise<any> => async dispatch => {
  try {
    const syllabuses = store.getState().patient.resource.full.data;
    const syllabus = findResourceByType(surgeryId, category);
    if (syllabuses === null || syllabus === null) {
      return Promise.reject();
    }

    const categoryIndex = syllabuses.categories.findIndex((a) => a.category === syllabus.category)
    const resourceIndex = syllabus.resource_engagements.findIndex((q) => q.resource_id === resource.resource_id)

    if (categoryIndex === -1 || resourceIndex === -1) {
      return Promise.reject();
    }

    dispatch(patientResourceSlice.actions.submitFullResourceEngagement({
      categoryIndex: categoryIndex,
      resourceIndex: resourceIndex,
      viewed: syllabus.resource_engagements[resourceIndex].patient_engagement + 1,
    }));
    return Promise.resolve();
  } catch (err: any) {
    dispatch(patientResourceSlice.actions.setEnd({index: 'full', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getResourceSummary = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(patientResourceSlice.actions.setStart({index: 'summary'}))

  try {
    const response = await resourceClient.getResourceSummary(surgeryId);
    dispatch(patientResourceSlice.actions.setSummaryResource(response.data));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientResourceSlice.actions.setEnd({index: 'summary', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const clearFullResource = (): Promise<any> => async dispatch => {
  dispatch(patientResourceSlice.actions.clearFullResource());
  return Promise.resolve();
}

type patientResourceState = {
  full: {
    error: Error
    loading: Loading
    data: {
      surgery_id: string
      categories: ResourceCategory[]
    }|null
  }
  summary: {
    error: Error
    loading: Loading
    data: ResourceSummary|null,
  }
}
