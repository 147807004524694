import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {Note} from "../../api/Types";
import DOMPurify from "dompurify";
import moment from "moment/moment";
import {authClient} from "../../api/Container";

interface CpDisplayNoteProps {
  notes: Note[]|null
  emptyDisplayText: string
}

const CpDisplayNotes: FunctionComponent<CpDisplayNoteProps> = props => {
  const classes = useStyles();
  const author = authClient.getAuthId();

  const sortedNotes = [...props.notes ?? []].sort((a: Note, b: Note) => (a.created_at ?? 0) < (b.created_at ?? 0) ? -1 : 1)

  return <>
    {sortedNotes !== null && sortedNotes.length > 0 ? sortedNotes.map((note) => {
      const authorClass = author === note.author_id ? classes.authorMe : classes.authorThem;
      return <div key={note.id} className={[classes.messageBox, authorClass].join(' ')}>
          <Typography className={classes.date} variant="caption">{moment(note.created_at).format("MMM Do YY")}</Typography>
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(note.content)}}/>
        </div>
      })
      : <Typography variant="h5" className={classes.emptyNoteTitle} dangerouslySetInnerHTML={{__html: props.emptyDisplayText}} />
    }
  </>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageBox: {
      backgroundColor: 'white',
      borderRadius: '5px',
      width: '70%',
      margin: '1em',
      boxShadow: '1px 1px 2px 2px #dbdbdb',
      padding: '0.5em 1.5em',
    },
    authorMe: {
      backgroundColor: '#457EC1',
      color: '#fff',
      float: 'right',
    },
    authorThem: {
      backgroundColor: '#fff',
      float: 'left',
      color: '#000',
    },
    date: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '0.5em'
    },
    emptyNoteTitle: {
      width: '100%',
      height: '100%',
      textAlign: 'justify',
      padding: '0 3em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export default CpDisplayNotes;
