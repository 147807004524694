import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {ConsentStep} from "../../../../api/Types";
import CpContentContainer from "../../../UI/CpContentContainer";
import CpButton from "../../../UI/CpButton";
import {CardMembership, Chat, Visibility} from "@material-ui/icons";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {downloadCertificate, findCertificate} from "../../../../store/patient/patientCertificateSlice";
import {RootState} from "../../../../store/store";
import {getSurgery} from "../../../../store/surgeon/surgeonSurgerySlice";

interface CertificateSummaryProps {
  step: ConsentStep
}

const CertificateSummary: FunctionComponent<CertificateSummaryProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const certificate = useSelector((state: RootState) => state.patient.certificate.current.data);
  const surgery = useSelector((state: RootState) => state.patient.surgery.current.data);
  const error = useSelector((state: RootState) => state.patient.certificate.current.error);
  const loading = useSelector((state: RootState) => state.patient.certificate.current.loading);
  const [showCode, setShowCode] = React.useState(false);

  useEffect(() => {
    if (certificate === null || certificate.surgery_id !== params.id) {
      Promise.all([
        dispatch(getSurgery(params.id)),
        dispatch(findCertificate(params.id)),
      ]);
    }
    // eslint-disable-next-line
  }, [params.id]);

  const navigateNoteHandler = () => {
    history.push(`/patient/notes/${params.id}`);
  }

  const previewCertificateHandler = () => {
    dispatch(downloadCertificate(params.id));
  }

  const displayCodeHandler = () => {
    setShowCode(true);
  }

  return (
    <>
      <div className={classes.buttonContainer}>
        <CpButton
          variant="contained"
          color="primary"
          classes={{containedPrimary: classes.noteButtonColour}}
          size="large"
          startIcon={<Chat />}
          onClick={navigateNoteHandler}
        >
          View Notes
        </CpButton>
        <CpButton
          disabled={loading || certificate === null || !certificate.consented}
          variant="contained"
          color="primary"
          classes={{containedPrimary: classes.downloadButtonColour}}
          size="large"
          startIcon={<CardMembership />}
          onClick={previewCertificateHandler}
        >
          Download Certificate
        </CpButton>
        {surgery !== null ?
          showCode ? <Typography className={classes.code} variant="h5">{surgery.unique_code}</Typography>
            : <CpButton
              variant="contained"
              classes={{containedPrimary: classes.revealButtonColour}}
              color="primary"
              size="large"
              startIcon={<Visibility />}
              onClick={displayCodeHandler}
            >
              Reveal Your Code
            </CpButton>
          : null
        }
      </div>
      <div className={classes.descriptionContainer}>
        <Typography paragraph variant="h5"><strong>{props.step.name}</strong></Typography>
        <CpContentContainer error={error} loading={loading}>
          {
            certificate ?
              <><Typography paragraph variant="body1">
                Well done on completing your certificate!
                If you have chosen on surgery and want to sign the consent form, please continue below.
              </Typography>
              <Typography paragraph variant="body1">
                Please give your unique code to your surgeon so they can see your progress. Do <strong>not</strong> share this code with anyone else.
              </Typography></>
            : <Typography paragraph variant="body1">
                Please consent before downloading your certificate.
                <br />Please click <strong>"Continue"</strong> below for more details.
              </Typography>
          }
        </CpContentContainer>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionContainer: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      textAlign: 'justify'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'space-around',
      height: '100%',
    },
    noteButtonColour: {
      backgroundColor: '#356891',
      '&:hover': {
        backgroundColor: '#2a5374',
      }
    },
    downloadButtonColour: {
      backgroundColor: '#26aac7',
      '&:hover': {
        backgroundColor: '#1e889f',
      },
    },
    revealButtonColour: {
      backgroundColor: '#26c794',
      '&:hover': {
        backgroundColor: '#1e9f76',
      },
    },
    preview: {
      padding: '4em',
      border: `1px solid ${theme.palette.primary.main}`
    },
    code: {
      fontFamily: 'open sans',
      letterSpacing: '7px',
      textAlign: 'center',
    },
  }),
);

export default CertificateSummary;
