import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Typography} from "@material-ui/core";
import {RootState} from "../../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import clsx from "clsx";
import {Description} from "@material-ui/icons";
import {findConsentForm} from "../../../store/surgeon/surgeonConsentFormSlice";
import {ConsentForm} from "../../../api/Types";

type ConsentFormAvatarProps = {
  surgery_id: string
  className?: string
  onClick?: (consentForm: ConsentForm|null) => void
}

const ConsentFormAvatar: FunctionComponent<ConsentFormAvatarProps> = props => {
  const classes = useStyles();
  const [consentForm, setConsentForm] = React.useState<ConsentForm|null>(null);
  const loading = useSelector((state: RootState) => state.surgeon.consentForm.current.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    dispatch(findConsentForm(props.surgery_id)).then(res => {
      if (mounted) {
        setConsentForm(res.data.consent_form)
      }
    }).catch(e => { return }); // ignore error

    return () => { mounted = false };
    // eslint-disable-next-line
  }, [props.surgery_id]);

  if (loading) {
    return null;
  }

  const onClickHandler = () => {
    if (props.onClick === undefined) {
      return;
    }

    props.onClick(consentForm);
  }

  return (
    <div onClick={onClickHandler} className={[classes.root, props.className].join(' ')}>
      <Avatar className={clsx(classes.avatarContainer, {
        [classes.complete]: consentForm !== null,
        [classes.incomplete]: consentForm === null,
      })}><Description fontSize="small" /></Avatar>
      <Typography variant="body2"><strong>{consentForm !== null
        ? 'Consent form complete'
        : 'Consent form incomplete'
      }</strong></Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
  },
  avatarContainer: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },
  complete: {
    backgroundColor: '#009A49',
  },
  incomplete: {
    backgroundColor: '#cccccc',
  },
})));

export default ConsentFormAvatar;
