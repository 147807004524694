import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Divider, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import SurgeonHelpActions from "../../components/Surgeon/SurgeonHelpActions";

const SurgeonHelpPage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const patient = useSelector((state: RootState) => state.patient.auth.account.data);

  return (
    <div className={classes.container}>
      <Typography className={classes.headerTitle} variant="h5">
        Welcome {`${patient ? patient.patient_first_name : ''}`}
      </Typography>
      <Divider />
      <div className={classes.actionContainer}>
        <SurgeonHelpActions />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    padding: '0',
    marginBottom: '5em',
  },
  headerTitle: {
    marginLeft: '1em',
    padding: '0.5em 0',
  },
  actionContainer: {
    margin: '2em 5em',
    [theme.breakpoints.down('md')]: {
      margin: '2em 0',
    },
  },
})));

export default SurgeonHelpPage;
