import React, {FunctionComponent, useState} from 'react';
import CpTextField from "../../components/UI/CpTextField";
import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";
import {Email} from "@material-ui/icons";
import CpForm from "../UI/CpForm";
import CpButton from "../UI/CpButton";
import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import CpLoading from "../UI/CpLoading";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {passwordResetRequestSchema, passwordResetSchema} from "../../schemas/publicSchemas";
import {resetPassword, resetPasswordRequest} from "../../store/public/publicAuthSlice";
import {Alert} from "@material-ui/lab";

const PasswordResetForm: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const loading = useSelector((state: RootState) => state.public.auth.loading);
  const dispatch = useDispatch();
  const urlQuery = useLocation();
  const [email, setEmail] = useState<string|null>(null);
  const [password, setPassword] = useState<string|null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string|null>(null);
  const [errors, setErrors] = useState(null);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const token = new URLSearchParams(urlQuery.search).get('token');

  const submitHandler = async () => {
    setErrors(null);

    token !== null ? await resetPasswordHandler() : await resetPasswordRequestHandler();
  }

  const resetPasswordRequestHandler = async () => {
    try {
      await passwordResetRequestSchema.validateAsync(
        {email: email},
        { abortEarly: false }
      );

      await dispatch(resetPasswordRequest(email ?? ''));

      setEmailSent(true);
    } catch (e: any) {
      setErrors(e.details ?? null);
    }
  }

  const resetPasswordHandler = async () => {
    try {
      await passwordResetSchema.validateAsync(
        {password: password, confirmPassword: confirmPassword},
        { abortEarly: false }
      );

      await dispatch(resetPassword(token ?? '', password ?? ''));

      setPasswordSuccess(true);
    } catch (e: any) {
      setErrors(e.details ?? null);
    }
  }

  const emailSuccessMessage = (<Alert className={classes.field} variant="filled" severity="success">An email has been sent so you can reset your password</Alert>)
  const resetSuccessMessage = (<Alert className={classes.field} variant="filled" severity="success">Your password has been successfully set, please login</Alert>)

  return (
    <CpForm onSubmit={submitHandler} name="Reset Password">
      {token === null
      ? <CpTextField
        className={classes.field}
        id="email"
        type="email"
        label="Email"
        variant="outlined"
        onChange={(e) => setEmail(e.target.value)}
        error={getJoiErrorMessage('email', errors) !== null}
        helperText={getJoiErrorMessage('email', errors)}
      />
      :
      <>
        <CpTextField
          className={classes.field}
          id="password"
          type="password"
          label="Password"
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
          error={getJoiErrorMessage('password', errors) !== null}
          helperText={getJoiErrorMessage('password', errors)}
        />
        <CpTextField
          className={classes.field}
          type="password"
          label="Confirm Password"
          variant="outlined"
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={getJoiErrorMessage('confirmPassword', errors) !== null}
          helperText={getJoiErrorMessage('confirmPassword', errors)}
        />
      </>
      }
      {emailSent ? emailSuccessMessage : passwordSuccess ? resetSuccessMessage :
        <CpButton
        disabled={loading}
        type="submit"
        className={classes.field}
        variant="contained"
        color="primary"
        size="large"
        startIcon={<Email />}
        >
          Submit
        </CpButton>
      }
      <CpLoading loading={loading} />
    </CpForm>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  field: {
    margin: '1.2em',
  },
  passwordReset: {
    margin: 'auto',
  },
})));

export default PasswordResetForm;
