import React, {FunctionComponent} from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {ConsentStep} from "../../../api/Types";
import {findConsentStepById, getConsentStepsByIds} from "../../../entities/ConsentStep";
import {setActiveStep} from "../../../store/patient/patientConsentSlice";

const ColorlibConnector = withStyles({
  root: {
    padding: 0,
  },
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(94,197,199) 0%,rgb(35,187,186) 50%,rgb(15,57,92) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(94,197,199) 0%,rgb(35,187,186) 50%,rgb(15,57,92) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme: Theme) => (createStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient( 136deg, rgb(94,197,199) 0%, rgb(35,187,186) 50%, rgb(15,57,92) 100%)',
    cursor: 'pointer !important',
  },
  completed: {
    backgroundImage: 'linear-gradient( 136deg, rgb(94,197,199) 0%, rgb(35,187,186) 50%, rgb(15,57,92) 100%)',
    cursor: 'pointer !important',
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    transform: 'scale(1.15)',
  },
  pointer: {
    cursor: 'not-allowed',
  }
})));

interface ColorlibStepIconProps extends StepIconProps {
  selected: boolean,
  disableSelect: boolean,
}

const ColorlibStepIcon: FunctionComponent<ColorlibStepIconProps> = props => {
  const classes = useColorlibStepIconStyles();
  const { active, completed, selected, disableSelect } = props;

  return (
    <div className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
        [classes.pointer]: !disableSelect,
        [classes.selected]: selected && !disableSelect,
      })}
    >{props.icon}</div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootStepper: {
      overflowX: 'scroll',
      width: '100%',
    },
    root: {
      width: '100%',
      padding: '5px',
      backgroundColor: 'transparent',
      color: 'black',
    },
    area: {
      padding: 0,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    label: {
      color: 'red',
    },
    incompleteLabel: {
      color: '#ccc',
    }
  }),
);

interface SurgeryStepperProps {
  disableSelect?: boolean
  surgery: string
}

const SurgeryStepper: FunctionComponent<SurgeryStepperProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const steps = getConsentStepsByIds(useSelector((state: RootState) => state.patient.consent.steps));
  const completedStep = findConsentStepById(useSelector((state: RootState) => state.patient.consent.completedStep));
  const activeStep = findConsentStepById(useSelector((state: RootState) => state.patient.consent.activeStep));

  const handleStepChange = (toStep: ConsentStep) => {
    if (props.disableSelect) {
      return;
    }

    const toStepIndex = steps.findIndex((step) => step.id === toStep.id);
    const completedStepIndex = steps.findIndex((step) => step.id === completedStep.id);

    if (toStepIndex <= completedStepIndex) {
      dispatch(setActiveStep(toStep));
    }
  }

  let activeSteps = steps.filter((step) => step.required);
  let activeIndex = activeSteps.findIndex((step) => step.id === completedStep.id);

  if (activeIndex === -1) {
    const lastCompletedStep = activeSteps.filter((step) => step.completed(props.surgery)).pop()
    activeIndex = lastCompletedStep === undefined ? 0 : activeIndex = activeSteps.findIndex((step) => step.id === lastCompletedStep.id);
  }

  return (
    <div className={classes.rootStepper}>
      <Stepper classes={{root: classes.root}} alternativeLabel activeStep={activeIndex} connector={<ColorlibConnector />}>
        {steps.map((consentStep) => (
          consentStep.required
          ? <Step onClick={() => handleStepChange(consentStep)} key={consentStep.id}>
              <StepLabel classes={{label: classes.incompleteLabel}} StepIconComponent={(stepLabelProps) => <ColorlibStepIcon
                {...stepLabelProps}
                icon={consentStep.getIcon()}
                selected={consentStep.id === activeStep.id}
                disableSelect={props.disableSelect ?? false}
              />}>
                {consentStep.name}
              </StepLabel>
            </Step>
          : null
        ))}
      </Stepper>
    </div>
  );
}

export default SurgeryStepper;
