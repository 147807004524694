import React, {FunctionComponent, useEffect} from "react";
import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import CpButton from "../UI/CpButton";
import {NavigateNext} from "@material-ui/icons";
import {FormHelperText, Select} from "@material-ui/core";
import CpForm from "../UI/CpForm";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpSelectContainer from "../UI/CpSelectContainer";
import {ValidationErrorItem} from "joi";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {getOperations} from "../../store/patient/patientSurgerySlice";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";
import {operationSchema} from "../../schemas/patientSchemas";

interface SelectOperationFormProps extends LoadingErrorProps {
  onSubmit: (operation: string) => void
}

const SelectOperationForm: FunctionComponent<SelectOperationFormProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [operation, setOperation] = React.useState<string|null>(null);
  const [joiErrors, setJoiErrors] = React.useState<ValidationErrorItem[]|null>(null);
  const error = useSelector((state: RootState) => state.patient.surgery.operations.error);
  const loading = useSelector((state: RootState) => state.patient.surgery.operations.loading);
  const operations = useSelector((state: RootState) => state.patient.surgery.operations.data);

  useEffect(() => {
    if (operations === null) {
      dispatch(getOperations())
    }
    // eslint-disable-next-line
  }, []);

  const submitHandler = async () => {
    setJoiErrors(null)
    try {
      await operationSchema.validateAsync(
        { operation: operation },
        { abortEarly: false }
      );

      if (operation === null) {
        return;
      }

      return props.onSubmit(operation);
    } catch (e: any) {
      if (e.status === undefined) {
        setJoiErrors(e.details);
        return;
      }
    }
  }

  const operationChangeHandler = (operation: any) => {
    setOperation(operation);
  }

  if (operations === null) {
    return null;
  }

  return <CpContentContainer error={error} loading={loading}>
      <CpForm onSubmit={submitHandler} name="Please Select Your Operation">
      <CpSelectContainer className={classes.field} label="Operation" id="create-operation-select">
        <Select
          native
          value={operation ?? ''}
          label="Operation"
          onChange={(e) => operationChangeHandler(e.target.value)}
          error={getJoiErrorMessage('operation', joiErrors) !== null}
          inputProps={{name: 'operation', id: 'create-operation-select'}}
        >
          <option aria-label="Please Select" value="" />
          {operations.map((operation) => {
            return <option key={operation.operation_id} aria-label={operation.operation_name} value={operation.operation_id}>
              {operation.operation_name}
            </option>
          })}
        </Select>
        <FormHelperText style={{color: 'red'}}>{getJoiErrorMessage('operation', joiErrors)}</FormHelperText>
      </CpSelectContainer>
      <CpButton
        disabled={loading || (props.loading ?? false)}
        type="submit"
        className={classes.field}
        variant="contained"
        color="primary"
        size="large"
        startIcon={<NavigateNext />}
        data-testid="login-button"
      >
        Submit
      </CpButton>
      <CpContentContainer error={props.error} loading={props.loading} />
    </CpForm>
  </CpContentContainer>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  field: {
    margin: '1.2em',
  },
})));

export default SelectOperationForm;
