import React, {FunctionComponent} from 'react';
import {Snackbar, IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";

interface CpErrorProps {
  error: string
}

const CpError: FunctionComponent<CpErrorProps> = props => {
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = React.useState(true);

  return (
    <Snackbar
      className={classes.snackbar}
      open={alertOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={() => setAlertOpen(false)}
    >
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setAlertOpen(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        className={classes.alert}
        variant="filled"
        severity="error"
      >
        {props.error}
      </Alert>
    </Snackbar>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  snackbar: {
    paddingTop: '4.5em',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '3.4em',
    },
  },
  alert: {
    padding: '0.5em 1.5em',
  },
})));

export default CpError;
