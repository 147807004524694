import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Divider, Grid, Typography} from "@material-ui/core";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {getSurgery} from "../../store/surgeon/surgeonSurgerySlice";
import SurgeryConsentFormSection from "../../components/Surgeon/SurgeryConsentFormSection";
import {useHistory, useParams} from "react-router-dom";
import CpContentContainer from "../../components/UI/CpContentContainer";
import CpTextField from "../../components/UI/CpTextField";
import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import CpButton from "../../components/UI/CpButton";
import {BorderColor} from "@material-ui/icons";
import {ValidationErrorItem} from "joi";
import {consentSchema} from "../../schemas/surgeonSchemas";
import {createConsentForm, findConsentForm} from "../../store/surgeon/surgeonConsentFormSlice";
import CpBreadcrumbs from "../../components/UI/CpBreadcrumbs";

const SurgeonConsentFormPage: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [signature, setSignature] = React.useState<string>('');
  const [signatureError, setSignatureError] = React.useState<ValidationErrorItem[]|null>(null);
  const error = useSelector((state: RootState) => state.surgeon.surgery.currentSurgery.error);
  const loading = useSelector((state: RootState) => state.surgeon.surgery.currentSurgery.loading);
  const surgery = useSelector((state: RootState) => state.surgeon.surgery.currentSurgery.data);
  const surgeon = useSelector((state: RootState) => state.surgeon.auth.account.data);
  const consentForm = useSelector((state: RootState) => state.surgeon.consentForm.current.data);
  const consentFormError = useSelector((state: RootState) => state.surgeon.consentForm.current.error);
  const consentFormLoading = useSelector((state: RootState) => state.surgeon.consentForm.current.loading);

  useEffect(() => {
    if (surgery === null || params.id !== surgery.surgery_id) {
      dispatch(getSurgery(params.id));
    }

    if (consentForm === null || consentForm.surgery_id !== params.id) {
      dispatch(findConsentForm(params.id));
    }

    // eslint-disable-next-line
  }, [params.id]);

  const breadcrumbs = [
    {to: '/surgeon/home', name: 'Home', click: true},
    {to: `/surgeon/surgeries/${params.id}`, name: 'Surgery Details', click: true},
    {to: `/surgeon/consent-form/${params.id}`, name: 'Consent Form Preview', click: false}
  ];

  const submitHandler = async (e: React.FormEvent) => {
    if (surgeon === null || consentForm === null) {
      window.location.reload();
      return;
    }

    setSignatureError(null)
    try {
      const surgeonName = `${surgeon.surgeon_first_name} ${surgeon.surgeon_last_name}`
      await consentSchema.validateAsync(
        {signature: signature, signature_name: surgeonName},
        { abortEarly: false }
      );

      await dispatch(createConsentForm(params.id, signature));

      history.push(`/surgeon/surgeries/${params.id}`);
    } catch (e: any) {
      if (e.status === undefined) {
        setSignatureError(e.details);
      }
    }
  }

  const signatureChangeHandler = (value: string) => {
    setSignature(value);
  }

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Consent Form</Typography>
        <Divider />
      </div>
      <><CpBreadcrumbs className={classes.breadcrumbs} breadcrumbs={breadcrumbs} />
      <CpContentContainer error={error} loading={loading || surgery === null || params.id !== surgery.surgery_id}>
        { surgery && surgeon ? <SurgeryConsentFormSection surgery={surgery} minHeight={750}>
            {consentForm !== null && !consentForm.surgeon_signed
              ? <><div className={classes.consentTextContainer}>
                <Typography align="center" paragraph variant="body1">
                  Your signature must match your name exactly: <strong>{surgeon?.surgeon_first_name} {surgeon?.surgeon_last_name}</strong>
                </Typography>
              </div>
              <Grid className={classes.bottomMargin} container item xs={12} direction="column" alignItems="center" justify="space-between">
                <CpTextField
                  id="signature-surgeon-name"
                  disabled={consentFormLoading}
                  className={classes.signatureField}
                  type="text"
                  label="Signature"
                  variant="outlined"
                  onChange={(e) => signatureChangeHandler(e.target.value)}
                  error={getJoiErrorMessage('signature', signatureError) !== null}
                  helperText={getJoiErrorMessage('signature', signatureError)}
                />
                <CpButton
                  disabled={consentFormLoading}
                  variant="contained"
                  className={classes.signButton}
                  color="primary"
                  size="large"
                  startIcon={<BorderColor />}
                  onClick={submitHandler}
                >
                  Sign
                </CpButton>
                <CpContentContainer error={consentFormError} loading={consentFormLoading} />
              </Grid></>
              : null
            }
          </SurgeryConsentFormSection>
          : <Typography>The patient has not completed the consent form for this surgery yet</Typography>}
      </CpContentContainer></>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  breadcrumbs: {
    paddingBottom: '1em',
    paddingLeft: '0.5em',
    backgroundColor: theme.palette.background.default,
  },
  consentTextContainer: {
    marginTop: '3.5em',
    textAlign: 'center',
    width: '100%',
  },
  signatureField: {
    width: '50%',
    minWidth: '200px',
    margin: '1em',
  },
  signButton: {
    padding: '1em 6em',
    margin: '1em'
  },
  bottomMargin: {
    marginBottom: '3em',
  }
})));

export default SurgeonConsentFormPage;
