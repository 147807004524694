import React, {FunctionComponent} from 'react';
import {useSelector} from "react-redux";
import {Card} from "@material-ui/core";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpChart from "../UI/CpChart";
import {Patient} from "../../api/Types";
import moment from "moment/moment";
import CpContentContainer from "../UI/CpContentContainer";

const SurgeonPatientsYearlyBarChart: FunctionComponent<{}> = props => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.surgeon.auth.patients.error);
  const loading = useSelector((state: RootState) => state.surgeon.auth.patients.loading);
  const patients = useSelector((state: RootState) => state.surgeon.auth.patients.data);

  const splitPatientsIntoYear = (patients: Patient[]) => {
    const data: {[key: string]: number} = {};
    [...patients].forEach(patient => {
      const date = moment(patient.patient_created_at)
      data[date.year()] = data[date.year()] === undefined ? 1 : data[date.year()] + 1;
    });

    return data;
  }

  if (patients === null) {
    return null;
  }

  const chartData = splitPatientsIntoYear(patients);

  const data = {
    labels: Object.keys(chartData).reverse(),
      datasets: [{
      label: 'Patients Registered',
      data: Object.values(chartData).reverse(),
      backgroundColor: 'rgba(35, 187, 186, 0.6)',
    }]
  };

  const options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  return (
    <Card className={classes.chart}>
      <CpContentContainer error={error} loading={loading}>
        <CpChart id="patients-yearly-chart" type="bar" data={data} options={options} />
      </CpContentContainer>
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  chart: {
    padding: '1.5em',
  },
})));

export default SurgeonPatientsYearlyBarChart;
