import {createSlice} from "@reduxjs/toolkit";
import {authClient} from "../../api/Container";
import {Credentials, Patient, Surgeon} from "../../api/Types";
import moment from "moment/moment";

const initialState: publicAuthState = {
  token: authClient.getToken(),
  surgeonId: authClient.getSurgeonId(),
  patientId: authClient.getPatientId(),
  expiration: authClient.getExpirationDate()?.unix() ?? null,
  roles: authClient.getAuthRoles(),
  error: null,
  loading: false,
}

export const publicAuthSlice = createSlice({
  name: 'publicAuth',
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.token = action.payload.token;
      state.surgeonId = action.payload.surgeonId;
      state.expiration = action.payload.expiration;
      state.roles = action.payload.roles;
    },
    setLogout: (state) => {
      state.token = null;
      state.surgeonId = null;
      state.expiration = null;
      state.roles = null;
    },
    setStart: (state) => {
      state.error = null;
      state.loading = true;
    },
    setEnd: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
  },
});

export const {setLogin, setLogout} = publicAuthSlice.actions;

export default publicAuthSlice.reducer;

// @ts-ignore as this is a middleware
export const login = (credentials: Credentials): Promise<any> => async dispatch => {
  dispatch(publicAuthSlice.actions.setStart())
  await dispatchClear(dispatch);

  try {
    const response = await authClient.login(credentials);
    dispatch(publicAuthSlice.actions.setLogin({
      token: authClient.getToken(),
      surgeonId: authClient.getSurgeonId(),
      expiration: authClient.getExpirationDate()?.unix(),
      roles: authClient.getAuthRoles(),
    }));
    dispatch(publicAuthSlice.actions.setEnd({error: null}))
    setRefreshTokenTimer(dispatch); // no need to await the response
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(publicAuthSlice.actions.setEnd({error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const logout = (): Promise<any> => async dispatch => {
  try {
    const response = await authClient.logout();
    await dispatchClear(dispatch);
    dispatch(publicAuthSlice.actions.setLogout()); // used so we can specifically hook in to resetting state globally
    return Promise.resolve(response);
  } catch (err: any) {
    await dispatchClear(dispatch);
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const registerSurgeon = (surgeon: Surgeon): Promise<any> => async dispatch => {
  dispatch(publicAuthSlice.actions.setStart());

  try {
    const response = await authClient.registerSurgeon(surgeon);
    dispatch(publicAuthSlice.actions.setEnd({error: null}))
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(publicAuthSlice.actions.setEnd({error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const registerPatient = (patient: Patient): Promise<any> => async dispatch => {
  dispatch(publicAuthSlice.actions.setStart());

  try {
    const response = await authClient.registerPatient(patient);
    dispatch(publicAuthSlice.actions.setEnd({error: null}))
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(publicAuthSlice.actions.setEnd({error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

const setRefreshTokenTimer = (dispatch: any) => {
  const time = 30 * 60 * 1000; // set to run in 30 minutes as token last 1 hour

  setTimeout(() => {
    dispatch(refreshToken());
  }, time);
}

// @ts-ignore as this is a middleware
export const refreshToken = (): Promise<any> => async dispatch => {
  if (!authClient.getToken() || !authClient.getExpirationDate()) {
    await dispatchClear(dispatch)
    return Promise.resolve();
  }

  const now = moment().unix();
  const expiresAt = authClient.getExpirationDate()?.unix() ?? now
  const expiryDiff = Math.floor(expiresAt - now);
  const fortyFiveMinutesLeft = 45 * 60 // 45 minutes * 60 to get into seconds

  if (expiryDiff <= 0 ) {
    await logout();
    return Promise.resolve();
  }

  // Expires in 60 minutes, if they still have more than 45 mins left do not reset token
  if (expiryDiff > fortyFiveMinutesLeft ) {
    return Promise.resolve();
  }

  try {
    const response = await authClient.refreshToken();
    await dispatch(publicAuthSlice.actions.setLogin({
      token: authClient.getToken(),
      expiration: authClient.getExpirationDate()?.unix(),
      roles: authClient.getAuthRoles(),
    }));
    setRefreshTokenTimer(dispatch); // no need to await the response
    dispatch(publicAuthSlice.actions.setEnd({error: null}))
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(publicAuthSlice.actions.setEnd({error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const resetPasswordRequest = (email: string): Promise<any> => async dispatch => {
  dispatch(publicAuthSlice.actions.setStart());

  try {
    const response = await authClient.resetPasswordRequest(email);
    dispatch(publicAuthSlice.actions.setEnd({error: null}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(publicAuthSlice.actions.setEnd({error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const resetPassword = (token: string, password: string): Promise<any> => async dispatch => {
  dispatch(publicAuthSlice.actions.setStart());

  try {
    const tokenResponse = await authClient.checkResetPasswordToken(token);
    const email = tokenResponse.data.email;
    const response = await authClient.resetPassword(email, password, token);
    dispatch(publicAuthSlice.actions.setEnd({error: null}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(publicAuthSlice.actions.setEnd({error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

const dispatchClear = async (dispatch: any) => {
  dispatch(publicAuthSlice.actions.setLogin({token: null, surgeonId: null, expiration: null, roles: null}))
}

type publicAuthState = {
  token: string|null
  surgeonId: string|null
  patientId: string|null
  expiration: number|null
  roles: string[]|null
  error: string|null
  loading: boolean
}
