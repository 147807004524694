import React, {FunctionComponent, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import PatientTableList from "../../components/Surgeon/PatientTableList";
import {Patient} from "../../api/Types";
import {LoadingErrorProps} from "../UI/CpContentContainer";

interface SurgeonPatientsTableListProps extends LoadingErrorProps {
  onSelect?: (patient: Patient) => void
}

const SurgeonPatientsTableList: FunctionComponent<SurgeonPatientsTableListProps> = props => {
  const classes = useStyles();
  const patients = useSelector((state: RootState) => state.surgeon.auth.patients.data);
  const error = useSelector((state: RootState) => state.surgeon.auth.patients.error);
  const loading = useSelector((state: RootState) => state.surgeon.auth.patients.loading);
  const [searchedPatients, setSearchedPatients] = useState<Patient[]|null>(null);

  const searchPatients = (searchValue: string) => {
    if (patients === null) {
      return;
    }

    const searchList = [...patients].filter((patient) => {
      return `${patient.patient_first_name} ${patient.patient_last_name}`.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    })

    setSearchedPatients(searchList);
  }

  const patientSelected = (patientId: string) => {
    if (patients === null || props.onSelect === undefined) {
      return;
    }

    const patient = patients.find((p) => p.patient_id === patientId)

    if (patient) {
      props.onSelect(patient);
    }
  }

  return <PatientTableList
    error={error}
    loading={loading}
    className={classes.section}
    patients={searchedPatients !== null ? searchedPatients : patients ?? []}
    onSearch={searchPatients}
    onSelect={props.onSelect !== undefined ? patientSelected : undefined}
  />
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  section: {
    marginRight: '1em',
    marginBottom: '1em',
  },
})));

export default SurgeonPatientsTableList;
