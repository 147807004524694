import React, {FunctionComponent} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const TermsPage: FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container item xs={12} className={classes.familyContainer}>
        <Typography variant="h4" color="textPrimary" paragraph className={classes.center}>
          <strong>Terms of Use</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          This web page represents our Terms of Use and Sale ("Agreement") regarding our website, www
          .consentplus.com ("Website"). It was last posted on 10 March 2021. The terms, "we" and
          "our" as used in this Agreement refer to ConsentPlus or Consentplus.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          We may amend this Agreement at any time by posting the amended terms on our Website. We may or
          may not post notices on the homepage of our Website when such changes occur.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          We refer to this Agreement, our Privacy Policy accessible at www.consentplus.com/ privacy ,
          and any other terms, rules, or guidelines on our Website collectively as our "Legal Terms."
          You explicitly and implicitly agree to be bound by our Legal Terms each time you access our
          Website. If you do not wish to be so bound, please do not use or access our Website.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          ConsentPlus is an interactive platform which aim to promote discussion, it is not aim to offer
          advice, educate or consult. It is design to be use by anyone that would like to learn about
          the risk and complications of surgeries and enhance communication.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Limited License</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          ConsentPlus grants you a non-exclusive, non-transferable, revocable license to access and use
          our Website in order for your own educational purposes and for you to make purchases of
          related services through our Website, strictly in accordance with our Legal Terms.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Copyrights and Trademarks</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Unless otherwise noted, all materials including without limitation, logos, brand names,
          images, designs, photographs, video clips and written and other materials that appear as part
          of our Website are copyrights, trademarks, service marks, trade dress and/or other
          intellectual property whether registered or unregistered ("Intellectual Property") owned,
          controlled or licensed by ConsentPlus. Our Website as a whole is protected by copyright and
          trade dress. Nothing on our Website should be construed as granting, by implication, estoppel
          or otherwise, any license or right to use any Intellectual Property displayed or used on our
          Website, without the prior written permission of the Intellectual Property owner. ConsentPlus
          aggressively enforces its intellectual property rights to the fullest extent of the law. The
          names and logos of ConsentPlus, may not be used in any way, including in advertising or
          publicity pertaining to distribution of materials on our Website, without prior, written
          permission from ConsentPlus. ConsentPlus prohibits use of any logo of ConsentPlus or any of
          its affiliates as part of a link to or from any Website unless ConsentPlus approves such link
          in advance and in writing. Fair use of ConsentPlus’s Intellectual Property requires proper
          acknowledgment. Other product and company names mentioned in our Website may be the
          Intellectual Property of their respective owners.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Links to Third-Party Websites</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Our Website may contain links to Websites owned or operated by parties other than ConsentPlus.
          Such links are provided for your reference only. ConsentPlus does not monitor or control
          outside Websites and is not responsible for their content. ConsentPlus’s inclusion of links to
          an outside Website does not imply any endorsement of the material on our Website or, unless
          expressly disclosed otherwise, any sponsorship, affiliation or association with its owner,
          operator or sponsor, nor does ConsentPlus’ inclusion of the links imply that ConsentPlusis
          authorized to use any trade name, trademark, logo, legal or official seal, or copyrighted
          symbol that may be reflected in the linked Website.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Content Disclaimer</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Postings on our Website are made at such times as ConsentPlus determines in its discretion.
          You should not assume that the information contained on our Website has been updated or
          otherwise contains current information. ConsentPlus does not review past postings to determine
          whether they remain accurate and information contained in such postings may have been
          superseded. The information and materials in our website are provided for your review in
          accordance with the notices, terms and conditions set forth herein. These materials are not
          guaranteed or represented to be complete, correct or up to date. These materials may be
          changed from time to time without notice.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Contracts and Legal Advice Disclaimer</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          You understand that we offer education materials as created by doctors, nurses and patients
          through our Website. You acknowledge that we are not doctors, attorneys or paralegals nor do
          we offer medical or legal advice. You acknowledge that we do not endorse any specific doctors,
          heathcare professionals, attorney or paralegal or any service as being better than another. We
          do not otherwise guarantee the medical and legal accuracy or applicability of any educational
          materials, checklist for your medical or legal needs. You will at all times consult medical
          professionals and your own doctor for medical advice and look to any attorney or paralegal
          that you select for services as to any legal claims related to such services.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>No Warranties; Exclusion of Liability; Indemnification</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Our website is operated by consentplus on an "as is," "as available" basis, without
          representations or warranties of any kind. To the fullest extent permitted by law, consentplus
          specifically disclaims all warranties and conditions of any kind, including all implied
          warranties and conditions of merchantability, fitness for a particular purpose, title and
          noninfringement for our website and any contracts and services you purchase through it.
          consentplus shall not have any liability or responsibility for any errors or omissions in the
          content of our website, for contracts or services sold through our website, for your action or
          inaction in connection with our website or for any damage to your computer or data or any
          other damage you may incur in connection with our website. Your use of our website and any
          contracts or services are at your own risk. In no event shall either consentplus or their
          agents be liable for any direct, indirect, punitive, incidental, special or consequential
          damages arising out of or in any way connected with the use of our website, contracts and
          services purchased through our website, the delay or inability to use our website or otherwise
          arising in connection with our website, contracts or related services, whether based on
          contract, tort, strict liability or otherwise, even if advised of the possibility of any such
          damages. In no event shall consentplus liability for any damage claim exceed the amount paid
          by you to consentplus for the transaction giving rise to such damage claim.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Some jurisdictions do not allow the exclusion or limitation of incidental or consequential
          damages, so the above exclusion may not apply to you.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Without limiting the foregoing, consentplus  do not represent or warrant that the information
          on the webite is accurate, complete, reliable, useful, timely or current or that our website
          will operate without interruption or error.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          You agree that all times, you will look to attorneys from whom you purchase services for any
          claims of any nature, including loss, damage, or warranty. Consentplus  and their respective
          affiliates make no representation or guarantees about any contracts and services offered
          through our website.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Consentplus makes no representation that content provided on our website, contracts, or
          related services are applicable or appropriate for use in all jurisdictions.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Indemnification</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          You agree to defend, indemnify and hold ConsentPlus harmless from and against any and all
          claims, damages, costs and expenses, including attorneys' fees, arising from or related to
          your use of our Website or any Contracts or Services you purchase through it.
        </Typography>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  familyContainer: {
    padding: '2em',
    [theme.breakpoints.up('sm')]: {
      padding: '2em 5em',
    },
  },
  center: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '1em',
  },
  header: {
    width: '100%',
  },
})));

export default TermsPage;
