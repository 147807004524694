import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {login} from "../../store/public/publicAuthSlice";
import CpTextField from "../../components/UI/CpTextField";
import {withStyles, createStyles, Theme, WithStyles} from "@material-ui/core/styles";
import {ExitToApp} from "@material-ui/icons";
import CpForm, {CpFormState} from "../UI/CpForm";
import {loginSchema} from "../../schemas/publicSchemas";
import CpButton from "../UI/CpButton";
import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import CpLoading from "../UI/CpLoading";
import {Typography} from "@material-ui/core";
import {authClient} from "../../api/Container";

interface PatientLoginFormProps extends WithStyles, RouteComponentProps {
  login: typeof login;
  loading: boolean;
}

class PatientLoginForm extends PureComponent<PatientLoginFormProps, CpFormState> {
  state: CpFormState = {
    fields: {
      email: null,
      password: null,
    },
    errors: null,
  }

  submitHandler = async (e: React.FormEvent) => {
    this.setState({errors: []});
    const fields = {...this.state.fields};

    try {
      await loginSchema.validateAsync(
        fields,
        { abortEarly: false }
      );

      await this.props.login({email: fields.email ?? '', password: fields.password ?? ''});
      const roles = authClient.getAuthRoles(); // fetch roles after logging in
      if (roles !== null && roles.includes('SURGEON') && !roles.includes('PATIENT')) {
        this.props.history.push('/surgeon/dashboard');
        return;
      }
      this.props.history.push('/patient/dashboard');
    } catch (e: any) {
      this.setState({errors: e.details});
    }
  }

  inputChangeHandler = (inputName: string, value: any) => {
    const fields = {...this.state.fields};
    fields[inputName] = value;

    this.setState({fields: fields});
  }

  render() {
    const { classes } = this.props;

    return (
      <CpForm onSubmit={this.submitHandler} name="Patient Portal Login">
        <CpTextField
          id="login-email"
          className={classes.field}
          type="email"
          label="Email"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('email', e.target.value)}
          error={getJoiErrorMessage('email', this.state.errors) !== null}
          helperText={getJoiErrorMessage('email', this.state.errors)}
        />
        <CpTextField
          id="login-password"
          className={classes.field}
          type="password"
          label="Password"
          variant="outlined"
          onChange={(e) => this.inputChangeHandler('password', e.target.value)}
          error={getJoiErrorMessage('password', this.state.errors) !== null}
          helperText={getJoiErrorMessage('password', this.state.errors)}
        />
        <CpButton
          disabled={this.props.loading}
          type="submit"
          className={classes.field}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<ExitToApp />}
          data-testid="login-button"
        >
          Login
        </CpButton>
        <Link to="/password-reset" className={classes.passwordReset}>
          <Typography variant="caption" paragraph> Have you forgotten your password?</Typography>
        </Link>
        <CpLoading loading={this.props.loading} />
      </CpForm>
    )
  }
}

const styles = (theme: Theme) => createStyles({
  field: {
    margin: '1.2em',
  },
  passwordReset: {
    margin: 'auto',
  }
});

const mapStateToProps = (state: any) => {
  return {
    loading: state.public.auth.loading,
  };
};

const actionCreators = { login };

export default connect( mapStateToProps, actionCreators )(withStyles(styles)(withRouter(PatientLoginForm)));
