import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {QuestionCategory} from "../../../api/Types";
import {submitAssessmentAnswer} from "../../../store/patient/patientAssessmentSlice";
import {useDispatch} from "react-redux";
import AssessmentQuestionAnswer from "../AssessmentQuestionAnswer";
import PreviousNextButtons from "../Assessment/PreviousNextButtons";

interface AssessmentQuestionAnswerContainerProps {
  surgeryId: string
  assessment: QuestionCategory
  completed?: boolean
}

const AssessmentQuestionAnswerContainer: FunctionComponent<AssessmentQuestionAnswerContainerProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentQuestionKey, setCurrentQuestionKey] = React.useState<number|null>(null); // null so we know to setup

  const answerHandler = (answer: string) => {
    if (currentQuestionKey === null) {
      return Promise.reject();
    }

    const question = props.assessment.question_answers[currentQuestionKey]
    dispatch(submitAssessmentAnswer(props.surgeryId, props.assessment.category, question, answer))
    setTimeout(() => {
      nextQuestionHandler();
    }, 400);
  }

  const nextQuestionHandler = async () => {
    const key = (currentQuestionKey ?? 0) + 1;

    if (key !== props.assessment.question_answers.length) {
      setCurrentQuestionKey(props.assessment.question_answers[key] ? key : 0)
    }
  }

  const previousQuestionHandler = async () => {
    const key = (currentQuestionKey ?? 0) - 1;
    setCurrentQuestionKey(props.assessment.question_answers[key] ? key : props.assessment.question_answers.length - 1)
  }

  const setLatestQuestion = () => {
    for (const [key, question] of Object.entries(props.assessment.question_answers)) {
      if (question.patient_answer === null) {
        setCurrentQuestionKey(parseInt(key))
        break;
      }
    }

    if (currentQuestionKey === null) {
      setCurrentQuestionKey(0);
    }
  }

  if (currentQuestionKey === null) {
    setLatestQuestion();
  }

  return (
    <>
      <div className={classes.assessmentContainer}>
        <AssessmentQuestionAnswer
          completed={props.completed}
          question={props.assessment.question_answers[currentQuestionKey ?? 0]}
          answerHandler={answerHandler}
          questionPosition={currentQuestionKey !== null ? (currentQuestionKey + 1) : 1}
          questionTotalCount={props.assessment.question_answers.length}
        />
      </div>
      <PreviousNextButtons
        previousQuestion={previousQuestionHandler}
        nextQuestion={nextQuestionHandler}
        showNextButton={((currentQuestionKey ?? 0) + 1) !== props.assessment.question_answers.length}
        showPreviousButton={(currentQuestionKey ?? 0) !== 0}
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    assessmentContainer: {
      padding: '1em',
      [theme.breakpoints.down('sm')]: {
        padding: '1em 0',
      }
    },
  }),
);

export default AssessmentQuestionAnswerContainer;
