import {combineReducers, configureStore, ThunkAction, Action} from '@reduxjs/toolkit'
import patientCombineReducers from "./patient/patientCombineReducers";
import surgeonCombineReducers from "./surgeon/surgeonCombineReducers";
import adminCombineReducers from "./admin/adminCombineReducers";
import publicCombineReducers from "./public/publicCombineReducers";

// create slice uses library "immer" that allows us to make mutations in our reducers
// under normal reducer / state management we should not directly mutate state!
const appReducer = combineReducers({
  patient: patientCombineReducers(),
  surgeon: surgeonCombineReducers(),
  public: publicCombineReducers(),
  admin: adminCombineReducers(),
});

const rootReducer = (state: any, action: Action) => {
  if (action.type === 'publicAuth/setLogout') {
    state = undefined;
  }

  return appReducer(state, action)
}

const store = configureStore({reducer: rootReducer})

export type RootState = ReturnType<typeof appReducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export type Error = string|null
export type Loading = boolean

export default store

export const getFreshStore = () => {
  return configureStore({reducer: rootReducer});
}
