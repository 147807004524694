import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import CpButton from "../../../UI/CpButton";
import {ArrowForward} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {navigateToLatestStep, setStarted} from "../../../../store/patient/patientConsentSlice";
import {useParams} from "react-router-dom";

const StartConsentProcess: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const startHandler = async () => {
    await dispatch(setStarted());
    await dispatch(navigateToLatestStep(params.id));
  }

  return (
    <div className={classes.descriptionContainer}>
      <Typography paragraph variant="h5"><strong>Start Your Consent Process</strong></Typography>
      <div className={classes.descriptionText}>
        <Typography paragraph variant="body1">
          Welcome to the start of your consent process. You cannot get anything wrong as this process is bespoke to you, there are no silly questions.
          If there is anything that you are unsure about please ask; it is better to discuss your questions before the consent form is signed.
        </Typography>
        <Typography paragraph variant="body1">
          You can stop and come back at anytime. A save button will appear in the top left corner so please save your progress before logging off to ensure your progress is not lost.
        </Typography>
        <Typography paragraph variant="body1">
          There will be an opportunity to send notes to your surgeon before you sign, but the assessment must be completed first. We really hope you enjoy the process and learn a lot about your surgery.
        </Typography>
      </div>
      <CpButton
        className={classes.startButton}
        variant="contained"
        color="primary"
        size="large"
        endIcon={<ArrowForward />}
        onClick={() => startHandler()}
      >
        Start
      </CpButton>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionContainer: {
      marginTop: '3em',
      padding: '3em 6em',
      margin: '0 5em',
      textAlign: 'center',
      backgroundColor: '#fff',
      boxShadow: '2px 2px 5px 5px #dbdbdb',
      [theme.breakpoints.down('sm')]: {
        margin: '0',
        marginTop: '1.5em',
        padding: '3em',
      },
    },
    descriptionText: {
      width: '100%',
      margin: '3em 0',
      textAlign: 'justify',
    },
    startButton: {
      width: '40%',
      marginTop: '2em',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginTop: '1em',
      },
      [theme.breakpoints.down('xs')]: {
        width: '60%',
      },
    }
  }),
);

export default StartConsentProcess;
