import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Divider, Grid, Typography} from "@material-ui/core";
import {RootState} from "../../store/store";
import {useParams} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {clearPatientSurgeries, getPatientSurgeries} from "../../store/surgeon/surgeonSurgerySlice";
import {clearPatient, getSurgeonPatient} from "../../store/surgeon/surgeonAuthSlice";
import SurgeonPatientSurgeries from "../../components/Surgeon/SurgeonPatientSurgeries";
import PatientDetails from "../../components/Surgeon/PatientDetails";

type PatientParamTypes = {
  id: string
}

const SurgeonPatientAccount: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<PatientParamTypes>();
  const patientSurgeries = useSelector((state: RootState) => state.surgeon.surgery.currentPatientSurgeries);
  const currentPatient = useSelector((state: RootState) => state.surgeon.auth.currentPatient.data);
  const error = useSelector((state: RootState) => state.surgeon.auth.currentPatient.error);
  const loading = useSelector((state: RootState) => state.surgeon.auth.currentPatient.loading);

  useEffect(() => {
    if (params.id !== currentPatient?.patient_id ?? 0) {
      dispatch(clearPatient());
      dispatch(getSurgeonPatient(params.id));
    }

    if (params.id !== patientSurgeries.data?.[0].patient_id ?? 0) {
      dispatch(clearPatientSurgeries());
      dispatch(getPatientSurgeries(params.id));
    }
    // eslint-disable-next-line
  }, []);

  if (params.id  === undefined || params.id === null) {
    return <Typography paragraph>Please specify a patient to view their account</Typography>
  }

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Patient Account</Typography>
        <Divider />
      </div>
      <Grid container>
        <Grid item xs={12} md={8} className={classes.gridSection}>
          <SurgeonPatientSurgeries />
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridSection}>
          <PatientDetails error={error} loading={loading} patient={currentPatient} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  section: {
    marginRight: '1em',
    marginBottom: '1em',
  },
  gridSection: {
    paddingBottom: '1em',
  },
})));

export default SurgeonPatientAccount;
