import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {getCurrentSurgery, setCurrentSurgery} from "../../store/patient/patientSurgerySlice";
import CpContentContainer from "../../components/UI/CpContentContainer";
import {useParams} from "react-router-dom";

interface PatientSurgeryProps {
  id: string,
}

const PatientConsentContainer: FunctionComponent<{}> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<PatientSurgeryProps>();
  const currentSurgery = useSelector((state: RootState) => state.patient.surgery.current.data);
  const surgeries = useSelector((state: RootState) => state.patient.surgery.surgeries.data);
  const error = useSelector((state: RootState) => state.patient.surgery.current.error);
  const loading = useSelector((state: RootState) => state.patient.surgery.current.loading);

  useEffect(() => {
    if (currentSurgery !== null && currentSurgery.surgery_id === params.id) {
      return;
    }

    const surgery = surgeries?.find(surgery => surgery.surgery_id === params.id)
    if (surgery) {
      dispatch(setCurrentSurgery(surgery))
      return;
    }

    if (!surgery) {
        dispatch(getCurrentSurgery(params.id))
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.header}>
      <CpContentContainer error={error} loading={loading}>
        {props.children}
      </CpContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
})));

export default PatientConsentContainer;
