import React, {FunctionComponent} from "react";
import {getJoiErrorMessage} from "../../errors/joiErrorHandler";
import CpButton from "../UI/CpButton";
import {NavigateNext} from "@material-ui/icons";
import {FormHelperText} from "@material-ui/core";
import CpForm from "../UI/CpForm";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ValidationErrorItem} from "joi";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";
import {dateSchema} from "../../schemas/patientSchemas";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CpDatePicker from "../UI/CpDatePicker";
import {Moment} from "moment/moment";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";

interface SelectSurgeryDateFormProps extends LoadingErrorProps {
  onSubmit: (date: Moment) => void
}

const SelectSurgeryDateForm: FunctionComponent<SelectSurgeryDateFormProps> = props => {
  const classes = useStyles();
  const [joiErrors, setJoiErrors] = React.useState<ValidationErrorItem[]|null>(null);
  const [date, setDate] = React.useState<null|Moment>(null);
  const dateFormat = 'DD-MM-YYYY';

  const submitHandler = async () => {
    setJoiErrors(null)
    try {
      await dateSchema.validateAsync(
        { date_of_surgery: date?.toDate() },
        { abortEarly: false }
      );

      if (date === null) {
        return;
      }

      return props.onSubmit(date);
    } catch (e: any) {
      if (e.status === undefined) {
        setJoiErrors(e.details);
        return;
      }
    }
  }

  const dateChangeHandler = (date: MaterialUiPickersDate) => {
    setDate(date);
  }

  return <CpForm onSubmit={submitHandler} name="Expected Date Of Surgery If Known">
    <div className={classes.field}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CpDatePicker
          margin="normal"
          id="user-date-of-surgery"
          label="Date of Surgery (DD-MM-YYYY)"
          format={dateFormat}
          style={{width: '100%', margin: 0}}
          inputVariant="outlined"
          value={date}
          onChange={dateChangeHandler}
          openTo="year"
          KeyboardButtonProps={{
            'aria-label': 'change date of surgery',
          }}
        />
        <FormHelperText style={{color: 'red', marginLeft: '1.2em'}}>{getJoiErrorMessage('date_of_surgery', joiErrors)}</FormHelperText>
      </MuiPickersUtilsProvider>
    </div>
  <CpButton
    disabled={(props.loading ?? false)}
    type="submit"
    className={classes.field}
    variant="contained"
    color="primary"
    size="large"
    startIcon={<NavigateNext />}
    data-testid="surgery-date-button"
  >
    Submit
  </CpButton>
  <CpContentContainer error={props.error} loading={props.loading} />
</CpForm>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  field: {
    margin: '1.2em',
  },
})));

export default SelectSurgeryDateForm;
