import {createSlice} from "@reduxjs/toolkit";
import {authClient} from "../../api/Container";
import {Surgeon, Patient} from "../../api/Types";
import {Error, Loading} from "../store";

const initialState: surgeonAuthState = {
  account: {
    error: null,
    loading: false,
    data: null
  },
  patients: {
    error: null,
    loading: false,
    data: null
  },
  currentPatient: {
    error: null,
    loading: false,
    data: null
  },
}

export const surgeonAuthSlice = createSlice({
  name: 'surgeonAuth',
  initialState,
  reducers: {
    setSurgeon: (state, action) => {
      state.account.data = action.payload.account;
      state.account.error = null;
      state.account.loading = false;
    },
    setPatients: (state, action) => {
      state.patients.data = action.payload.patients;
      state.patients.error = null;
      state.patients.loading = false;
    },
    setCurrentPatient: (state, action) => {
      state.currentPatient.data = action.payload.patient;
      state.currentPatient.error = null;
      state.currentPatient.loading = false;
    },
    clearPatient: (state) => {
      state.currentPatient = initialState.currentPatient;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default surgeonAuthSlice.reducer;

// @ts-ignore as this is a middleware
export const initialiseSurgeonAccount = (): Promise<any> => async dispatch => {
  dispatch(surgeonAuthSlice.actions.setStart({index: 'account'}))

  try {
    const response = await authClient.getSurgeon(authClient.getSurgeonId());
    const surgeon = response.data.surgeon;
    dispatch(surgeonAuthSlice.actions.setSurgeon({account: surgeon}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonAuthSlice.actions.setEnd({index: 'account', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getListOfSurgeonPatients = (): Promise<any> => async dispatch => {
  dispatch(surgeonAuthSlice.actions.setStart({index: 'patients'}))

  try {
    const response = await authClient.getListOfPatients();
    const sortedPatients = [...response.data.patients];
    sortedPatients.sort((a: Patient, b: Patient) => (a.patient_created_at ?? 0) > (b.patient_created_at ?? 0) ? -1 : 1);
    dispatch(surgeonAuthSlice.actions.setPatients({patients: sortedPatients}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonAuthSlice.actions.setEnd({index: 'patients', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getSurgeonPatient = (patientId: string): Promise<any> => async dispatch => {
  dispatch(surgeonAuthSlice.actions.setStart({index: 'currentPatient'}))

  try {
    const response = await authClient.getPatient(patientId);
    dispatch(surgeonAuthSlice.actions.setCurrentPatient({patient: response.data.patient}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(surgeonAuthSlice.actions.setEnd({index: 'currentPatient', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const findPatientByEmail = (email: string): Promise<any> => async dispatch => {
  try {
    const response = await authClient.findPatientByEmail(email);
    return Promise.resolve(response);
  } catch (err: any) {
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const clearPatient = (): Promise<any> => async dispatch => {
  dispatch(surgeonAuthSlice.actions.clearPatient());
  return Promise.resolve();
}

type surgeonAuthState = {
  account: {
    error: Error
    loading: Loading
    data: Surgeon|null
  }
  patients: {
    error: Error
    loading: Loading
    data: Patient[]|null
  }
  currentPatient: {
    error: Error
    loading: Loading
    data: Patient|null
  }
}
