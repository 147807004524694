import React, {FunctionComponent, useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";
import HospitalTableList from "../Surgeon/HospitalTableList";
import {getHospitals} from "../../store/surgeon/surgeonSurgerySlice";
import {Check} from "@material-ui/icons";
import CpButton from "../UI/CpButton";
import {Typography} from "@material-ui/core";

interface SelectHospitalFormProps extends LoadingErrorProps {
  onSubmit: (hospital: string) => void
}

const SelectHospitalForm: FunctionComponent<SelectHospitalFormProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hospital, setHospital] = React.useState<string|null>(null);
  const error = useSelector((state: RootState) => state.surgeon.surgery.hospitals.error);
  const loading = useSelector((state: RootState) => state.surgeon.surgery.hospitals.loading);
  const hospitals = useSelector((state: RootState) => state.surgeon.surgery.hospitals.data);

  useEffect(() => {
    if (hospitals === null) {
      dispatch(getHospitals());
    }
    // eslint-disable-next-line
  }, []);

  const onSelectHospitalHandler = (hospitalId: string) => {
    if (hospital === hospitalId) {
      setHospital(null);
      return;
    }

    setHospital(hospitalId);
  }

  const submitHandler = async () => {
    if (hospital === null) {
      return;
    }

    props.onSubmit(hospital);
  }

  if (hospitals === null) {
    return null;
  }

  return <>
    <CpContentContainer error={error} loading={loading} />
    <CpButton
      type="submit"
      disabled={hospital === null}
      onClick={submitHandler}
      className={classes.confirmButton}
      variant="contained"
      color="primary"
      size="large"
      startIcon={<Check />}
    >
      Confirm
    </CpButton>
    {hospital !== null ? <Typography variant="h6" paragraph>
      Selected: <strong>{hospitals.filter((h) => h.hospital_id === hospital)[0].hospital_name}</strong>
    </Typography> : null}
    <HospitalTableList selected={hospital} hospitals={hospitals} onSelectHospital={onSelectHospitalHandler}/>
  </>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  field: {
    margin: '1.2em',
  },
  confirmButton: {
    display: 'flex',
    justifySelf: 'center',
    margin: '2em auto',
  },
})));

export default SelectHospitalForm;
