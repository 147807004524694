import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {QuestionAnswer} from "../../api/Types";
import {Grid, Typography} from "@material-ui/core";
import AssessmentAnswer from "./Assessment/AssessmentAnswer";
import clsx from "clsx";
import {CheckCircle} from "@material-ui/icons";

type AssessmentQuestionAnswerProps = {
  question: QuestionAnswer
  questionPosition: number
  questionTotalCount: number
  answerHandler: (answer: string) => void,
  completed?: boolean
}

const AssessmentQuestionAnswer: FunctionComponent<AssessmentQuestionAnswerProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={ clsx({
        [classes.answered]: props.question.patient_answer !== null,
        [classes.notAnswered]: props.question.patient_answer === null,
      })} variant="body1" paragraph>
        Question {props.questionPosition} / {props.questionTotalCount}
        {props.question.patient_answer ? <CheckCircle className={classes.circleIcon} /> : null}
      </Typography>
      <Grid className={[classes.container, classes.question].join(' ')} item xs={12}>
        <Typography variant="body1">{props.question.question_text}</Typography>
        <AssessmentAnswer completed={props.completed} answerHandler={props.answerHandler} question={props.question} />
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1em',
    },
    container: {
      borderRadius: '5px',
      boxShadow: '2px 2px 5px 5px #dbdbdb',
      backgroundColor: '#fff',
      padding: '2em 1em',
      textAlign: 'center'
    },
    question: {
      width: '100%',
    },
    answered: {
      position: 'relative',
      top: '-7px',
      height: '25px',
      color: '#009A49',
    },
    notAnswered: {
      height: '25px',
      color: 'red',
    },
    circleIcon: {
      position: 'relative',
      top: '6px',
      marginLeft: '10px',
      fontSize: '23px',
    },
  }),
);

export default AssessmentQuestionAnswer;
