import React, {FunctionComponent} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Paper, Tab, Tabs} from "@material-ui/core";
import {AddCircle, Check, List, Search} from "@material-ui/icons";
import SurgeonPatientsTableList from "../Surgeon/SurgeonPatientsTableList";
import FindPatientByEmailForm from "./FindPatientByEmailForm";
import CreatePatientForm from "../Surgeon/CreatePatientForm";
import PatientDetails from "../Surgeon/PatientDetails";
import {Patient} from "../../api/Types";
import CpButton from "../UI/CpButton";

type FindOrCreatePatientFormProps = {
  onSubmit: (patientId: string) => void
}

const FindOrCreatePatientForm: FunctionComponent<FindOrCreatePatientFormProps> = props => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [patient, setPatient] = React.useState<null|Patient>(null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const selectPatient = (patient: Patient) => {
    setPatient(patient);
  }

  const confirmPatient = () => {
    if (!patient || !patient.patient_id) {
      return;
    }

    props.onSubmit(patient.patient_id);
  }

  return (
    <>
      <Paper square className={classes.tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab icon={<List />} label="CURRENT LIST" />
          <Tab icon={<Search />} label="FIND BY EMAIL" />
          <Tab icon={<AddCircle />} label="CREATE ACCOUNT" />
        </Tabs>
      </Paper>
      {patient !== null
        ? <div className={classes.confirmPatientContainer}>
          <PatientDetails patient={patient} />
          <CpButton
            type="submit"
            onClick={confirmPatient}
            className={classes.confirmButton}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Check />}
          >
            Confirm
          </CpButton>
        </div> : null}
      <>
        { value === 0 ? <SurgeonPatientsTableList onSelect={selectPatient} />
        : value === 1 ? <FindPatientByEmailForm onSelect={selectPatient} />
        : <CreatePatientForm onCreate={selectPatient} />
        }
      </>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  tabs: {
    flexGrow: 1,
    maxWidth: 750,
    margin: 'auto',
    marginBottom: '2em',
  },
  confirmPatientContainer: {
    margin: '0 10em',
  },
  confirmButton: {
    display: 'flex',
    justifySelf: 'center',
    margin: '1em auto 3em',
  },
})));

export default FindOrCreatePatientForm;
