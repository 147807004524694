import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {QuestionAnswer} from "../../../api/Types";
import CpSelectContainer from "../../UI/CpSelectContainer";
import {Select} from "@material-ui/core";

type AnswerSelectProps = {
  question: QuestionAnswer
  answerHandler: (answer: string) => void,
  disabled?: boolean
}

const AnswerSelect: FunctionComponent<AnswerSelectProps> = props => {
  const classes = useStyles();

  const inputHandler = (answer: any) => {
    if (typeof answer === 'string') {
      props.answerHandler(answer)
    }
  }

  return (
    <div className={classes.answerContainer}>
      <CpSelectContainer label={props.question.question_heading} id="answer-select">
        <Select
          disabled={props.disabled ?? false}
          native
          value={props.question.patient_answer ?? props.question.question_possible_answers[0]}
          label={props.question.question_heading}
          onChange={(e) => inputHandler(e.target.value)}
          inputProps={{name: 'answer', id: 'assessment-select'}}
        >
          {props.question.question_possible_answers.map((answer) => {
            return <option key={answer} aria-label={answer} value={answer}>{answer}</option>
          })}
        </Select>
      </CpSelectContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    answerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    answer: {
      [theme.breakpoints.down('sm')]: {
        margin: '0.5em',
      }
    },
  }),
);

export default AnswerSelect;
