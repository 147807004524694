import React, {FunctionComponent} from 'react';
import {InputBase} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import {createStyles, fade, makeStyles, Theme} from "@material-ui/core/styles";

type CpSearchProps = {
  className?: string,
  onSearch: (searchText: string) => void,
}

const CpSearch: FunctionComponent<CpSearchProps> = props => {
  const classes = useStyles();

  return (
    <div className={props.className}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Search />
        </div>
        <InputBase
          placeholder="Search…"
          onChange={(e) => props.onSearch(e.target.value)}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.primary.main, 0.10),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.20),
    },
    width: '100%',
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    padding: theme.spacing(0, 2),
  },
  inputRoot: {
    color: theme.palette.primary.main,
  },
  inputInput: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
})));


export default CpSearch;
