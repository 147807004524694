import React, {FunctionComponent} from 'react';
import {FormControl, InputLabel} from "@material-ui/core";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";

type CpSelectProps = {
  className?: string
  id: string
  label: string
}

const CpSelectContainer: FunctionComponent<CpSelectProps> = props => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={props.className}>
      <InputLabel htmlFor={props.id} classes={{root: classes.label}} color="primary">{props.label}</InputLabel>
      {props.children}
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  label: {
    color: theme.palette.text.primary,
    opacity: 0.8,
  },
})));

export default CpSelectContainer;
