import React, {FunctionComponent} from "react";
import {Switch} from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import SurgeonRoutes from "./SurgeonRoutes";
import AdminRoutes from "./AdminRoutes";
import PatientRoutes from "./PatientRoutes";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import TrackRoutes from "./TrackRoutes";
import ReactGA from "react-ga";

const Routes: FunctionComponent<{}> = (props) => {
  const roles = useSelector((state: RootState) => state.public.auth.roles);
  const authRoutes = [];

  ReactGA.initialize('UA-88983937-1');
  TrackRoutes(); // sets up google analytics

  if (roles !== null && roles.includes('PATIENT')) {
    authRoutes.push(PatientRoutes())
  }

  if (roles !== null && roles.includes('SURGEON')) {
    authRoutes.push(SurgeonRoutes())
  }

  if (roles !== null && roles.includes('ADMIN')) {
    authRoutes.push(AdminRoutes())
  }

  return (
    <Switch>
      {authRoutes}
      {PublicRoutes()}  {/* always keep at the bottom as a fall back */}
    </Switch>
  );
}

export default Routes;
