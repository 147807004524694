import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardHeader, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Surgery} from "../../api/Types";
import {downloadConsentForm, previewConsentForm} from "../../store/surgeon/surgeonConsentFormSlice";
import CpContentContainer from "../UI/CpContentContainer";
import {GetApp} from "@material-ui/icons";
import {RootState} from "../../store/store";
import {findConsentForm} from "../../store/surgeon/surgeonConsentFormSlice";

type SurgeryConsentFormSectionProps = {
  surgery: Surgery
  onClick?: () => void
  minHeight?: number
}

const SurgeryConsentFormSection: FunctionComponent<SurgeryConsentFormSectionProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const consentForm = useSelector((state: RootState) => state.surgeon.consentForm.current.data);
  const [previewError, setPreviewError] = React.useState<string|null>(null);
  const [previewPdf, setConsentFormPreview] = React.useState<string|null>(null);

  useEffect(() => {
    if (consentForm === null || consentForm.surgery_id !== props.surgery.surgery_id) {
      dispatch(findConsentForm(props.surgery.surgery_id)).then((response) => {
        if (response.data.consent_form !== null) {
          getConsentFormPreview();
        } else {
          setConsentFormPreview(null);
          setPreviewError('Please make sure the patient has completed the consent form process');
        }
      });
    } else if(previewPdf === null) {
      getConsentFormPreview();
    }
    // eslint-disable-next-line
  }, [props.surgery.surgery_id]);

  const getConsentFormPreview = () => {
    dispatch(previewConsentForm(props.surgery.surgery_id)).then(filePath => {
      setConsentFormPreview(filePath)
    }).catch(() => {
      setConsentFormPreview(null);
      setPreviewError('Please make sure the patient has completed the consent form process');
    });
  }

  const downloadConsentFormHandler = () => {
    dispatch(downloadConsentForm(props.surgery.surgery_id));
  }

  const downloadAction = consentForm !== null && consentForm.surgeon_signed && consentForm.patient_signed
    ? <Tooltip title="Download Consent Form">
        <IconButton disabled={!consentForm.consented} onClick={downloadConsentFormHandler} aria-label="view">
          <GetApp />
        </IconButton>
      </Tooltip>
    : <Typography variant="body1" paragraph>Download unavailable</Typography>;

  return (
    <Card className={classes.cardSection}>
      <CardHeader titleTypographyProps={{onClick: props.onClick, className: props.onClick ? classes.cardHeader : undefined}} title="Consent Form Preview" action={downloadAction} />
        {consentForm !== null && !consentForm.surgeon_signed && props.surgery.surgery_id === consentForm.surgery_id
          ? <Typography paragraph align="center"> This consent form is ready to be signed</Typography>
          : null
        }
        <Grid container direction="row" className={classes.cardContent}>
          {previewPdf === null
            ? <CpContentContainer error={previewError} loading={previewError === null} />
            : <iframe title="Consent Form preview" src={previewPdf}
                className={classes.previewIframe}
                style={{minHeight: props.minHeight ? `${props.minHeight}px` : '500px'}}
            />
          }
          {props.children}
        </Grid>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  cardSection: {
    marginRight: '1em',
  },
  cardContent: {
    padding: '1em',
  },
  cardHeader: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  previewIframe: {
    display: 'block',
    height: '100%',
    width: '100%',
    minHeight: '500px',
    maxWidth: '750px',
    margin: 'auto',
  },
})));

export default SurgeryConsentFormSection;
