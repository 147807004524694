import Joi from "joi";
import {mobileNumberRegex} from "./publicSchemas";

const linkUniqueCodeRegex = '^(?=.*[A-Z])(?=.*\\d)[A-Z\\d]{9}$';

export const linkConsentProcessSchema = Joi.object({
  unique_code: Joi.string()
    .label('Unique Code')
    .pattern(new RegExp(linkUniqueCodeRegex))
    .required(),
});

export const emailSchema = Joi.object({
  email: Joi.string()
    .label('Email Address')
    .email({ tlds: false })
    .required(),
});

export const createPatientSchema = Joi.object({
  title: Joi.string()
    .label('Title')
    .allow(null, ''), // makes it optional
  first_name: Joi.string()
    .label('First Name')
    .required(),
  last_name: Joi.string()
    .label('Last Name')
    .required(),
  email: Joi.string()
    .label('Email Address')
    .email({ tlds: false })
    .required(),
  mobile_number: Joi.string()
    .label('Mobile Number')
    .pattern(new RegExp(mobileNumberRegex))
    .allow(null, ''), // makes it optional
});

export const consentSchema = Joi.object({
  signature_name: Joi.string()
    .label('Surgeon Name')
    .required(),
  signature: Joi.string()
    .label('Signature')
    .required()
    .valid(Joi.ref('signature_name')),
});
