import React, {FunctionComponent, useEffect} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {FormControlLabel, Grid, Typography} from "@material-ui/core";
import {ConsentStep} from "../../../../api/Types";
import CpContentContainer from "../../../UI/CpContentContainer";
import CpButton from "../../../UI/CpButton";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {findCertificate, previewCertificate} from "../../../../store/patient/patientCertificateSlice";
import CpCheckbox from "../../../UI/CpCheckbox";
import {Chat} from "@material-ui/icons";

interface CertificateProcessProps {
  step: ConsentStep
}

const CertificateProcess: FunctionComponent<CertificateProcessProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const [consented, setConsented] = React.useState(false);
  const [previewError, setPreviewError] = React.useState<string|null>(null);
  const [previewPdf, setCertificatePreview] = React.useState<string|null>(null);
  const certificate = useSelector((state: RootState) => state.patient.certificate.current.data);
  const error = useSelector((state: RootState) => state.patient.certificate.current.error);
  const loading = useSelector((state: RootState) => state.patient.certificate.current.loading);

  useEffect(() => {
    let mounted = true;
    if (certificate === null) {
      dispatch(findCertificate(params.id));
    }

    if (previewPdf === null || certificate === null || certificate.surgery_id !== params.id) {
      dispatch(previewCertificate(params.id)).then(filePath => {
        if (mounted) {
          setCertificatePreview(filePath)
        }
      }).catch(() => {
        if (mounted) {
          setPreviewError('We were unable to download your certificate at this time');
        }
      });
    }

    return () => { mounted = false };
    // eslint-disable-next-line
  }, [params.id]);

  const submitHandler = async (e: React.FormEvent) => {
    await dispatch(props.step.save(params.id, consented));
    history.push(`/patient/surgery/${params.id}`);
  }

  const consentChangeHandler = (value: boolean) => {
    setConsented(value);
  }

  const navigateNoteHandler = () => {
    history.push(`/patient/notes/${params.id}`);
  }

  return (
    <>
      {certificate !== null && certificate.consented
        ? <Grid item xs={12} className={classes.previewContainer}>
            <Typography className={classes.certificateExistsText} variant="h5">You have completed your certificate</Typography>
            {previewPdf === null
              ? <CpContentContainer error={previewError} loading={previewError === null} />
              : <iframe title="Certificate preview" src={previewPdf} className={classes.previewIframe} />
            }
          </Grid>
        : <div className={classes.descriptionContainer}>
          <Typography paragraph variant="h5"><strong>{props.step.name}</strong></Typography>
          <Grid xs={12} item container>
            <Grid item xs={12} md={5}>
              <Typography paragraph variant="h6"><strong>Create a note</strong></Typography>
              <Typography paragraph variant="body1">
                Before completing your certificate you can create notes for your surgeon. Your first note will be added to your certificate, so make it count!
              </Typography>
              <CpButton
                variant="contained"
                color="primary"
                className={classes.noteButton}
                classes={{containedPrimary: classes.noteButtonColour}}
                size="large"
                startIcon={<Chat />}
                onClick={navigateNoteHandler}
              >
                Create Notes
              </CpButton>
            </Grid>
            <Grid item xs={12} md={7} className={classes.previewContainer}>
              <Typography paragraph variant="h6"><strong>Certificate Preview</strong></Typography>
              <Typography paragraph variant="body1">
                Your surgeon will not be able to see this until you consent and complete below.
              </Typography>
              {previewPdf === null
                ? <CpContentContainer error={previewError} loading={previewError === null} />
                : <iframe title="Certificate preview" src={previewPdf} className={classes.previewIframe} />
              }
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="column" alignItems="center" justify="space-between" className={classes.consentContainer}>
            <FormControlLabel
              control={
                <CpCheckbox
                  id="certificate-consent-checkbox"
                  checked={consented}
                  onChange={(e) => consentChangeHandler(e.target.checked)}
                />
              }
              label="I understand the risks and benefits of surgery and have also considered non operative options"
            />
            <CpButton
              disabled={!consented || loading}
              variant="contained"
              className={classes.completeButton}
              color="primary"
              size="large"
              onClick={submitHandler}
            >
              Complete
            </CpButton>
          </Grid>
          <CpContentContainer error={error} loading={loading} />
        </div>
      }
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionContainer: {
      width: '100%',
      textAlign: 'center',
      padding: '3em',
    },
    consentContainer: {
      marginTop: '3em',
    },
    previewContainer: {
      paddingLeft: '35px',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0',
      },
      height: '100%',
      minHeight: '500px',
    },
    previewIframe: {
      display: 'block',
      height: '100%',
      width: '100%',
      minHeight: '500px',
      maxWidth: '750px',
      margin: 'auto',
    },
    completeButton: {
      padding: '1em 6em',
      margin: '2em 1em'
    },
    certificateExistsText: {
      padding: '2em 1em',
      textAlign: 'center',
    },
    noteButton: {
      marginTop: '1em',
      marginBottom: '2em',
    },
    noteButtonColour: {
      backgroundColor: '#356891',
      '&:hover': {
        backgroundColor: '#2a5374',
      },
    },
  }),
);

export default CertificateProcess;
