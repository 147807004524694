import React, {FunctionComponent} from 'react';
import {Divider, Typography} from "@material-ui/core";
import GetSurgeryByUniqueCodeForm from "../../components/Forms/GetSurgeryByUniqueCodeForm";
import {Surgery} from "../../api/Types";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import AddHospitalToSurgeryForm from "../../components/Forms/AddHospitalToSurgeryForm";
import {Alert} from "@material-ui/lab";
import {useDispatch} from "react-redux";
import {getSurgeries} from "../../store/surgeon/surgeonSurgerySlice";
import {getListOfSurgeonPatients} from "../../store/surgeon/surgeonAuthSlice";

const SurgeonConsentProcessLink: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [surgery, setSurgery] = React.useState<Surgery|null>(null);
  const [completed, setCompleted] = React.useState(false);

  const onGetSurgeryHandler = (surgery: Surgery) => {
    setSurgery(surgery);
  }

  const onHospitalLinked = (completedSurgery: Surgery) => {
    if (surgery !== null && completedSurgery.hospital_id !== null && completedSurgery.surgery_id === surgery.surgery_id) {
      Promise.all([
        dispatch(getListOfSurgeonPatients()),
        dispatch(getSurgeries()),
      ]);
      setCompleted(true);
    }
  }

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} paragraph variant="h5">Find your patient's consent process by entering their unique code</Typography>
        <Typography variant="body1" paragraph>If your patient created their own consent process they will receive a unique code. This can be used for the surgeon to view their progress and consent form.</Typography>
        <Divider />
      </div>
      {!surgery
        ? <GetSurgeryByUniqueCodeForm onComplete={onGetSurgeryHandler} />
        : !completed
        ? <AddHospitalToSurgeryForm surgery={surgery} onComplete={onHospitalLinked} />
        : <Alert className={classes.success} variant="filled" severity="success">You have successfully linked to this patient's surgery</Alert>
      }
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  success: {
    margin: '5em',
  }
})));

export default SurgeonConsentProcessLink;
