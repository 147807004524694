import React, {FunctionComponent, useEffect} from 'react';
import {Avatar, Grid, Tooltip, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {getSurgery} from "../../store/surgeon/surgeonSurgerySlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {BorderColor, CardMembership, Chat} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {Surgery} from "../../api/Types";
import CpContentContainer from "../../components/UI/CpContentContainer";

interface SurgeryActionListItemsProps {
  surgeryId: string
}

const SurgeryActionListItems: FunctionComponent<SurgeryActionListItemsProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const surgery = useSelector((state: RootState) => state.surgeon.surgery.currentSurgery.data);

  useEffect(() => {
    if (surgery === null || props.surgeryId !== surgery.surgery_id) {
      dispatch(getSurgery(props.surgeryId))
    }

    // eslint-disable-next-line
  }, [props.surgeryId]);

  const navigateHandler = (route: string) => {
    history.push(route);
  }

  return <Grid container className={classes.quickButtonContainer}>

    {surgery
      ? getSurgeryActions(surgery).map((actionButton) => {
        return <div key={actionButton.color} className={classes.avatarContainer}>
          <Typography className={classes.avatarTitle} variant="subtitle2">{actionButton.title}</Typography>
          <Avatar onClick={() => navigateHandler(actionButton.route)} style={{backgroundColor: actionButton.color}}>
          <Tooltip title={actionButton.tooltip}>{actionButton.icon}</Tooltip>
        </Avatar>
        </div>
      })
      : <CpContentContainer loading={true} />
    }
  </Grid>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarTitle: {
    marginBottom: '2px',
  },
  quickButtonContainer: {
    padding: 0,
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& > *': {
      cursor: 'pointer',
    },
  },
})));

type actionButton = {
  title: string,
  tooltip: string,
  icon: JSX.Element
  route: string,
  color: string,
}

const getSurgeryActions = (surgery: Surgery): actionButton[] => [
  {
    title: 'Consent Form',
    tooltip: 'View/Sign Consent Form',
    icon: <BorderColor />,
    route: `/surgeon/consent-form/${surgery.surgery_id}`,
    color: '#23bbbb',
  },
  {
    title: 'Surgery Notes',
    tooltip: 'View Surgery Notes',
    icon: <Chat />,
    route: `/surgeon/notes/${surgery.surgery_id}`,
    color: '#356891',
  },
  {
    title: 'Certificate',
    tooltip: 'View Certificate',
    icon: <CardMembership />,
    route: `/surgeon/certificate/${surgery.surgery_id}`,
    color: '#26aac7',
  },
];

export default SurgeryActionListItems;
