import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {ConsentStep} from "../../../../api/Types";

interface CompletedProcessProps {
  step: ConsentStep
}

const CompletedProcess: FunctionComponent<CompletedProcessProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography paragraph variant="h5" align="center"><strong>{props.step.name}</strong></Typography>
      <Typography paragraph variant="h6" align="center">Please download your certificate to show your surgeon. Once done, you will be able to come back and complete the consent form on our website</Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '2em 0',
      padding: '1em 15em',
      [theme.breakpoints.down('md')]: {
        padding: '1em 10em',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '1em',
      },
    },
  }),
);

export default CompletedProcess;
