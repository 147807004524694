import {createSlice} from "@reduxjs/toolkit";
import {authClient, surgeryClient} from "../../api/Container";
import {Operation, Surgeon, Surgery} from "../../api/Types";
import {Error, Loading} from "../store";
import {Moment} from "moment";

const initialState: patientSurgeryState = {
  surgeries: {
    error: null,
    loading: false,
    data: null
  },
  current: {
    error: null,
    loading: false,
    data: null
  },
  operations: {
    error: null,
    loading: false,
    data: null
  },
  surgeons: {
    error: null,
    loading: false,
    data: null
  },
}

export const patientSurgerySlice = createSlice({
  name: 'patientSurgery',
  initialState,
  reducers: {
    setSurgeries: (state, action) => {
      state.surgeries.data = action.payload.surgeries;
      state.surgeries.error = null;
      state.surgeries.loading = false;
    },
    setSurgery: (state, action) => {
      state.current.data = action.payload.surgery;
      state.current.error = null;
      state.current.loading = false;
    },
    setOperations: (state, action) => {
      state.operations.data = action.payload.operations;
      state.operations.error = null;
      state.operations.loading = false;
    },
    setSurgeons: (state, action) => {
      state.surgeons.data = action.payload.surgeons;
      state.surgeons.error = null;
      state.surgeons.loading = false;
    },
    setStart: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = null;
      // @ts-ignore
      state[action.payload.index].loading = true;
    },
    setEnd: (state, action) => {
      // @ts-ignore
      state[action.payload.index].error = action.payload.error;
      // @ts-ignore
      state[action.payload.index].loading = false;
    },
  },
});

export default patientSurgerySlice.reducer;

// @ts-ignore as this is a middleware
export const getSurgeries = (patientId: string): Promise<any> => async dispatch => {
  dispatch(patientSurgerySlice.actions.setStart({index: 'surgeries'}))

  try {
    const response = await surgeryClient.getPatientSurgeries(patientId);
    dispatch(patientSurgerySlice.actions.setSurgeries({surgeries: response.data.surgeries}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientSurgerySlice.actions.setEnd({index: 'surgeries', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getCurrentSurgery = (surgeryId: string): Promise<any> => async dispatch => {
  dispatch(patientSurgerySlice.actions.setStart({index: 'current'}))

  try {
    const response = await surgeryClient.getSurgery(surgeryId);
    dispatch(patientSurgerySlice.actions.setSurgery({surgery: response.data.surgery}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientSurgerySlice.actions.setEnd({index: 'current', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const createSurgery = (patientId: string, operation: string, surgeonId: string|null = null, hospitalId: string|null = null, dateOfSurgery: Moment|null = null): Promise<any> => async dispatch => {
  try {
    const response = await surgeryClient.createSurgery(patientId, operation, surgeonId, hospitalId, dateOfSurgery);
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientSurgerySlice.actions.setEnd({index: 'current', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const updateSurgery = (hospitalId: string, surgeonId: string, surgeryId: string, dateOfSurgery: Moment|null = null): Promise<any> => async dispatch => {
  try {
    const response = await surgeryClient.updateSurgery(hospitalId, surgeonId, surgeryId, dateOfSurgery);
    await dispatch(getCurrentSurgery(surgeryId));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientSurgerySlice.actions.setEnd({index: 'current', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const setCurrentSurgery = (surgery: Surgery): Promise<any> => async dispatch => {
    dispatch(patientSurgerySlice.actions.setSurgery({surgery: surgery}));
    return Promise.resolve();
}

// @ts-ignore as this is a middleware
export const getOperations = (): Promise<any> => async dispatch => {
  dispatch(patientSurgerySlice.actions.setStart({index: 'operations'}))

  try {
    const response = await surgeryClient.getOperations();
    const operations = response.data.operations.sort((o1: Operation, o2: Operation) => o1.operation_name > o2.operation_name);
    dispatch(patientSurgerySlice.actions.setOperations({operations}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientSurgerySlice.actions.setEnd({index: 'operations', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

// @ts-ignore as this is a middleware
export const getSurgeons = (): Promise<any> => async dispatch => {
  dispatch(patientSurgerySlice.actions.setStart({index: 'surgeons'}))

  try {
    const response = await authClient.getAllSurgeons();
    const surgeons = response.data.surgeons.sort(
      (s1: Surgeon, s2: Surgeon) => (s1.surgeon_first_name + s1.surgeon_last_name) > (s2.surgeon_first_name + s2.surgeon_last_name)
    );
    dispatch(patientSurgerySlice.actions.setSurgeons({surgeons}));
    return Promise.resolve(response);
  } catch (err: any) {
    dispatch(patientSurgerySlice.actions.setEnd({index: 'surgeons', error: err.data.data.errors[0]}))
    return Promise.reject(err);
  }
}

type patientSurgeryState = {
  surgeries: {
    error: Error
    loading: Loading
    data: Surgery[]|null
  }
  current: {
    error: Error
    loading: Loading
    data: Surgery|null
  }
  operations: {
    error: Error
    loading: Loading
    data: Operation[]|null
  }
  surgeons: {
    error: Error
    loading: Loading
    data: Surgeon[]|null
  }
}
