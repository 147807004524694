import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpButton from "../../UI/CpButton";

type PreviousNextButtonsProps = {
  previousQuestion: () => void,
  nextQuestion: () => void,
  showNextButton?: boolean,
  showPreviousButton?: boolean,
}

const PreviousNextButtons: FunctionComponent<PreviousNextButtonsProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CpButton
        disabled={props.showPreviousButton !== undefined && !props.showPreviousButton}
        className={classes.button}
        variant="contained"
        color="primary"
        size="large"
        onClick={() => props.previousQuestion()}
      >
        Previous Question
      </CpButton>
      <CpButton
        disabled={props.showNextButton !== undefined && !props.showNextButton}
        className={classes.button}
        variant="contained"
        color="primary"
        size="large"
        onClick={() => props.nextQuestion()}
      >
        Next Question
      </CpButton>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    button: {
      margin: '0 0.3em',
    },
  }),
);

export default PreviousNextButtons;
