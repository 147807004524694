import React, {FunctionComponent} from 'react';
import {CircularProgress, Fade} from "@material-ui/core";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";

type CpLoadingProps = {
  loading: boolean
}

const CpLoading: FunctionComponent<CpLoadingProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fade
        in={props.loading}
        style={{ transitionDelay: props.loading ? '600ms' : '0ms' }}
        unmountOnExit
      >
        <CircularProgress />
      </Fade>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  root: {
    margin: 'auto',
    width: '40px',
    height: '40px',
  },
})));

export default CpLoading;
