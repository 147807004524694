import axios from 'axios';

const noteInstance = axios.create({
  baseURL: '/api/note',
});

noteInstance.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;

export default class NoteClient {
  createSurgeryNote = (surgeryId: string, content: string) => {
    this.authorize();

    return noteInstance.post(`/surgery/${surgeryId}`, {content})
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getSurgeryNotes = (surgeryId: string) => {
    this.authorize();

    return noteInstance.get(`/surgery/${surgeryId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  private authorize = () => {
    noteInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }
}
