import CpButton from "../UI/CpButton";
import {NavigateNext} from "@material-ui/icons";
import React, {FunctionComponent, useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import CpContentContainer from "../UI/CpContentContainer";
import {getHospitals, getSurgery, updateSurgery} from "../../store/surgeon/surgeonSurgerySlice";
import {Surgery} from "../../api/Types";
import {RootState} from "../../store/store";
import {getSurgeonPatient} from "../../store/surgeon/surgeonAuthSlice";
import PatientDetails from "../Surgeon/PatientDetails";
import HospitalTableList from "../Surgeon/HospitalTableList";
import {Typography} from "@material-ui/core";
import {authClient} from "../../api/Container";

interface AddHospitalToSurgeryFormProps {
  surgery: Surgery,
  onComplete: (completedSurgery: Surgery) => void,
}

const AddHospitalToSurgeryForm: FunctionComponent<AddHospitalToSurgeryFormProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hospitals = useSelector((state: RootState) => state.surgeon.surgery.hospitals.data);
  const surgery = useSelector((state: RootState) => state.surgeon.surgery.currentSurgery.data);
  const patient = useSelector((state: RootState) => state.surgeon.auth.currentPatient.data);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string|null>(null);
  const [selectedHospital, setSelectedHospital] = React.useState<string|null>(null);

  useEffect(() => {
    if (hospitals === null) {
      dispatch(getHospitals());
    }

    if (surgery === null || props.surgery.surgery_id !== surgery.surgery_id) {
      dispatch(getSurgery(props.surgery.surgery_id));
    }

    if (surgery === null || patient === null || props.surgery.patient_id !== surgery.patient_id) {
      dispatch(getSurgeonPatient(props.surgery.patient_id));
    }

    // eslint-disable-next-line
  }, [props.surgery]);

  const onSelectHospitalHandler = (hospitalId: string) => {
    if (selectedHospital === hospitalId) {
      setSelectedHospital(null);
      return;
    }

    setSelectedHospital(hospitalId);
  }

  const submitHandler = async () => {
    const surgeonId = authClient.getSurgeonId()
    if (!selectedHospital || !surgeonId) {
      setSubmitError('Only verified surgeons can make this request')
      return;
    }

    setSubmitLoading(true)
    setSubmitError(null)
    try {
      const response = await dispatch(updateSurgery(selectedHospital, surgeonId, props.surgery.surgery_id));
      setSubmitLoading(false);
      if (response.status === 'success') {
        dispatch(getSurgery(response.data.surgery_id)).then(response => {
          props.onComplete(response.data.surgery);
        });
      }
    } catch (e: any) {
      setSubmitLoading(false)
      setSubmitError(e.data.data.errors[0])
    }
  }

  if (!surgery || !hospitals || !patient) {
    return <CpContentContainer loading={true} />
  }

  return <div className={classes.hospitalForm}>
    <div className={classes.patientContainer}>
      <PatientDetails patient={patient} title="Please verify this is the correct patient" />
    </div>
    <CpContentContainer loading={submitLoading} error={submitError} />
    {!submitLoading
      ? <>
        <CpButton
          className={classes.submitButton}
          disabled={submitLoading || !selectedHospital}
          type="button"
          variant="contained"
          color="primary"
          size="large"
          startIcon={<NavigateNext />}
          onClick={submitHandler}
        >
          Link To Surgery
        </CpButton>
        {selectedHospital
          ? <Typography variant="h6" paragraph>
            Selected: <strong>{hospitals.filter((h) => h.hospital_id === selectedHospital)[0].hospital_name}</strong>
          </Typography>
          : null
        }
        <HospitalTableList selected={selectedHospital} hospitals={hospitals}  onSelectHospital={onSelectHospitalHandler}/>
      </>
      : null}
  </div>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  patientContainer: {
    padding: '1em',
  },
  hospitalForm: {
    margin: '2em'
  },
  inputCodeText: {
    fontFamily: 'open sans',
    textTransform: 'uppercase',
    letterSpacing: '10px',
  },
  submitButton: {
    display: 'flex',
    margin: '2em auto',
    padding: '1em 5em',
  },
  field: {
    width: '50%',
    minWidth: '200px',
    margin: '1em auto',
  },
})));

export default AddHospitalToSurgeryForm;
