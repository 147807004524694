import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardHeader, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Surgery} from "../../api/Types";
import {downloadCertificate, previewCertificate} from "../../store/surgeon/surgeonCertificateSlice";
import CpContentContainer from "../UI/CpContentContainer";
import {GetApp} from "@material-ui/icons";
import {RootState} from "../../store/store";
import {findCertificate} from "../../store/surgeon/surgeonCertificateSlice";

type SurgeryCertificateSectionProps = {
  surgery: Surgery
  onClick?: () => void
  minHeight?: number
}

const SurgeryCertificateSection: FunctionComponent<SurgeryCertificateSectionProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const certificate = useSelector((state: RootState) => state.surgeon.certificate.current.data);
  const [previewError, setPreviewError] = React.useState<string|null>(null);
  const [previewPdf, setCertificatePreview] = React.useState<string|null>(null);

  useEffect(() => {
    if (certificate === null || certificate.surgery_id !== props.surgery.surgery_id) {
      dispatch(findCertificate(props.surgery.surgery_id)).then((response) => {
        if (response.data.consent_form !== null) {
          getCertificatePreview();
        } else {
          setCertificatePreview(null);
          setPreviewError('Please make sure the patient has completed the assessment and learning resources');
        }
      });
    } else if(previewPdf === null) {
      getCertificatePreview();
    }
    // eslint-disable-next-line
  }, []);

  const getCertificatePreview = () => {
    dispatch(previewCertificate(props.surgery.surgery_id)).then(filePath => {
      setCertificatePreview(filePath)
    }).catch(() => {
      setCertificatePreview(null);
      setPreviewError('Please make sure the patient has completed the assessment and learning resources');
    });
  }

  const downloadCertificateHandler = () => {
    dispatch(downloadCertificate(props.surgery.surgery_id));
  }

  const downloadAction = certificate !== null && certificate.consented ? <Tooltip title="Download Certificate">
    <IconButton disabled={!certificate.consented} onClick={downloadCertificateHandler} aria-label="view">
      <GetApp />
    </IconButton>
  </Tooltip> : <Typography variant="body1" paragraph>Download unavailable</Typography>;

  return (
    <Card className={classes.cardSection}>
      <CardHeader titleTypographyProps={{onClick: props.onClick, className: props.onClick ? classes.cardHeader : undefined}} title="Certificate Preview" action={downloadAction} />
        <Grid container direction="row" className={classes.cardContent}>
          {previewPdf === null
            ? <CpContentContainer error={previewError} loading={previewError === null} />
            : <iframe
              title="Certificate preview"
              src={previewPdf}
              className={classes.previewIframe}
              style={{minHeight: props.minHeight ? `${props.minHeight}px` : '500px'}}
            />
          }
        </Grid>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  cardSection: {
    marginRight: '1em',
  },
  cardContent: {
    padding: '1em',
  },
  cardHeader: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  previewIframe: {
    display: 'block',
    height: '100%',
    width: '100%',
    minHeight: '500px',
    maxWidth: '750px',
    margin: 'auto',
  },
})));

export default SurgeryCertificateSection;
