import {TextField} from "@material-ui/core";
import {withStyles, createStyles, Theme} from "@material-ui/core/styles";

const CpTextField = withStyles((theme: Theme) => createStyles({
  root: {
    '& label': {
      color: theme.palette.text.primary,
      opacity: 0.8,
    },
    '& label.Mui-focused': {
      opacity: 1,
    },
  }
}))(TextField);

export default CpTextField;
