import React, {FunctionComponent, useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";
import SurgeonTableList from "../Surgeon/SurgeonTableList";
import {getSurgeons} from "../../store/patient/patientSurgerySlice";
import {Check} from "@material-ui/icons";
import CpButton from "../UI/CpButton";
import {Typography} from "@material-ui/core";
import {Surgeon} from "../../api/Types";

interface SelectSurgeonFormProps extends LoadingErrorProps {
  onSubmit: (surgeon: string) => void
}

const SelectSurgeonForm: FunctionComponent<SelectSurgeonFormProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [surgeon, setSurgeon] = React.useState<string|null>(null);
  const error = useSelector((state: RootState) => state.patient.surgery.surgeons.error);
  const loading = useSelector((state: RootState) => state.patient.surgery.surgeons.loading);
  const surgeons = useSelector((state: RootState) => state.patient.surgery.surgeons.data);

  useEffect(() => {
    if (surgeons === null) {
      dispatch(getSurgeons());
    }
    // eslint-disable-next-line
  }, []);

  const onSelectSurgeonHandler = (surgeonId: string) => {
    if (surgeon === surgeonId) {
      setSurgeon(null);
      return;
    }

    setSurgeon(surgeonId);
  }

  const getSurgeonName = (): string => {
    if (surgeons === null) {
      return '';
    }

    const filtered = surgeons.filter((s: Surgeon) => s.surgeon_id === surgeon);

    if (filtered.length === 1) {
      const selected = filtered.shift();
      return `${selected?.surgeon_first_name ?? ''} ${selected?.surgeon_last_name ?? ''}`
    }

    return 'Unable to find surgeon';
  }

  const submitHandler = async () => {
    if (surgeon === null) {
      return;
    }

    props.onSubmit(surgeon);
  }

  if (surgeons === null) {
    return null;
  }

  return <>
    <CpContentContainer error={error} loading={loading} />
      <CpButton
        type="submit"
        disabled={surgeon === null}
        onClick={submitHandler}
        className={classes.confirmButton}
        variant="contained"
        color="primary"
        size="large"
        startIcon={<Check />}
      >
        Confirm
      </CpButton>
    {surgeon !== null ? <Typography variant="h6" paragraph>
      Selected: <strong>{getSurgeonName()}</strong>
    </Typography> : null}
    <SurgeonTableList selected={surgeon} surgeons={surgeons} onSelectSurgeon={onSelectSurgeonHandler}/>
  </>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  field: {
    margin: '1.2em',
  },
  confirmButton: {
    display: 'flex',
    justifySelf: 'center',
    margin: '2em auto',
  },
})));

export default SelectSurgeonForm;
