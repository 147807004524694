import React, {FunctionComponent, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getOperations, getSurgeons} from "../../store/patient/patientSurgerySlice";
import {RootState} from "../../store/store";
import {getHospitals} from "../../store/surgeon/surgeonSurgerySlice";
import {Hospital, Operation, Surgeon} from "../../api/Types";
import {Card, CardContent, CardHeader, Typography} from "@material-ui/core";
import {Accessibility, Cancel, Check, LocalHospital, Person, Schedule} from "@material-ui/icons";
import CpButton from "../UI/CpButton";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Moment} from "moment";

interface ConfirmSurgeryProps {
  operationId: string
  surgeonId: string
  hospitalId: string
  surgeryDate: Moment|null
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmSurgery: FunctionComponent<ConfirmSurgeryProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const operations = useSelector((state: RootState) => state.patient.surgery.operations.data);
  const hospitals = useSelector((state: RootState) => state.surgeon.surgery.hospitals.data);
  const surgeons = useSelector((state: RootState) => state.patient.surgery.surgeons.data);

  useEffect(() => {
    if (operations === null) {
      dispatch(getOperations());
    }

    if (surgeons === null) {
      dispatch(getSurgeons());
    }

    if (hospitals === null) {
      dispatch(getHospitals());
    }
    // eslint-disable-next-line
  }, []);

  const getOperation = (): string => {
    if (operations === null) {
      return '';
    }

    const operation = operations.filter((operation: Operation) => operation.operation_id === props.operationId);
    return operation && operation.length === 1 ? operation.shift()?.operation_name ?? '' : 'We were unable to find your operation, please try again';
  }

  const getHospital = (): string => {
    if (hospitals === null) {
      return '';
    }

    const hospital = hospitals.filter((hospital: Hospital) => hospital.hospital_id === props.hospitalId);

    if (hospital.length === 1) {
      const selected = hospital.shift();
      return `${selected?.hospital_name ?? ''}, ${selected?.hospital_city ?? ''}`;
    }

    return 'Hospital Unknown';
  }

  const getSurgeon = (): string => {
    if (surgeons === null) {
      return '';
    }

    const surgeon = surgeons.filter((surgeon: Surgeon) => surgeon.surgeon_id === props.surgeonId);

    if (surgeon.length === 1) {
      const selected = surgeon.shift();
      return `Dr. ${selected?.surgeon_first_name ?? ''} ${selected?.surgeon_last_name ?? ''}`;
    }

    return 'Surgeon Unknown';
  }

  return <Card elevation={3} className={classes.cardSection}>
    <CardHeader title={"Confirm your surgery"} style={{textAlign: 'center'}} />
    <CardContent className={classes.contentContainer}>
      <Typography variant="body1" paragraph><Accessibility className={classes.icon} />{getOperation()}</Typography>
      <Typography variant="body1" paragraph><Person className={classes.icon} />{getSurgeon()}</Typography>
      <Typography variant="body1" paragraph><LocalHospital className={classes.icon} />{getHospital()}</Typography>
      <Typography variant="body1" paragraph><Schedule className={classes.icon} />{props.surgeryDate ? props.surgeryDate.format('MMMM Do YYYY') : 'Date of Surgery Unknown'}</Typography>
    </CardContent>
    <div className={classes.buttonContainer}>
      <CpButton type="submit" onClick={props.onCancel} className={classes.cancelButton} variant="contained" color="primary" size="large" startIcon={<Cancel />}>Cancel</CpButton>
      <CpButton type="submit" onClick={props.onConfirm} className={classes.confirmButton} variant="contained" color="primary" size="large" startIcon={<Check />}>Confirm</CpButton>
    </div>
  </Card>
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  cardSection: {
    padding: '1em',
  },
  contentContainer: {
    paddingLeft: '3em',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8em',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
  },
  cancelButton: {
    margin: '0 0.2em',
    [theme.breakpoints.up('md')]: {
      margin: '1.5em 5em',
    },
    backgroundColor: '#d11919',
    '&:hover': {
      backgroundColor: '#b70000',
    },
  },
  confirmButton: {
    margin: '0 0.2em',
    [theme.breakpoints.up('md')]: {
      margin: '1.5em 5em',
    },
    backgroundColor: '#009A49',
    '&:hover': {
      backgroundColor: '#007b3a',
    },
  },
  icon: {
    verticalAlign: '-6px',
    marginRight: '0.5em',
  },
})));

export default ConfirmSurgery;
