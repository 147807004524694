import React, {FunctionComponent, useEffect} from 'react';
import Typography from "@material-ui/core/Typography";
import CpAppBar from "../UI/CpAppBar";
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Button, Grid, Hidden} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/public/publicAuthSlice";
import {initialisePatientAccount} from "../../store/patient/patientAuthSlice";
import logo from "../../resources/logo.png";
import {RootState} from "../../store/store";
import {authClient} from "../../api/Container";
import {tokenHasExpired} from "../../app/helpers";

const PatientDashboard: FunctionComponent<{}> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const roles = authClient.getAuthRoles();
  const patient = useSelector((state: RootState) => state.patient.auth.account.data);

  const isAdmin = roles !== null ? roles.includes('ADMIN') : false;

  useEffect(() => {
    // check if token has expired and logout if so
    if(tokenHasExpired()) {
      dispatch(logout());
      history.push('/patient/login');
      return;
    }

    if (patient === null) {
      dispatch(initialisePatientAccount()).catch(err => {
        if(err.status === 401 || err.status === 403) {
          dispatch(logout());
          history.push('/patient/login');
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const navBarHandler = (route: string) => {
    history.push(route);
  }

  const logoutHandler = () => {
    dispatch(logout()).then(() => {
      history.push('/home');
    }).catch(() => {
      history.push('/home');
    })
  }

  return (
    <div className={classes.root}>
      <CpAppBar className={classes.appBar}>
        <Grid container item sm={6} md={5}>
          <img src={logo} className={classes.logo} onClick={() => navBarHandler('/home')} alt="Consent Plus Logo" />
          <Hidden xsDown>
            <Typography className={classes.title} variant="h6" noWrap> Patient Portal </Typography>
          </Hidden>
        </Grid>
        <Grid container item xs={9} sm={6} md={7} direction="row" justify="flex-end">
          {isAdmin
            ? <Button onClick={() => navBarHandler('/admin')} className={classes.navItem} color="inherit">Admin</Button>
            : null
          }
          <Button onClick={logoutHandler} className={classes.navItem} color="inherit">Logout</Button>
        </Grid>
      </CpAppBar>
      <main className={classes.content}>{ props.children }</main>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '2.5em',
      },
    },
    logo: {
      maxHeight: '45px',
      cursor: 'pointer',
    },
    title: {
      minWidth: '130px',
      marginLeft: '1em',
      marginRight: '1.3em',
      lineHeight: '2.5',
    },
    content: {
      flexGrow: 1,
      marginTop: '64px',
      maxWidth: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    navItem: {
      marginRight: '2em',
    },
  }),
);

export default PatientDashboard;
