import React, {FunctionComponent} from 'react';
import { Divider, Grid, Typography } from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import UnverifiedSurgeonsList from "../../components/Admin/UnverifiedSurgeonsList";

const AdminHome: FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h5">Welcome to your Admin Dashboard</Typography>
        <Divider />
      </div>
      <Grid item xs={12} className={classes.section}>
        <UnverifiedSurgeonsList />
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  header: {
    marginBottom: '1.5em',
  },
  headerTitle: {
    paddingBottom: '0.5em',
  },
  section: {
    marginRight: '1em',
    marginBottom: '2em',
  },
})));

export default AdminHome;
