import React, {FunctionComponent} from 'react';
// @ts-ignore
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface CpTextEditorProps {
  className?: string
  onChange?: (event: any, editor: CKEditor) => void
  placeholder?: string
  note: string
}

const CpTextEditor: FunctionComponent<CpTextEditorProps> = props => {
  return <CKEditor
    className={props.className}
    editor={ ClassicEditor }
    onChange={props.onChange}
    data={props.note}
    config={{
      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList','|', 'undo', 'redo'],
      placeholder: props.placeholder,
    }}
  />
}

export default CpTextEditor;
