import React, {FunctionComponent} from 'react';
import CpTextEditor from "./CpTextEditor";
import UseSessionStorage from "../Storage/UseSessionStorage";
import CpButton from "./CpButton";
import {Send} from "@material-ui/icons";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpLoading from "./CpLoading";
import {Grid} from "@material-ui/core";

interface CpAddNoteProps {
  id: string
  onSubmit: (note: string) => void
  height?: string
}

const CpAddNote: FunctionComponent<CpAddNoteProps> = props => {
  const classes = useStyles();
  const [note, setNote] = UseSessionStorage(props.id);
  const [sending, setSending] = React.useState(false);

  const onSubmit = async () => {
    if (note === null) {
      return;
    }

    setSending(true);
    try {
      await props.onSubmit(note);
    } catch {
      setSending(false);
      return;
    }
    setNote(null); // this removes the item from storage
    setSending(false);
  }

  const onChange = (event: any, editor: any) => {
    const data = editor.getData();
    setNote(data);
  }

  return <Grid container>
    <Grid item xs={8} md={10}>
      <CpTextEditor
        className={classes.editor}
        onChange={(onChange)}
        note={note ?? ''}
        placeholder="Start typing your message..."
      />
    </Grid>
    <Grid item xs={4} md={2} className={classes.buttonContainer}>
      {sending
      ? <CpLoading loading={sending} />
      : <CpButton
          disabled={note === null || note === '' || sending}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<Send />}
          onClick={onSubmit}
        >
          Send
        </CpButton>
      }
    </Grid>
  </Grid>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editorContainer: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'flex-start',
    },
    editor: {
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export default CpAddNote;
