import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {QuestionAnswer} from "../../../api/Types";
import AnswerSelect from "./AnswerSelect";
import AnswerRadio from "./AnswerRadio";

type AssessmentAnswerProps = {
  question: QuestionAnswer
  answerHandler: (answer: string) => void,
  completed?: boolean
}

const AssessmentAnswer: FunctionComponent<AssessmentAnswerProps> = props => {
  const classes = useStyles();

  let answerInput = null;
  if (props.question.question_possible_answers.length > 5 && !props.question.question_allow_multiple) {
    answerInput = <AnswerSelect disabled={props.completed} question={props.question} answerHandler={props.answerHandler} />
  }

  if (answerInput === null) {
    answerInput = <AnswerRadio disabled={props.completed} question={props.question} answerHandler={props.answerHandler} />
  }

  return (
    <div className={classes.container}>
      {answerInput}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '2em',
    },
    answer: {
      [theme.breakpoints.down('sm')]: {
        margin: '0.5em',
      }
    },
  }),
);

export default AssessmentAnswer;
