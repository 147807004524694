import React, {FunctionComponent} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const PrivacyStatementPage: FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container item xs={12} className={classes.familyContainer}>
        <Typography variant="h4" color="textPrimary" paragraph className={classes.center}>
          <strong>Privacy Statement</strong>
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Privacy Policy</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Our Privacy Policy was posted on 1 September 2016 and last updated on 15 March 2017. It
          governs the privacy terms of our Website, located at www.consentplus.com. Any capitalized
          terms not defined in our Privacy Policy, have the meaning as specified in our Terms of Use
          accessible at www.consentplus.com/terms.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Your Privacy</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          ConsentPlus follows all legal requirements to protect your privacy. Our Privacy Policy is a
          legal statement that explains how we may collect information from you, how we may share your
          information, and how you can limit our sharing of your information. You will see terms in our
          Privacy Policy that are capitalized. These terms have meanings as described in the Definitions
          section below.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Definitions</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          "Non Personal Information" is information that is not personally identifiable to you and that
          we automatically collect when you access our Website with a web browser. It may also include
          publicly available information that is shared between you and others.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          "Personally Identifiable Information" is non-public information that is personally
          identifiable to you and obtained in order for us to provide you within our Website. Personally
          Identifiable Information may include information such as your name, email address, and other
          related information that you provide to us or that we obtain about you.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Information We Collect</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Generally, you control the amount and type of information you provide to us when using our Website.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          As a Visitor, you can browse our website to find out more about our Website. You are not
          required to provide us with any Personally Identifiable Information as a Visitor.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Computer Information Collected</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          When you use our Website, we automatically collect certain computer information by the
          interaction of your mobile phone or web browser with our Website. Such information is
          typically considered Non Personal Information. We also collect the following:
        </Typography>
        <ul>
          <li>
            <Typography variant="h6" color="textPrimary" paragraph>
              <strong>Cookies</strong>
            </Typography>
          </li>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            Our Website uses "Cookies" to identify the areas of our Website that you have visited. A
            Cookie is a small piece of data stored on your computer or mobile device by your web
            browser. We use Cookies to personalize the Content that you see on our Website. Most web
            browsers can be set to disable the use of Cookies. However, if you disable Cookies, you
            may not be able to access functionality on our Website correctly or at all. We never place
            Personally Identifiable Information in Cookies.
          </Typography>
          <li>
            <Typography variant="h6" color="textPrimary" paragraph>
              <strong>Third Party Tracking Tools</strong>
            </Typography>
          </li>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            We also use third party tracking tools to improve the performance and features of our
            Website. These third party tracking tools are designed to collect only Non-Personal
            Information about your use of our Website. However, you understand that such tools are
            created and managed by parties outside our control. As such, we are not responsible for
            what information is actually captured by such third parties or how such third parties use
            and protect that information.
          </Typography>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            We use Remarketing with Google Analytics to advertise on third party sites to you after
            you visited our Site. We and our third party vendors, like Google, use first party cookies
            (such as the Google Analytics cookie) and third party cookies (such as the DoubleClick
            cookie) to inform, optimize and serve ads based on your past visits to our Site.
          </Typography>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            You can opt-out of Google Analytics for Display Advertising and customize the Google
            Display Network ads by visiting the <a href="https://www.google.com/settings/ads" rel="noreferrer" target="_blank"> Google Ads Settings</a> page.
            Google also recommends installing the <a href="https://tools.google.com/dlpage/gaoptout" rel="noreferrer" target="_blank">Google Analytics Opt-out Browser Add-on</a> for your browser.
            Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.
          </Typography>
          <li>
            <Typography variant="h6" color="textPrimary" paragraph>
              <strong>Automatic Information</strong>
            </Typography>
          </li>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            We automatically receive information from your web browser or mobile device. This
            information includes the name of the website from which you entered our Website, if any,
            as well as the name of the website to which you're headed when you leave our website. This
            information also includes the IP address of your computer/proxy server that you use to
            access the Internet, your Internet Website provider name, web browser type, type of mobile
            device, and computer operating system. We use all of this information to analyze trends
            among our Users to help improve our Website.
          </Typography>
        </ul>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>How We Use Your Information</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          We use the information we receive from you as follows:
        </Typography>
        <ul>
          <li>
            <Typography variant="h6" color="textPrimary" paragraph>
              <strong>Customizing Our Website</strong>
            </Typography>
          </li>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            We may use the Personally Identifiable information you provide to us along with any
            computer information we receive to customize our Website.
          </Typography>
          <li>
            <Typography variant="h6" color="textPrimary" paragraph>
              <strong>Sharing Information with Affiliates and Other Third Parties</strong>
            </Typography>
          </li>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            We do not sell, rent, or otherwise provide your Personally Identifiable Information to
            third parties for marketing purposes. We may provide your Personally Identifiable
            Information to affiliates that provide services to us with regards to our Website (i.e.
            payment processors, Website hosting companies, etc.); such affiliates will only receive
            information necessary to provide the respective services and will be bound by
            confidentiality agreements limiting the use of such information.
          </Typography>
          <li>
            <Typography variant="h6" color="textPrimary" paragraph>
              <strong>Data Aggregation</strong>
            </Typography>
          </li>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            We retain the right to collect and use any Non Personal Information collected from your
            use of our Website and aggregate such data for internal analytics that improve our Website
            and Service as well as for use or resale to others. At no time is your Personally
            Identifiable Information included in such data aggregations.
          </Typography>
          <li>
            <Typography variant="h6" color="textPrimary" paragraph>
              <strong>Legally Required Releases of Information</strong>
            </Typography>
          </li>
          <Typography variant="body1" color="textPrimary" paragraph align="justify">
            We may be legally required to disclose your Personally Identifiable Information, if such
            disclosure is (a) required by subpoena, law, or other legal process; (b) necessary to
            assist law enforcement officials or government enforcement agencies; (c) necessary to
            investigate violations of or otherwise enforce our Legal Terms; (d) necessary to protect
            us from legal action or claims from third parties including you and/or other Members;
            and/or (e) necessary to protect the legal rights, personal/real property, or personal
            safety of ConsentPlus, our Users, employees, and affiliates.
          </Typography>
        </ul>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Do Not Track ("DNT")</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web
          browser to inform websites that you do not want to be tracked. You can enable or disable Do
          Not Track by visiting the Preferences or Settings page of your web browser.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Protecting Your Child's Privacy</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Our Website is not designed for use by anyone under the age of 18 ("Child"), though we realize
          we may have a Child attempt to make purchases through our Website. We do not verify the age of
          our Users nor do we have any liability for verifying a User's age. If you are a Child, please
          seek the permission of a parent or guardian before using our Website.If you are a parent or
          guardian and believe your Child is using our Website, please contact us to remove your Child's
          account; we reserve the right to ask you for verification of your relationship to the Child
          before we honor such a request. If we discover that a Child has created an account on our
          Website, we will immediately delete the account as soon as we discover it, we will not use the
          information for any purpose, and we will not disclose the information to third parties.
          However, as parent of such a Child, you understand that you are legally liable for any
          transactions created by the Child.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Links to Other Websites</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Our Website may contain links to other websites that are not under our direct control. These
          websites may have their own policies regarding privacy. We have no control of or
          responsibility for linked websites and provide these links solely for the convenience and
          information of our visitors. You access such linked Websites at your own risk. These websites
          are not subject to this Privacy Policy. You should check the privacy policies, if any, of
          those individual websites to see how the operators of those third-party websites will utilize
          your personal information. In addition, these websites may contain a link to Websites of our
          affiliates. The websites of our affiliates are not subject to this Privacy Policy, and you
          should check their individual privacy policies to see how the operators of such websites will
          utilize your personal information.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Our Email Policy</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          Our affiliates and we fully comply with national laws regarding SPAM. You can always opt out
          of receipt of further email correspondence from us and/or our affiliates. We agree that we
          will not sell, rent, or trade your email address to any unaffiliated third-party without your
          permission.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Privacy Policy Updates</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          We reserve the right to modify this Privacy Policy at any time. You should review this Privacy
          Policy frequently. If we make material changes to this policy, we may notify you on our
          Website, by a blog post, by email, or by any method we determine. The method we chose is at
          our sole discretion. We will also change the "Last Updated" date at the beginning of this
          Privacy Policy. Any changes we make to our Privacy Policy are effective as of this Last
          Updated date and replace any prior Privacy Policies.
        </Typography>
        <Typography variant="h5" className={classes.header} color="textPrimary" paragraph>
          <strong>Questions About Our Privacy Practices or This Privacy Policy</strong>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph align="justify">
          If you have any questions about our Privacy Practices or this Policy, please contact us
        </Typography>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  familyContainer: {
    padding: '2em',
    [theme.breakpoints.up('sm')]: {
      padding: '2em 5em',
    },
  },
  center: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '1em',
  },
  header: {
    width: '100%',
  }
})));

export default PrivacyStatementPage;
