import React, {FunctionComponent} from 'react';
import {Grid, Typography} from "@material-ui/core";
import PatientRegisterForm from "../../components/Public/PatientRegisterForm";
import {Link} from "react-router-dom";

const PatientRegisterPage: FunctionComponent<{}> = () => {
  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={9} md={6}>
        <Typography paragraph>Got here accidentally? <Link to="/home">Go home</Link></Typography>
        <Typography>Already have an account? <Link to="/patient/login">Please login</Link></Typography>
        <PatientRegisterForm />
      </Grid>
    </Grid>
  )
}

export default PatientRegisterPage;
