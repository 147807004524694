import React, {FunctionComponent, useState} from 'react';
import {
  Card, Grid,
  IconButton,
  Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  Typography
} from "@material-ui/core";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import {Hospital} from "../../api/Types";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import CpSearch from "../UI/CpSearch";
import CpContentContainer, {LoadingErrorProps} from "../UI/CpContentContainer";

interface HospitalTableListProps extends LoadingErrorProps {
  hospitals: Hospital[],
  selected: string|null,
  onSelectHospital: (hospitalId: string) => void,
  className?: string,
}

const HospitalTableList: FunctionComponent<HospitalTableListProps> = props => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const [searchedHospitals, setSearchedHospitals] = useState<Hospital[]|null>(null);

  const selectHospital = (hospitalId: string) => {
    props.onSelectHospital(hospitalId);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSearch = (searchText: string) => {
    if (page !== 0) {
      setPage(0);
    }

    const searchList = [...props.hospitals].filter((hospital) => {
      return `${hospital.hospital_name} ${hospital.hospital_address_1} ${hospital.hospital_city}`.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });

    setSearchedHospitals(searchList);
  };

  const hospitals = searchedHospitals !== null ? searchedHospitals : props.hospitals ?? []

  return (
    <Card className={props.className}>
      <Grid container>
        <Grid item xs={12} md={7} lg={5} className={classes.section}>
          <Typography variant="h5">Hospitals</Typography>
        </Grid>
        <Grid item xs={12} md={5} lg={7} className={classes.section}>
          <CpSearch onSearch={onSearch} />
        </Grid>
      </Grid>
      <CpContentContainer error={props.error} loading={props.loading}>
        <TableContainer className={classes.tableSection} component={Paper}>
          <Table aria-label="Latest hospitals table" size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Select</strong></TableCell>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Address</strong></TableCell>
                <TableCell><strong>City</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hospitals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(hospital => (
                <TableRow onClick={() => selectHospital(hospital.hospital_id ?? '0')} key={hospital.hospital_id}>
                  <TableCell>
                    <IconButton aria-label="view">
                      {props.selected && props.selected === hospital.hospital_id
                        ? <CheckBox />
                        : <CheckBoxOutlineBlank />
                      }
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {hospital.hospital_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {hospital.hospital_address_1} {hospital.hospital_address_2} {hospital.hospital_address_3}
                  </TableCell>
                  <TableCell>{hospital.hospital_city}</TableCell>
                </TableRow>
              )) ?? []}
            </TableBody>
          </Table>
        </TableContainer>
      </CpContentContainer>
      {hospitals.length > rowsPerPage
        ? <TablePagination
          component="div"
          count={hospitals.length}
          rowsPerPageOptions={[10]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
        : null
      }

    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  section: {
    padding: '1em',
  },
  tableSection: {
    marginTop: '0.5em'
  }
})));


export default HospitalTableList;
