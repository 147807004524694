import React, {FunctionComponent} from 'react';
import {Grid, Hidden, Typography} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import consentPlusTeam from "../../resources/consent_plus_team.png";

const ErrorPage404: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const history = useHistory();

  const navBarHandler = (route: string) => {
    history.push(route);
  }

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid container item md={6}>
          <Typography className={classes.text} variant="h2" paragraph>Oops!</Typography>
          <div>
            <Typography className={classes.text} variant="body1" paragraph>Error Code: 404</Typography>
            <Typography className={classes.text} variant="h6" paragraph>We could not find the page you are looking for, it may have been archived.</Typography>
          </div>
          <div>
            <Typography className={classes.text} variant="body1" paragraph>Let us try and get back you on track with some useful links:</Typography>
            <Typography variant="h6" className={classes.text} paragraph>
              <Link className={classes.link} to="/home">Home</Link>
              <Link className={classes.link} to="/patient/login">Patient Portal</Link>
              <Link className={classes.link} to="/surgeon/login">Surgeon Portal</Link>
            </Typography>
          </div>
        </Grid>
        <Grid container item md={6}>
          <Hidden smDown>
            <img className={classes.logo} src={consentPlusTeam} onClick={() => navBarHandler('/home')} alt="Consent Plus Team" />
          </Hidden>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    padding: '8em 5em 5em 10em',
    [theme.breakpoints.down('sm')]: {
      padding: '8em 5em 5em 5em',
    },
  },
  text: {
    display: 'block',
    width: '100%',
  },
  link: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
    },
  },
  logo: {
    maxWidth: '566px',
    maxHeight: '484px',
    height: 'auto',
    width: 'auto',
    cursor: 'pointer',
  },
})));

export default ErrorPage404;
