import React, {FunctionComponent, useEffect} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import SurgeonLoginForm from "../../components/Public/SurgeonLoginForm";
import {authClient} from "../../api/Container";
import {tokenHasExpired} from "../../app/helpers";
import {logout} from "../../store/public/publicAuthSlice";
import {useDispatch} from "react-redux";

const SurgeonLoginPage: FunctionComponent<{}> = () => {
  const token = authClient.getToken();
  const roles = authClient.getAuthRoles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if(tokenHasExpired()) {
      dispatch(logout());
      return;
    }

    if (roles && token && roles.includes('SURGEON')) {
      history.push('/surgeon/dashboard');
    }
  });

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={9} md={6}>
        <Typography paragraph>Got here accidentally? <Link to="/home">Go home</Link></Typography>
        <Typography>Haven't got an account? <Link to="/surgeon/register">Please register</Link></Typography>
        <SurgeonLoginForm />
      </Grid>
    </Grid>
  )
}

export default SurgeonLoginPage;
