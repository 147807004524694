import React, {FunctionComponent} from 'react';
import {Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const SurgeonInformationPage: FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography paragraph variant="h4" align="center">
        Welcome to Consent Plus
      </Typography>
      <div className={classes.textContainer}>
        <Typography variant="h5" align="justify" paragraph>
          Who are we?
        </Typography>
        <Typography variant="body1" align="justify" paragraph>
          Consent Plus is a new process for dealing with consent in the UK healthcare industry. It is interactive as we understand your surgery is specific to you. The patient will enter the operation you are having (or your surgeon may have already done this) and they will need to complete the following process:
        </Typography>
        <Typography variant="h5" align="justify" paragraph>
          The process
        </Typography>
        <Typography variant="body1" align="justify" paragraph>
          If your Patient is considering surgery or decided on surgery, then consent plus is the right place! The process requires the patient to watch the educational resources and complete a couple of assessments so the surgeon is aware of what you do / don't understand.
          These assessments also allow us to make this process bespoke to you so you get the most out of our system. When the patient has completed the assessments and learning resources, they will be able to receive a certificate. If the patient decides on surgery they can continue and must complete a consent form.
          If the patient started the consent process then they <strong>must</strong> get verification from the surgeon. This can be done through the "link" on the homepage. They will provide a unique code for you to use. If the surgeon sets up the process, then you will be able to see their progress and they can start the consent process straight away.
        </Typography>
        <ul>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Information</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              A very basic set of questions to complete so we can understand what the patient wants to get out of the process. From here, we will tailor the process specifically to their needs.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Learning</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              This section will provide all the learning resources required to understand the risks, benefits and complications associated with the surgery. The patient <strong>must</strong> complete all learning resources before continuing to the next section.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Assessment</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              The assessment will help the surgeon understand what was learned in the previous section. Our system will highlight areas that need focus, so when the patient goes for a consultation with the surgeon, they are able to give you more information in the areas you don't understand and "fill in the missing gaps".
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Notes</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              This is not a mandatory part of the assessment, but once you have completed the assessment you will be able to create notes for your surgery. If you have any questions about your operation then please write them in here, these notes will be visible to the surgeon. Please be aware the surgeon is <strong>not</strong> obliged to respond, you will be able to view the notes when you go for your consultation. The <strong>first</strong> note will appear on the certificate so please make it count.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Certificate</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              This section is where the certificate will be received outlining the details of surgery along with the completed assessment and learning resources. This is <strong>not</strong> a consent form and has no legal standing.
              It is for the Patient's records to prove what they have completed <strong>before</strong> signing the Consent Form. This document also helps the surgeon identify missing information and provides a foundation for discussions on whether surgery is even right for the patient.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" align="justify" paragraph><strong>Consent Form</strong></Typography>
            <Typography variant="body1" align="justify" paragraph>
              This section is where you will sign your consent form <strong>if you want to proceed with surgery</strong>. If you created the consent process yourself then you must wait for surgeon verification before you are able to sign. You will be given a unique code that you must give to your surgeon so they can view your progress.
              If the surgeon created the consent process for you, then you will be able to sign the form straight away and your surgeon will be notified when it is complete. This will form as a legal document for you and the surgeon, so it must be completed before surgery takes place.
              <br />If you have learnt all you need and your decision is <strong>not</strong> to proceed with surgery then you have completed this process and you still have your certificate to prove that.
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => (createStyles({
  container: {
    padding: '1em 0.5em',
    marginBottom: '5em',
  },
  textContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '1em 5em',
    },
  },
  breadcrumbs: {
    margin: '0.5em 1.9em',
  },
})));

export default SurgeonInformationPage;
