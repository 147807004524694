import axios from 'axios';
import {Moment} from "moment";

const surgeryInstance = axios.create({
  baseURL: '/api/surgery',
});

surgeryInstance.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;

export default class SurgeryClient {
  getSurgery = (surgeryId: string) => {
    this.authorize();

    return surgeryInstance.get(`/${surgeryId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  createSurgery = (
    patientId: string,
    operation: string,
    surgeonId: string|null = null,
    hospitalId: string|null = null,
    surgeryDate: Moment|null = null
  ) => {
    this.authorize();

    return surgeryInstance.post('', {
      patient_id: patientId,
      operation_id: operation,
      surgeon_id: surgeonId,
      hospital_id: hospitalId,
      surgery_date: surgeryDate ? surgeryDate.format() : null,
    }).then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  updateSurgery = (hospitalId: string, surgeonId: string, surgeryId: string, dateOfSurgery: Moment|null = null) => {
    this.authorize();

    return surgeryInstance.put(`/${surgeryId}`, {
      surgeon_id: surgeonId,
      hospital_id: hospitalId,
      surgery_date: dateOfSurgery?.format(),
    }).then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getSurgeryByUniqueCode = (uniqueCode: string) => {
    this.authorize();

    return surgeryInstance.get(`/code/${uniqueCode}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getSurgeonSurgeries = () => {
    this.authorize();

    return surgeryInstance.get('/surgeon/me')
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getPatientSurgeries = (patientId: string) => {
    this.authorize();

    return surgeryInstance.get(`/patient/${patientId}`)
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getOperations = () => {
    this.authorize();

    return surgeryInstance.get('/operation')
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  getHospitals = () => {
    this.authorize();

    return surgeryInstance.get('/hospital')
      .then(response => {
        return Promise.resolve(response.data)
      }).catch(error => {
        return Promise.reject(error.response)
      });
  }

  private authorize = () => {
    surgeryInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }
}
