import React from 'react';

const UseSessionStorage = (sessionStorageKey: string): [string|null, React.Dispatch<React.SetStateAction<string|null>>] => {
  const [value, setValue] = React.useState<string|null>(sessionStorage.getItem(sessionStorageKey) || '');

  React.useEffect(() => {
    if (value === null) {
      sessionStorage.removeItem(sessionStorageKey);
      return;
    }

    sessionStorage.setItem(sessionStorageKey, value);
    // eslint-disable-next-line
  }, [value]);

  return [value, setValue];
};

export default UseSessionStorage;
