import React, {FunctionComponent} from 'react';
import {Breadcrumbs, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {NavigateNext} from "@material-ui/icons";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

type CpBreadcrumbsProps = {
  breadcrumbs: { to: string, name: string, click: boolean }[],
  className?: string
}

const CpBreadcrumbs: FunctionComponent<CpBreadcrumbsProps> = (props) => {
  const classes = useStyles();

  return (
    <Breadcrumbs className={props.className} color="primary" separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
      {props.breadcrumbs.map((breadcrumb) => breadcrumb.click
        ? <Link key={breadcrumb.name} className={classes.link} to={breadcrumb.to}>{breadcrumb.name}</Link>
        : <Typography key={breadcrumb.name}>{breadcrumb.name}</Typography>
      )}
    </Breadcrumbs>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

export default CpBreadcrumbs;
