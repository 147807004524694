import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ResourceEngagement} from "../../../api/Types";
import {Grid} from "@material-ui/core";
import ResourceVimeo from "./ResourceVimeo";
import ResourceList from "./ResourceList";

interface ResourceContainerProps {
  resourceEngagements: ResourceEngagement[]
}

const ResourceContainer: FunctionComponent<ResourceContainerProps> = props => {
  const classes = useStyles();
  const [currentResourceKey, setCurrentResourceKey] = React.useState<number|null>(null); // null so we know to setup

  const selectResourceHandler = async (resourceId: string) => {
    const index = props.resourceEngagements.findIndex((r) => r.resource_id === resourceId);
    setCurrentResourceKey(index);
  }

  const videoCompleteHandler = (resourceId: string) => {
    const index = props.resourceEngagements.findIndex((r) => r.resource_id === resourceId);

    if (index !== -1) {
      props.resourceEngagements[index + 1]
        ? setCurrentResourceKey(index + 1)
        : setCurrentResourceKey(0)
    }
  }

  return (
    <div className={classes.resourceContainer}>
      <Grid item xs={12} md={8}>
        <ResourceVimeo onVideoComplete={videoCompleteHandler} resource={props.resourceEngagements[currentResourceKey ?? 0]} />
      </Grid>
      <Grid item xs={12} md={4}>
        <ResourceList
          resources={props.resourceEngagements}
          active={currentResourceKey ?? 0}
          onResourceClick={selectResourceHandler}
        />
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resourceContainer: {
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      marginBottom: '3em',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        padding: '0 2.5em',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
  }),
);

export default ResourceContainer;
